import * as api from '@api/baseSubset';
import { State } from '../reducer';
import { getDealInfo } from '.';

type Args = {
  idChain: string
  token: string
  state: State
  callId: string
  dealId: string
  emotions: api.EmotionsTempType
};

export async function saveEmotionsTemp({ idChain, token, state, callId, emotions, dealId }: Args) {
  try {
    await api.saveEmotionsTemp({ callId, idChain, token, emotions });
    return await getDealInfo({ id: dealId, state, token, force: true });
  } catch(e) {
    console.log(e);
    state.dispatch({ type: 'updateStateAsync', stateSlice: { isLoading: false } });
  }
};
