import * as api from '@api/baseSubset';
import { State } from '../reducer';
import { getDealInfo } from './getDealInfo';

type Args = {
  idChain: string
  token: string
  state: State
  callId: string
  tones: NonNullable<api.FullAnalysis['tones']>
  dealId: string
};

export async function saveEmotions({ idChain, token, state, callId, tones, dealId }: Args) {
  try {
    await api.saveEmotions({ callId, idChain, token, tones });
    return await getDealInfo({ id: dealId, state, token, force: true });
  } catch(e) {
    console.log(e);
    state.dispatch({ type: 'updateStateAsync', stateSlice: { isLoading: false } });
  }
};
