import React, { createContext, useEffect, useReducer } from 'react';
import { PureWrapper } from '@components';
import { initialState, FiltersReducer } from './reducer';

export const FiltersCtx = createContext(initialState);
const { Provider } = FiltersCtx;

type Props = {
  children: React.ReactNode
};
export function FiltersProvider(x: Props) {
  const [state, dispatch] = useReducer(FiltersReducer, initialState);
  useEffect(() => dispatch({ type: 'initDispatch', dispatch }), []);

  if (typeof state.dispatch !== 'function') return null;

  return (
    <Provider value={{ ...state, dispatch }}>
      <PureWrapper>
        { x.children }
      </PureWrapper>
    </Provider>
  );
};
