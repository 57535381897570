import { ValidDialogAnalysis } from '.';
type Args = {
  token: string
  idChain: string
  callId: string
  emotions: {
    emoSign: ValidDialogAnalysis['emo_aro']
    emoPower: ValidDialogAnalysis['emo_val']
  }
};

export type EmotionsTempType = Args['emotions'];

// eslint-disable-next-line @typescript-eslint/require-await
export async function saveEmotionsTemp(x: Args) {
  const url = 'https://dashboards.reffection.com/dashboards/api/gen_process.php';
  const formData = JSON.stringify({
    token: x.token,
    process: 'save_emotions_star',
    data: {
      idchain: x.idChain,
      call_id: x.callId,
      analisys: {
        emo_aro: x.emotions.emoSign,
        emo_val: x.emotions.emoPower
      },
    }
  });

  try {
    await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: formData,
    });

  } catch (e) {
    console.log(e);
  }
};
