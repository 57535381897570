import { TagsAnalysis } from './commonTypes';

type Args = {
  token: string
  idChain: string
  callId: string
  subs: TagsAnalysis
};

export async function saveSubstatus(x: Args) {
  const url = 'https://dashboards.reffection.com/dashboards/api/gen_process.php';

  const saveTagsBody = JSON.stringify({
    process: 'save_subs',
    token: x.token,
    data: {
      idchain: x.idChain,
      subs: x.subs,
      call_id: x.callId,
    }
  });

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: saveTagsBody,
    });

    const r = await response.json();
    return r;

  } catch (e) {
    console.log(e);
  }
};
