import { z } from 'zod';
import { parseAndLogIfError } from '@api/parseAndLogIfError';

export type GetInitialListsResult = InitialSubsetLists;
export function isGetInitialListsResult(o: unknown): o is GetInitialListsResult {
  return parseAndLogIfError(InitialSubsetLists, o);
};

export type InitialSubsetLists = z.infer<typeof InitialSubsetLists>;
export type FilterInfo = z.infer<typeof FilterInfo>;
export type FilterInfos = z.infer<typeof FilterInfos>;

export async function getInitialLists(token: string) {
  const url = 'https://dashboards.reffection.com/dashboards/api/get_all_info.php';
  const formData = new URLSearchParams();
  formData.append('token', 'BCCA165471A6BB90D531EC08879941C7');
  formData.append('include', 'operator');

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded', },
      body: formData,
    });

    const r: unknown = await response.json();
    return r;

  } catch (e) {
    console.log(e);
    const r: unknown = {
      dates: [],
      filter_id: [],
      operators: [],
      projects: [],
      statuses: [],
      filters: {},
    };
    return r;
  }
};

const FilterInfo = z.object({
  cus: z.string(), // 'kia_alarm_motors_1205'
  dr_f: z.string(), // '30'
  dr_t: z.string(), // '60
  dt_f: z.string(), // '2021.11.04'
  dt_t: z.string(), // "2021-10-31"
  id_c: z.string(), // 19251562
  oper: z.string(), // 'ср.Лабадзе (Екатерина)'
  res: z.string(), // 'Уточнить решение'
  seg: z.string(), // '401103'
});

const InitialSubsetLists = z.object({
  dates: z.string().array(),
  operators: z.object({ id: z.string(), operator: z.string() }).array(),
  projects: z.string().array(),
  statuses: z.string().array(),
  filter_id: z.number().array(),
  filters: z.record(FilterInfo),
});

const FilterInfos = z.record(FilterInfo);
