import { CallInfoCtx } from '@contexts';
import * as api from '@api/baseSubset';
import { getDealInfo } from './getDealInfo';

type Args = {
  tags: api.TagsAnalysis
  token: string
  hasDelta: boolean
  state: CallInfoCtx
  dealId: string
};

export async function saveTags(x: Args) {
  const { tags, token, hasDelta, state, dealId } = x;
  const info = state.dealDatas[dealId];
  const idChain = info?.idchain;
  const callId = info?.calls.find(c => c.analisys)?.call_id;

  if (!idChain || !callId || !dealId) return;
  const { dispatch } = state;

  try {
    await api.saveTags({ idChain, tags, token, callId, hasDelta });
    return await getDealInfo({ id: dealId, token, state, force: true });
  } catch(e) {
    console.log(e);
    dispatch({ type: 'updateStateAsync', stateSlice: { isLoading: false } });
  }
};
