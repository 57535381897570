import React from 'react';
import { Search } from '@assets/icons';
import { cn } from '@utils';

import s from './InputSearch.module.css';

type Props = {
  type?: 'number' | 'text'
  name?: string
  className?: string
  keyValue?: string
  defaultValue?: string
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | 'none'
  disabled?: boolean
  dataCy?: string
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void
};

export function InputSearch(x: Props) {
  const { icon: Icon } = x;

  const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target;
    if (!(target instanceof HTMLInputElement)) return;

    if (e.key === 'Enter') return target.blur();
  };

  return (
    <div className={cn(s.inputSearch,
      x.className,
      x.defaultValue && s.selected,
      x.disabled && s.inputSearchDisabled
    )}>
      <input key={x.keyValue}
             className={cn(s.input,
               x.defaultValue && s.selected,
               x.disabled && s.inputDisabled)}
             type={x.type ? x.type : 'text'}
             name={x.name}
             data-cy={x.dataCy}
             defaultValue={x.defaultValue}
             onBlur={x.onBlur}
             onKeyPress={handleEnterPress}
             disabled={x.disabled}
             autoComplete="off" />
      { !Icon && <Search className={s.icon} /> }
      { Icon && Icon !== 'none' && <Icon className={s.icon} /> }
    </div>
  );
};
