import React, { useContext } from 'react';
import { formatDate, encodeOperatorList } from '@utils';
import { Button } from '@components';
import { FiltersCtx } from '@contexts';

import s from './CommonInfo.module.css';

type Props = {
  onResetClick: () => void
};

export function CommonInfo(x: Props) {
  const { operator, operators, project, selectedDates, initialLists } = useContext(FiltersCtx);
  const { operatorEncoded } = encodeOperatorList({ initialLists, operator, operators });

  const showRange = () => {
    if (!selectedDates) return 'Дата не выбрана';

    const [dateFrom, dateTo] = selectedDates;
    const dateFromStr = formatDate(dateFrom);
    let result = dateFromStr;

    const dateToStr = dateTo ? formatDate(dateTo) : '';
    if (dateToStr !== dateFromStr) {
      result = dateFromStr + (dateToStr ? ' - ' + dateToStr : '');
    }
    return result;
  };

  return (
    <div className={s.commonInfo}>
      <Button type="button" className={s.selection}
              dataCy='change'
              onClick={x.onResetClick}>
        Сменить выборку
      </Button>

      <div className={s.info} data-cy="common-info">
        <h5 className={s.title}>
          <span className={s.titleValue}>{ operatorEncoded?.text ?? 'Оператор не выбран' }</span>
        </h5>
        <h5 className={s.title}>
          <span className={s.titleValue}>{ project?.text ?? 'Проект не выбран' }</span>
        </h5>
        <h5 className={s.title} data-cy="data-range">
          <span className={s.titleValue}>{ showRange() }</span>
        </h5>
      </div>
    </div>
  );
};
