import { z } from 'zod';
import { parseAndLogIfError } from '@api/parseAndLogIfError';

type Args = {
  token: string
  dateFrom?: string
  dateTo?: string
};

type RequestBody = {
  token: string
  date_from?: string // 2022-01-11
  date_to?: string // 2022-01-13
};

export type GetStatsResult = z.infer<typeof GetStatsResult>;
export function isGetStatsResult(o: unknown): o is GetStatsResult {
  return parseAndLogIfError(GetStatsResult, o);
};

export async function getStats(x: Args) { // FIXME нужно вынести в отдельный контекст
  const url = 'https://dashboards.reffection.com/dashboards/api/get_work_stats.php';

  const requestBody: RequestBody = { token: x.token };
  if (x.dateTo) requestBody.date_to = x.dateTo;
  if (x.dateFrom) requestBody.date_from = x.dateFrom;

  const formData = JSON.stringify(requestBody);

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: formData,
    });

    const r = await response.json() as unknown;
    return r;

  } catch (e) {
    console.log(e);
  }
}

const GetStatsResult = z.object({
  work_stats: z.object({
    dt: z.string(),
    work_count: z.number(), // количество отправок на распознавание
    suc_dialogs: z.number(), // количество успешных
    error_dialogs: z.number(), // количество ошибок
    no_file_dialogs: z.number(), // количество диалогов без файлов, т.е. назад ни чего не пришло
    sum_tm_recogs: z.number(), // количество секунд отданных на распознавание
    avg_tm_work: z.number(), // среднее время распознавания
  }).array(),
});
