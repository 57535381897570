import React, { useContext, useState } from 'react';
import { Button } from '@components';
import { ConfigCtx } from '@contexts/config';

import s from '../ConfigBar.module.css';

export function Controls() {
  const [roleName, setRoleName] = useState('');
  const configCtx = useContext(ConfigCtx);
  const { dispatch, configTemp, changesStack } = configCtx;

  const req = /^\w*$/g;
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const str = e.target.value;
    if (!req.test(str)) return;
    setRoleName(str);
  };

  const handleRoleCreate = () => {
    dispatch({ type: 'createRole', name: roleName });
    setRoleName('');
  };

  const handleRoleRemove = () => {
    if (roleName === 'admin') {
      alert('Нельзя удалить админа!');
      return setRoleName('');
    }

    dispatch({ type: 'removeRole', name: roleName });
    setRoleName('');
  };

  const handleSaveConfig = () => configCtx.asyncDispatch(configCtx, { type: 'saveConfig', info: configTemp });
  const handleReset = () => dispatch({ type: 'reset' });

  return (
    <div className={s.controls}>
      <h4>Добавить/удалить роль</h4>

      <input className={s.input}
             onChange={handleInputChange}
             value={roleName}
             type="text"
             name="word"
             placeholder='Только латиница...'
             autoComplete='off' />
      <Button onClick={handleRoleCreate} disabled={!roleName} dataCy="add-role">+</Button>
      <Button onClick={handleRoleRemove} disabled={!roleName} dataCy="remove-role">-</Button>

      <Button dataCy="reset"
              disabled={changesStack.length === 0}
              onClick={handleReset}>Сбросить</Button>
      <Button dataCy="saveBtn"
              disabled={changesStack.length === 0}
              className={s.saveBtn}
              onClick={handleSaveConfig}>Сохранить
      </Button>
    </div>
  );
};
