import React, { useContext, useEffect, useRef, useState } from 'react';
import { cn, copySimpleObj, useControlBtn } from '@utils';
import { FiltersCtx, AuthCtx, CallInfoCtx, ConfigCtx } from '@contexts';
import { Button, RadioUncontrolled } from '@components';
import { isEmptyObj } from '@type-guards';

import s from './LeadsBar.module.css';

const THRESHOLD = 0.6;

type Props = {
  className?: string
};

export function LeadsBar(x: Props) {
  const filtersCtx = useContext(FiltersCtx);
  const { accessType, token } = useContext(AuthCtx);
  const { configTemp } = useContext(ConfigCtx);
  const callCtx = useContext(CallInfoCtx);

  const valueRef = useRef<string>();

  const [selectionHasChanged, setSelectionHasChanged] = useState(false);
  const [initialSelection, setInitialSelection] = useState<string>();
  const [radioKey, setRadioKey] = useState(Math.round(Date.now()));

  const { status, isLoading, deal } = filtersCtx;
  const { dealDatas } = callCtx;
  const callInfo = deal && dealDatas[deal.id];

  useEffect(() => {
    setRadioKey(Date.now());
    const callToShow = callInfo?.calls.find(c => !Array.isArray(c.analisys));
    const data = callToShow?.analisys?.subs;

    if (!data) return;
    const selected = Object.entries(data).filter(([k, v]) => v.okk_true === 1)?.[0]?.[0];
    setInitialSelection(selected);
    setSelectionHasChanged(false);
    valueRef.current = selected;
  }, [callInfo]);

  const { handleControlClick, showExtraInfo } = useControlBtn(); // Надеюсь, это временно..

  const emptyWrapper = (message?: string) => (
    <div className={cn(s.leadsBar, x.className)}>
      { message && <h4>{ message }</h4> }
    </div>
  );

  if (!status) return emptyWrapper('Выберите статус...');
  const statusId = status.id;

  if (statusId !== 'Выслушал, отказался') {
    return emptyWrapper('Только для статуса "Выслушал, отказался"');
  }

  if (!callInfo) return emptyWrapper('Выберите звонок...');

  const callToShow = callInfo?.calls.find(c => !Array.isArray(c.analisys));
  const data = callToShow?.analisys?.subs;
  if (!data || isEmptyObj(data)) return emptyWrapper('Звонок не проанализирован...');

  const callId = callToShow.call_id;
  const dataArr = Object.entries(data);

  const handleSelect = (inputValue: string) => {
    valueRef.current = inputValue;
    setSelectionHasChanged(inputValue !== initialSelection);
  };

  const handleSave = () => {
    const dataCopy = copySimpleObj(data);
    const selectedValue = valueRef.current;

    if (selectedValue) {
      Object.values(dataCopy).forEach(v => (v.okk_true = 0));
      dataCopy[selectedValue].okk_true = 1;
    }

    const dealId = deal?.id;
    if (!dealId) return;
    const idChain = callInfo?.idchain ?? '';

    callCtx.asyncDispatch(callCtx, { type: 'saveSubstatus', callId, dealId, idChain, subs: dataCopy, token });
  };

  const handleResetClick = () => {
    setRadioKey(Date.now());
    setSelectionHasChanged(false);

    if (!valueRef.current) return;
    valueRef.current = initialSelection;
  };

  const showNeuroEstimate = configTemp[accessType]?.neuro.includes('show') || showExtraInfo;

  return (
    <div className={cn(s.leadsBar, x.className)} data-cy="leads-bar">
      <h4>{ statusId }</h4>
      <h4>Подстатус: </h4>

      <ul className={s.list} key={callId}>
        { dataArr.map(([leadName, value]) => (
          <li key={leadName}
              className={cn(showNeuroEstimate ? s.listItem : s.listItemReduced)}>

            { showNeuroEstimate && (
              <>
                <input className={s.listItemInput} type="checkbox" readOnly
                       name={value.name}
                       checked={value.pred >= THRESHOLD}
                       data-cy="neuro-value"
                       disabled />
                <span className={s.listItemValue}>{ (value.pred * 100).toFixed(0) }%</span>
              </>
            ) }

            <span></span>
            <label>
              <RadioUncontrolled defaultChecked={value.okk_true === 1}
                                 radioKey={radioKey}
                                 onChange={() => handleSelect(leadName)}
                                 name="lead"
                                 value={leadName} />
              <span className={s.listItemTitle}>{ value.name }</span>
            </label>
          </li>
        )) }
      </ul>

      <div className={s.controlsWrapper}>
        <Button className={s.saveBtn} disabled={isLoading || !selectionHasChanged}
                dataCy="save"
                onClick={handleSave}>
          Сохранить
        </Button>

        <Button className={s.resetBtn} disabled={isLoading || !selectionHasChanged}
                dataCy="reset"
                onClick={handleResetClick}>
          Сбросить
        </Button>

        { !showNeuroEstimate && (
          <Button className={s.controlBtn} onClick={handleControlClick}>Контроль</Button>
        ) }
      </div>
    </div>
  );
};
