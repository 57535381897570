import React, { useLayoutEffect, useRef } from 'react';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

type Obj = {
  [key: string]: string | undefined
};

type ChartProps = {
  className?: string
  data: [Obj, Obj]
  colors: string[]
  dataCy: string
};

export function EmotionsChart(x: ChartProps) {
  const ref = useRef<HTMLDivElement>(null);
  const data = x.data;

  useLayoutEffect(() => {
    if (!ref || !ref.current) return;
    ref.current.dataset.ready = 'false';

    const chart = am4core.create(ref.current, am4charts.XYChart);
    chart.data = x.data;
    chart.properties.paddingTop = 0;

    chart.colors.list = x.colors.map(c => am4core.color(c));

    chart.legend = new am4charts.Legend();
    chart.legend.position = 'bottom';
    chart.marginTop = 0;

    chart.events.on('ready', () => {
      if (!ref.current) return;
      ref.current.dataset.ready = 'true';
    });

    chart.properties.marginTop = 0;
    chart.properties.paddingLeft = 0;

    const markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 10;
    markerTemplate.height = 10;

    const yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    yAxis.dataFields.category = 'actor';
    yAxis.renderer.grid.template.opacity = 0;

    const xAxis = chart.xAxes.push(new am4charts.ValueAxis());
    xAxis.min = 0;
    xAxis.renderer.grid.template.opacity = 0;
    xAxis.renderer.baseGrid.disabled = true;
    xAxis.renderer.minGridDistance = 40;
    xAxis.renderer.labels.template.disabled = true;

    function createSeries(name: string) {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = name;
      series.dataFields.categoryY = 'actor';
      series.stacked = true;
      series.name = name;
      series.columns.template.tooltipText = '{valueX}';
      series.columns.template.height = 20;
    }

    Object.keys(data[0])
      .filter(name => name !== 'actor')
      .forEach(name => createSeries(name));

    xAxis.renderer.labels.template.paddingBottom = 5;

    return () => chart.dispose();
  });

  return (
    <div ref={ref} className={x.className} data-cy={x.dataCy} data-ready="false"></div>
  );
};
