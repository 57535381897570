import { isDeal, DealsObj, } from '@api/baseSubset';
import { isObj } from '@type-guards';
import { getArrayUniqueByKey } from '@utils';
import { State, StateSlice } from '../reducer';
import { getInfoByFilters } from '.';

export async function updateFilters(state: State, token: string): Promise<void> {
  const { deal, dispatch, filterId, filterIds, tableId } = state;
  const dealInfos = await getInfoByFilters({ state, token });

  if (!isObj(dealInfos)) {
    return dispatch({ type: 'updateStateAsync', stateSlice: { isLoading: false } });
  }

  const allDealInfosValues = Object.values(dealInfos);
  const validDealInfosValues = allDealInfosValues.filter(isDeal);
  if (allDealInfosValues.length !== validDealInfosValues.length) {
    console.log('has invalid values');
    const invalidDeals = allDealInfosValues.filter(d => !isDeal(d));
    console.log('validDeals', validDealInfosValues);
    console.log('invalidDeals', invalidDeals);
  }

  const dealInfosValues = validDealInfosValues;

  const operators = getArrayUniqueByKey(dealInfosValues, 'operator')
    .map(i => ({ id: i.operator, text: i.operator }));
  const projects = getArrayUniqueByKey(dealInfosValues, 'customer')
    .map(i => ({ id: i.customer, text: i.customer }));
  const statuses = getArrayUniqueByKey(dealInfosValues, 'result')
    .map(i => ({ id: i.result, text: i.result }));

  const substatusesSet = new Set<string>();
  dealInfosValues.forEach(d => {
    if (!d.substatuses) return;
    d.substatuses.forEach(s => substatusesSet.add(s));
  });
  const substatuses = [...substatusesSet].map(i => ({ id: i, text: i }));

  const dealDates = dealInfosValues.map(i => new Date(i.dt.replace(' ', 'T')));

  const dealList = dealInfosValues
    .map(i => ({ id: String(i.deal_id), text: String(i.deal_id) }));

  const dealDatas = {} as DealsObj;
  dealInfosValues.forEach(i => { dealDatas[i.deal_id] = i; });

  const stateSlice: StateSlice = {
    deal, dealList, availableDates: dealDates,
    operators, projects, statuses,
    filterIds: filterId ? [filterId] : filterIds,
    isLoading: false,
    tableId,
    substatuses,
  };

  if (filterId) {
    stateSlice.savedDeals = Object.keys(dealDatas);
  }

  if (dealDates.length === 1) stateSlice.selectedDates = [dealDates[0], dealDates[0]];
  if (operators.length === 1) stateSlice.operator = operators[0];
  if (statuses.length === 1) stateSlice.status = statuses[0];
  if (projects.length === 1) stateSlice.project = projects[0];

  if (dealList.length === 1) {
    const dealListItem = dealList[0];
    stateSlice.deal = dealListItem;
  } else if (dealList.length === 0) {
    stateSlice.deal = undefined;
  }

  return dispatch({ type: 'updateStateAsync', stateSlice });
};
