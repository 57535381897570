import * as api from '@api/dictionaries';
import { StateSlice, State } from '../reducer';

type Args = {
  state: State
  id: number
  data: State['dictInfos'][number]
  token: string
  name: string
};

export async function updateDictionary(x: Args) {
  const { id, data, token } = x;
  const { dispatch, dictInfos } = x.state;
  const info = await api.updateDictionary({ data, token, id });
  const stateSlice: StateSlice = { isLoading: false };

  if (api.isUpdateDictionaryResult(info)) {
    const { color, words } = info.dict;
    stateSlice.dictInfos = { ...dictInfos, [id]: { color, words } };
  }

  dispatch({ type: 'updateStateAsync', stateSlice });
  return info;
};
