import { z } from 'zod';
import { parseAndLogIfError } from '@api/parseAndLogIfError';

export type DialogAnalysis = z.infer<typeof dialogAnalysis>; // [] для непроанализированных звонков
export type Estimate = z.infer<typeof Estimate>;
export type TagsAnalysis = z.infer<typeof TagsAnalysis>;
export type TonesAnalysis = z.infer<typeof TonesAnalysis>;
export type FullAnalysis = z.infer<typeof FullAnalysis>;
export type ValidAnalysis<T> = Exclude<T, undefined | null | Record<string, never>>;

export type Deal = z.infer<typeof Deal>;
export function isDeal(o: unknown): o is Deal {
  return parseAndLogIfError(Deal, o);
}

export type DealsObj = Partial<z.infer<typeof DealsObj>>;
export function isDealsObj(o: unknown): o is DealsObj {
  return parseAndLogIfError(DealsObj, o);
}

export type ValidDialogAnalysis = ValidAnalysis<z.infer<typeof ValidDialogAnalysis>>;
export function isValidDialogAnalysis(o: unknown): o is ValidDialogAnalysis {
  return parseAndLogIfError(ValidDialogAnalysis, o);
}

const Estimate = z.object({
  pred: z.number(),
  true: (z.union([z.literal(0), z.literal(1), z.literal(-1)])).optional(),
  okk_pred: z.number().optional(),
  okk_true: z.number().optional(),
});

const ValidDialogAnalysis = z.object({
  emo_aro: z.record(Estimate).optional(),
  emo_val: z.record(Estimate).optional(),
  end: z.record(z.string()),
  interrupt: z.record(z.number()),
  speaker: z.record(z.string()),
  speech: z.record(z.string()),
  start: z.record(z.string()),
  word_tempo: z.record(z.number()),
});

const dialogAnalysis = z.union([ValidDialogAnalysis, z.tuple([]), z.object({})]);

const EstimateWithName = Estimate.extend({ name: z.string().optional() });
const TagsAnalysis = z.union([z.record(EstimateWithName), z.object({})]);

const Tone = z.record(Estimate);
const TonesAnalysis = z.union([
  z.object({
    abonent: Tone,
    operator: Tone,
  }),
  z.object({})
]);

const EmoDictionary = z.record(z.string());

const FullAnalysis = z.object({
  dialogs: dialogAnalysis,
  dicts: z.object({
    dostoevsky: EmoDictionary,
    watson: EmoDictionary,
    tags: z.record(z.string()).optional(),
  }),
  tags: TagsAnalysis,
  tones: TonesAnalysis,
  subs: TagsAnalysis,
}).partial();

const Deal = z.object({
  info_comment: z.string().optional(),
  result: z.string(),
  comment: z.string(),
  customer: z.string(), // project
  date: z.string(), // 2021-11-01
  dt: z.string(), // 2021-11-01 10:22:40.783
  time: z.string(), // "14:00:31",
  deal_id: z.union([z.number(), z.string()]),
  operator: z.string(), // оп.Азизова (Карина)
  script: z.string().optional(),
  idtable: z.number(),
  idchain: z.string(),
  substatuses: z.string().array().optional(),

  calls: z.object({
    call_id: z.string(),
    url: z.string(),
    duration: z.number(),
    filter_id: z.number(),
    analisys: FullAnalysis,
  }).partial({ analisys: true }).array(),
});

export const DealsObj = z.record(Deal);
