import React, { useContext, useEffect, useRef, useState, memo } from 'react';
import { ValidDialogAnalysis } from '@api/baseSubset';
import { cn, copySimpleObj, mapTextToHTMLForHighlight, useControlBtn } from '@utils';
import { isEmptyObj } from '@type-guards';
import { AuthCtx, CallInfoCtx, ConfigCtx } from '@contexts';

import { Button } from '@components';
import s from './EmotionsTemp.module.css';

type Props = {
  className?: string
  wordsToHighlight: string[] | undefined
  dealId: string
};

type MiniStore = {
  emoPower: ValidDialogAnalysis['emo_val']
  emoSign: ValidDialogAnalysis['emo_aro']
};
type MiniStoreRef = React.MutableRefObject<MiniStore | null>;

const HIGHLIGHT_COLOR = 'yellow';

function EmotionsTemp(x: Props) {
  const { dealId } = x;
  const { accessType, token } = useContext(AuthCtx);
  const { configTemp } = useContext(ConfigCtx);
  const miniStore = useRef<MiniStore | null>(null);
  const callCtx = useContext(CallInfoCtx);
  const { showExtraInfo, handleControlClick } = useControlBtn();

  const { dealDatas, isLoading } = callCtx;
  const callInfo = dealDatas[x.dealId];
  const callToShow = callInfo?.calls.find(c => !Array.isArray(c.analisys));
  const analysis = callToShow?.analisys?.dialogs;

  const showControl = accessType !== 'admin';
  const showNeuro = configTemp[accessType]?.neuro.includes('show') || showExtraInfo;

  useEffect(() => {
    if (!analysis) return;
    if (Array.isArray(analysis) || isEmptyObj(analysis)) return;

    miniStore.current = {
      emoSign: analysis.emo_aro,
      emoPower: analysis.emo_val
    };

  }, [dealId]);

  if (!callInfo) return null;

  const callSelectedButNotAnalized
    = !analysis || Array.isArray(analysis) || isEmptyObj(analysis);
  if (callSelectedButNotAnalized) {
    return <h3 className={cn(s.wrapper, x.className)}>Эмоции* не обработаны</h3>;
  }

  const handleSave = () => {
    if (!miniStore.current) return;
    if (!dealId) return;

    const idChain = callInfo?.idchain ?? '';
    const callId = callToShow.call_id;

    callCtx.asyncDispatch(callCtx, {
      type: 'saveEmotionsTemp', callId, dealId,
      emotions: copySimpleObj(miniStore.current),
      idChain, token
    });
  };

  const mappedDialog = mapToChart(analysis);

  return (
    <div className={cn(s.wrapper, x.className, isLoading && s.wrapperDisabled)}
         data-cy="emotions-temp" data-disabled={isLoading}>
      <div className={s.caption}>
        <span className={s.plusMinus}>Знак</span>
        <span className={s.power}>Сила</span>
      </div>

      <div className={s.rows}>
        { mappedDialog.map((d, i) => (
          <div className={cn(s.row, d.speaker[0] === 'a' && s.abonent)} key={i}>
            <h4 className={s.speaker}>
              { d.speaker[0].toUpperCase() }:
            </h4>

            { x.wordsToHighlight && (
              <span className={s.text}
                    dangerouslySetInnerHTML={
                      { __html: mapTextToHTMLForHighlight(d.speech, x.wordsToHighlight, HIGHLIGHT_COLOR) }
                    } />
            ) }
            { !x.wordsToHighlight && (
              <span className={s.text}> { d.speech } </span>
            ) }

            <SignRange autoValue={d.emoSignAuto}
                       okkValue={d.emoSignOkk}
                       elKey={dealId + 's' + i + String(d.emoSignAuto) + String(d.emoSignOkk)}
                       num={String(i)}
                       showNeuro={showNeuro}
                       miniStore={miniStore} />
            <PowerRange autoValue={d.emoPowerAuto}
                        okkValue={d.emoPowerOkk}
                        elKey={dealId + 'p' + i + String(d.emoPowerAuto) + String(d.emoPowerOkk)}
                        num={String(i)}
                        showNeuro={showNeuro}
                        miniStore={miniStore} />
          </div>
        )) }
        <div className={s.controls}>
          <Button className={s.saveBtn}
                  disabled={isLoading}
                  onClick={handleSave}>Сохранить</Button>
          { showControl && (
            <Button className={s.saveBtn}
                    disabled={isLoading}
                    onClick={handleControlClick}>Контроль</Button>
          ) }
        </div>
      </div>

    </div>
  );
};

type RangeType = {
  autoValue: number
  okkValue: number
  num: string
  miniStore: MiniStoreRef
  showNeuro: boolean
  elKey: string
};

function SignRange(x: RangeType) {
  const [okkValue, setOkkValue] = useState(x.okkValue ?? 0);

  useEffect(() => {
    setOkkValue(x.okkValue);
  }, [x.elKey]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    setOkkValue(Number(e.target.value));

    if (!x.miniStore?.current?.emoSign) return;
    x.miniStore.current.emoSign[x.num].okk_pred = value;
  };

  return (
    <div className={s.plusMinusRange}>
      <span className={s.plusMinusValue} data-cy='sign-value'>{ okkValue }</span>
      { x.showNeuro && (
        <input type="range" name="" disabled defaultValue={x.autoValue}
               min={-10} max={10} data-cy="neuro-sign" />
      ) }
      <input type="range" name="" max={10} min={-10} value={okkValue}
             onChange={handleChange} />
    </div>
  );
};

function PowerRange(x: RangeType) {
  const [okkValue, setOkkValue] = useState(x.okkValue ?? 0);

  useEffect(() => {
    setOkkValue(x.okkValue);
  }, [x.elKey]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    setOkkValue(Number(e.target.value));

    if (!x.miniStore?.current?.emoPower) return;
    x.miniStore.current.emoPower[x.num].okk_pred = value;
  };

  return (
    <div className={s.powerRange}>

      { x.showNeuro && (
        <input type="range" name="" disabled defaultValue={x.autoValue}
               min={0} max={10} data-cy="neuro-power" />
      ) }
      <input type="range" name="" min={0} max={10} value={okkValue}
             onChange={handleChange} />
      <span className={s.powerRangeValue} data-cy="power-value">{ okkValue }</span>
    </div>
  );
};

function mapToChart(data: ValidDialogAnalysis) {
  type ResultType = {
    speech: string
    speaker: string
    emoPowerAuto: number
    emoSignAuto: number
    emoPowerOkk: number
    emoSignOkk: number
  }[];
  const acc: ResultType = [];

  for (let i = 0; ; i++) {
    const speech = (data.speech)[i];
    if (!speech) break;

    const speaker = (data.speaker)[i];
    if (!speaker) break;

    const emoSignAuto = data.emo_aro?.[i]?.pred ?? 0;
    const emoSignOkk = data.emo_aro?.[i]?.okk_pred ?? 0;

    const emoPowerAuto = data.emo_val?.[i]?.pred ?? 0;
    const emoPowerOkk = data.emo_val?.[i]?.okk_pred ?? 0;

    acc.push({ speech, speaker, emoPowerAuto, emoSignAuto, emoPowerOkk, emoSignOkk });
  }

  return acc;
}

const MemoizedEmotionsTemp = memo(EmotionsTemp);
export { MemoizedEmotionsTemp as EmotionsTemp };
