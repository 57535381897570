import { url } from './constants';

type Args = {
  formData: string
  errorReturn?: unknown
  successLog?: string
};

export async function basicRequest(x: Args) {
  const { formData, errorReturn = null, successLog } = x;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', },
      body: formData,
    });

    const result: unknown = await response.json();
    if (successLog) console.log(successLog, result);
    return result;

  } catch (e) {
    console.log(e);
    const result: unknown = errorReturn;
    return result;
  }
}
