import React from 'react';
import ReactDOM from 'react-dom';
import { FiltersProvider, AuthCtxProvider, CallInfoCtxProvider, ConfigCtxProvider, DictionaryCtxProvider } from '@contexts';
import { App } from './App';

ReactDOM.render(
  <AuthCtxProvider>
    <ConfigCtxProvider>
      <DictionaryCtxProvider>
        <CallInfoCtxProvider>
          <FiltersProvider>
            <App />
          </FiltersProvider>
        </CallInfoCtxProvider>
      </DictionaryCtxProvider>
    </ConfigCtxProvider>
  </AuthCtxProvider>,
  document.getElementById('root')
);
