import { FullAnalysis } from './commonTypes';
export type SaveEmotionsArgs = {
  token: string
  idChain: string
  callId: string
  tones: NonNullable<FullAnalysis['tones']>
};

export async function saveEmotions(x: SaveEmotionsArgs) {
  const url = 'https://dashboards.reffection.com/dashboards/api/gen_process.php';
  const formData = JSON.stringify({
    token: x.token,
    process: 'save_emotions',
    data: {
      idchain: x.idChain,
      call_id: x.callId,
      analisys: { tones: x.tones }
    }
  });

  try {
    await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: formData,
    });

  } catch (e) {
    console.log(e);
  }
};

