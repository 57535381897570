import * as api from '@api/baseSubset';
import { Dispatch, StateSlice } from '../reducer';

type Args = {
  token: string
  project: string
  dealId: string
  idTable: number
  dealDatas: api.DealsObj
  callInfo: api.DealsObj[string]
  dispatch: Dispatch
};

export async function getDealHistory(x: Args) {
  const { token, project, dealId, idTable, dispatch, dealDatas, callInfo } = x;
  const result = await api.getDealHistory({ dealId, idTable, token, project });
  const { isGetDealHistoryResult, isDeal } = api;

  let stateSlice: StateSlice = { callHistoryState: 'empty', isLoading: false };

  if (!isGetDealHistoryResult(result)) {
    return dispatch({ type: 'updateStateAsync', stateSlice }); // FIXME
  }

  const historyCalls = Object.values({ ...result, [dealId]: callInfo })
    .filter(isDeal)
    .sort((a, b) => (String(a.dt) > String(b.dt) ? -1 : 1));

  stateSlice = {
    isLoading: false,
    callHistoryState: 'not-empty',
    dealDatas: { ...dealDatas, ...result, [dealId]: callInfo },
    historyCalls,
  };

  dispatch({ type: 'updateStateAsync', stateSlice });
  return result;
};
