import { formatDate } from '@utils';
import { getStats, GetStatsResult, isGetStatsResult } from '@api/baseSubset';

const weekAgoInMilliseconds = 6 * 24 * 3600_000;
const dayWeekAgo = new Date(Number(new Date()) - weekAgoInMilliseconds);

export const initialState = {
  datesRange: [dayWeekAgo, new Date()] as [Date, Date?],
  stats: undefined as GetStatsResult | undefined,
  chartsEqualityKey: {}, // it will update only after load
  isLoading: false,
  dispatch: {} as Dispatch
};

const resetLoader: Action = { type: 'updateStateAsync', stateSlice: { isLoading: false } };
export type Dispatch = React.Dispatch<Action>;
type State = typeof initialState;
type StateSlice = Partial<State> & {isLoading: boolean};

type Action =
  | { type: 'selectDates'; dates: [Date, Date?] }
  | { type: 'resetDatesRange' }
  | { type: 'loadStats'; token: string }
  | { type: 'updateStateAsync'; stateSlice: StateSlice }
  | { type: 'initDispatch'; dispatch: Dispatch };

export function reducer(state: State, action: Action): State {
  const { type } = action;

  if (type === 'initDispatch') {
    return { ...state, dispatch: action.dispatch };
  } else if (type === 'selectDates') {
    return { ...state, datesRange: action.dates };

  } else if (type === 'resetDatesRange') {
    return { ...initialState, dispatch: state.dispatch };

  } else if (type === 'loadStats') {
    const { token } = action;
    const { datesRange: [dateFrom, dateTo], dispatch } = state;
    loadStats({ token, dateFrom, dateTo, dispatch });
    return { ...state, isLoading: true };

  } else if (type === 'updateStateAsync') {
    const { stateSlice } = action;
    return { ...state, ...stateSlice };
  }

  const remnant: never = type;
  throw new Error(`unhandled action.type = ${remnant} in MonitorBar reducer`);
}

type LoadErrorsArgs = {
  token: string
  dateFrom: Date
  dateTo?: Date
  dispatch: Dispatch
};

async function loadStats(x: LoadErrorsArgs) {
  const { dispatch, token, dateFrom, dateTo } = x;
  const dateFromStr = formatDate(dateFrom, 'yyyy-mm-dd');
  const dateToStr = dateTo ? formatDate(dateTo, 'yyyy-mm-dd') : dateFromStr;
  try {
    const r = await getStats({ token, dateFrom: dateFromStr, dateTo: dateToStr });

    console.log('r', r);
    if (!isGetStatsResult(r)) return dispatch(resetLoader);

    const stateSlice: StateSlice = {
      stats: r,
      isLoading: false,
      chartsEqualityKey: {},
    };
    dispatch({ type: 'updateStateAsync', stateSlice });

  } catch(e) {
    console.log(e);
    dispatch(resetLoader);
  }
}
