import * as api from '@api/dictionaries';
import { State, StateSlice } from '../reducer';

type Args = {
  state: State
  token: string
};

export async function getDictsList(x: Args) {
  const { dispatch } = x.state;
  const list = await api.loadList(x.token);

  if (!api.isLoadListResult(list)) {
    return dispatch({ type: 'updateStateAsync', stateSlice: { isLoading: false } });
  }

  const stateSlice: StateSlice = {
    isLoading: false,
    dictionaries: list.map(d => ({ id: String(d.id), text: d.dict_name }))
  };
  dispatch({ type: 'updateStateAsync', stateSlice });
  return list;
}
