import React, { createContext, useEffect, useReducer } from 'react';
import { PureWrapper } from '@components';
import { CallInfoCtxReducer, initialState } from './reducer';

export const CallInfoCtx = createContext(initialState);
export type CallInfoCtx = typeof initialState;

const { Provider } = CallInfoCtx;

type Props = {
  children: React.ReactNode
};

export function CallInfoCtxProvider(x: Props) {
  const [state, dispatch] = useReducer(CallInfoCtxReducer, initialState);
  useEffect(() => dispatch({ type: 'initDispatch', dispatch }), []);

  if (typeof state.dispatch !== 'function') return null;

  return (
    <Provider value={{ ...state, dispatch }}>
      <PureWrapper>
        { x.children }
      </PureWrapper>
    </Provider>
  );
};
