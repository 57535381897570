import { z } from 'zod';
import { parseAndLogIfError } from '@api/parseAndLogIfError';
import { RequestDicts } from './types';
import { basicRequest } from './basicRequest';

type Args = {
  token: string
  id: number
};
export async function getDictionary(x: Args): Promise<unknown> {
  const { token, id } = x;
  const sendingObject: RequestDicts = {
    token,
    process: 'dict_work',
    data: {
      proc: 'sel',
      id,
    }
  };

  const formData = JSON.stringify(sendingObject);
  return basicRequest({ formData });
};

export type GetDictionaryResult = z.infer<typeof GetDictionaryResult>;
export function isGetDictionaryResult(o: unknown): o is GetDictionaryResult {
  return parseAndLogIfError(GetDictionaryResult, o);
};

const GetDictionaryResult = z.object({
  id: z.number(),
  dict_name: z.string(),

  dict: z.object({
    words: z.string().array(),
    color: z.string(),
  }),
});
