import { ListItem } from '@components';
import * as api from '@api/dictionaries';
import * as h from './helpers';

type DictInfos = {
  [id: number]: api.GetDictionaryResult['dict'] | undefined
};

export const initialState = {
  dictionaries: undefined as undefined | ListItem[],
  isLoading: false,
  dictInfos: {} as DictInfos,
  dispatch: {} as Dispatch,
  asyncDispatch,
};

export type State = typeof initialState;
export type StateSlice = Partial<State> & { isLoading: boolean };
export type Dispatch = React.Dispatch<Action>;

type Action =
  | { type: 'updateStateAsync'; stateSlice: StateSlice }
  | { type: 'initDispatch'; dispatch: Dispatch }
;

export function DictionaryCtxReducer(state: State, action: Action): State {
  const { type } = action;
  if (state.isLoading && type !== 'updateStateAsync') return state;

  if (type === 'updateStateAsync') {
    const { stateSlice } = action;
    return { ...state, ...stateSlice };

  } else if (type === 'initDispatch') {
    return { ...state, dispatch: action.dispatch };
  }

  const remnant: never = type;
  throw new Error(`unhandled action.type = ${remnant} in dictionary reducer`);
}

type ActionAsync =
  | { type: 'loadDictInfo'; id: number; token: string }
  | { type: 'create'; name: string; token: string }
  | { type: 'delete'; id: number; token: string }
  | { type: 'getDictsList'; token: string }
  | { type: 'update'; id: number; data: DictInfos[number]; token: string; name: string }
;

async function asyncDispatch<T extends ActionAsync, U extends T['type']>(state: State, action: T & ActionAsync): Promise<AsyncDispatchResults[U]> {
  const { type } = action;
  const setIsLoading = () => state.dispatch({ type: 'updateStateAsync', stateSlice: { isLoading: true } });

  if (type === 'getDictsList') {
    const { token } = action;
    setIsLoading();
    return h.getDictsList({ state, token });

  } else if (type === 'create') {
    const { token, name } = action;
    const { dictionaries } = state;
    if (dictionaries?.find(d => d.text === name)) return;
    setIsLoading();

    return h.createDictionary({ name, state, token });

  } else if (type === 'update') {
    const { id, data, token, name } = action;
    setIsLoading();
    return h.updateDictionary({ state, id, data, token, name });

  } else if (type === 'delete') {
    const { id, token } = action;
    setIsLoading();
    return h.deleteDictionary({ id, state, token });

  } else if (type === 'loadDictInfo') {
    const { id, token } = action;
    setIsLoading();
    return h.loadDictInfo({ state, id, token });
  }
};

type AsyncDispatchResults = {
  create: ReturnType<typeof h.createDictionary>
  getDictsList: ReturnType<typeof h.getDictsList>
  update: ReturnType<typeof h.updateDictionary>
  delete: ReturnType<typeof h.deleteDictionary>
  loadDictInfo: ReturnType<typeof h.loadDictInfo>
};
