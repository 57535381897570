import React, { useContext, useRef, useState, useEffect } from 'react';
import { FullAnalysis, ValidAnalysis } from '@api/baseSubset';
import { SmileyNegative, SmileyNeutral, SmileyPositive } from '@assets/icons';
import { FiltersCtx, AuthCtx, CallInfoCtx, ConfigCtx } from '@contexts';
import { isEmptyObj } from '@type-guards';
import { cn, copySimpleObj, useControlBtn } from '@utils';
import { Button } from '@components';
import { EmotionsChart } from './components';

import s from './EmotionsBar.module.css';

const mockWOperator: [string, {pred: number; okk_pred: number}][] = [ // mock Watson
  ['Грусть', { pred: 0.1, okk_pred: 0 }],
  ['Радость', { pred: 0.15, okk_pred: 0 }],
  ['Страх', { pred: 0.39, okk_pred: 0 }],
  ['Недовольство', { pred: 0.12, okk_pred: 0 }],
  ['Гнев', { pred: 0.8, okk_pred: 0 }],
];

const mockWAbonent: [string, {pred: number; okk_pred: number}][] = [ // mock Watson
  ['Грусть', { pred: 0.2, okk_pred: 0 }],
  ['Радость', { pred: 0.58, okk_pred: 0 }],
  ['Страх', { pred: 0.32, okk_pred: 0 }],
  ['Недовольство', { pred: 0.1, okk_pred: 0 }],
  ['Гнев', { pred: 0.7, okk_pred: 0 }],
];

const chartColors = {
  dostoevsky: ['lightgray', 'crimson', 'forestgreen'],
  watson: ['yellow', 'green', 'black', 'orange', 'red']
};

type MiniStore = {
  dostoevsky: ValidAnalysis<FullAnalysis['tones']>
  watson: ValidAnalysis<FullAnalysis['tones']>
  callId: string
};

type Methods = 'dostoevsky' | 'watson';
type Actor = 'abonent' | 'operator';

type Props = {
  className?: string
};

export function EmotionsBar(x: Props) {
  const filtersCtx = useContext(FiltersCtx);
  const { accessType, token } = useContext(AuthCtx);
  const miniStore = useRef<MiniStore | null>(null);
  const { configTemp } = useContext(ConfigCtx);
  const callCtx = useContext(CallInfoCtx);
  const [keyPart, setKeyPart] = useState(Math.round(Date.now()));
  const [method, setMethod] = useState<Methods>('dostoevsky');
  const updateComponent = useState({})[1];
  const { handleControlClick, showExtraInfo } = useControlBtn();

  const { deal } = filtersCtx;
  const { dealDatas } = callCtx;
  const callInfo = deal && dealDatas[deal.id];
  const callToShow = callInfo?.calls.find(c => c.analisys?.tones);
  const [tones, setTones] = useState(callToShow?.analisys?.tones);

  useEffect(() => {
    setKeyPart(Date.now());
    return () => {
      if (miniStore.current) miniStore.current = null;
    };
  }, [callInfo]);

  const emptyWrapper = (message?: string) => (
    <div className={cn(s.wrapper, x.className)}>
      { message && <h4>{ message }</h4> }
    </div>
  );
  if (!callToShow) return emptyWrapper();

  if (!miniStore.current || miniStore.current.callId !== callToShow?.call_id) {
    // FIXME по-всей видимости, рефы здесь уже не нужны.
    // хотя, как вариант, можно их связать с
    // методом chart.add и запрашивать актуальные данные для него
    // именно через реф...
    const tonesNew = callToShow?.analisys?.tones;
    if (!tonesNew || isEmptyObj(tonesNew)) return emptyWrapper('Не обработано..');
    const dostoevsky = filterDostoevskyEmo(copySimpleObj(tonesNew));

    // FIXME заменить, когда придут реальные данные по Ватсону
    // работать все равно не будет, т.к. тех полей, что в Достоевском
    // здесь нет. Указано для совместимости типов
    const watson = copySimpleObj(tonesNew);

    miniStore.current = { dostoevsky, watson, callId: callToShow.call_id };
    setTones(tonesNew);
  }

  if (Array.isArray(tones) || !tones || isEmptyObj(tones)) return emptyWrapper('Не обработано...');

  const operatorEmosEntries = method === 'dostoevsky'
    ? Object.entries(miniStore.current ? miniStore.current.dostoevsky.operator : tones.operator)
    : mockWOperator;
  const abonentEmosEntries = method === 'dostoevsky'
    ? Object.entries(miniStore.current ? miniStore.current.dostoevsky.abonent : tones.abonent)
    : mockWAbonent;

  const handleSendBtnClick = () => {
    const tonesLocal = miniStore.current?.dostoevsky;
    if (!tonesLocal) return;

    const dealId = String(callInfo?.deal_id);
    const idChain = callInfo?.idchain ?? '';
    const callId = callToShow.call_id;
    callCtx.asyncDispatch(callCtx, { type: 'saveEmotions', dealId, idChain, tones: tonesLocal, callId, token });
    miniStore.current = null;
  };

  const OkkLayoutCahrtData = mapEmosToChart(operatorEmosEntries, abonentEmosEntries, 'OKK');
  const NeuroLayoutCahrtData = mapEmosToChart(operatorEmosEntries, abonentEmosEntries, 'Neuro');

  const isLoading = callCtx.isLoading || filtersCtx.isLoading;
  const sendBtnIsDisabled = method === 'watson' || isLoading;

  const showNeuro = configTemp[accessType]?.neuro.includes('show') || showExtraInfo;

  return (
    <div data-cy="emotions-bar"
         className={cn(s.wrapper, x.className, isLoading && s.wrapperDisabled)}
         data-disabled={isLoading}>
      <div className={s.list} data-cy="emotions-list">
        <h4 className={s.titleEmotion}>Эмоция</h4>
        <h4 className={s.titleOperator}>Оператор</h4>
        <h4 className={s.titleAbonent}>Абонент</h4>
        { operatorEmosEntries.map(([emoName]) => (
          <div key={emoName + keyPart} className={s.emoName}>{ mapEmoToSmiley(emoName) }</div>
        )) }
        { operatorEmosEntries.map(([emoName, value]) => (
          <OkkInput key={emoName + keyPart} emoName={emoName} actor='operator' method={method}
                    value={value.okk_pred ?? 0}
                    onChange={() => updateComponent({})}
                    miniStore={miniStore} />
        )) }
        { abonentEmosEntries.map(([emoName, value]) => (
          <OkkInput key={emoName + keyPart} emoName={emoName} actor='abonent' method={method}
                    value={value.okk_pred ?? 0}
                    onChange={() => updateComponent({})}
                    miniStore={miniStore} />
        )) }
      </div>

      <div className={s.column2}>
        <label className={s.methodToggler}>
          <input type="radio" name="method" checked={method === 'dostoevsky'}
                 disabled={isLoading}
                 onChange={() => setMethod('dostoevsky')} />
          <span> Neuro_1</span>
        </label>
        <label className={s.methodToggler}>
          <input type="radio" name="method" checked={method === 'watson'}
                 disabled={true}
                 onChange={() => setMethod('watson')} />
          <span> Neuro_2</span>
        </label>
        <Button className={s.sendBtn}
                onClick={handleSendBtnClick}
                disabled={sendBtnIsDisabled}>Отправить</Button>
      </div>
      <div className={s.charts}>
        <h4 className={s.chartsTitle}>Разметка</h4>
        <EmotionsChart className={s.chart}
                       dataCy="operator-charts"
                       data={OkkLayoutCahrtData}
                       colors={chartColors[method]} />

        { !showNeuro && (
          <Button className={s.controlBtn} onClick={handleControlClick}>Контроль</Button>
        ) }

        { showNeuro && (
          <>
            <h4 className={s.chartsTitle}>Нейросеть</h4>
            <EmotionsChart dataCy="neuro-charts"
                           className={s.chart}
                           data={NeuroLayoutCahrtData}
                           colors={chartColors[method]} />
          </>
        ) }
      </div>
    </div>
  );
};

type OkkInputProps = {
  method: Methods
  emoName: string
  actor: Actor
  miniStore: React.MutableRefObject<MiniStore | null>
  onChange: () => void
  value: number
};

function OkkInput(x: OkkInputProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = Number(e.target.value);

    const currentEstimate = x.miniStore.current?.[x.method]?.[x.actor]?.[x.emoName];
    if (!currentEstimate) return;
    currentEstimate.okk_pred = currentValue;
    x.onChange();
  };

  return (
    <>
      <input type="range" name={x.emoName} min={0} max={1} step={0.01}
             onChange={handleChange}
             value={x.value}
             className={cn(s.slider, s[`${x.actor}Input`])} />
      <span className={cn(s.percent, s[`${x.actor}Value`])}>{ x.value.toFixed(2) }</span>
    </>
  );
};

type EmoEntries = [string, {okk_pred?: number; pred: number}][];
type Obj = {
  [key: string]: string | undefined
};

function mapEmosToChart(
  operatorEmosEntries: EmoEntries,
  abonentEmosEntries: EmoEntries,
  marker: 'OKK' | 'Neuro'): [Obj, Obj]
{
  const abonentObj = { actor: 'абонент', } as Obj;
  abonentEmosEntries.forEach(([key, value]) => {
    const valueToShow = marker === 'Neuro' ? value.pred : (value.okk_pred ?? 0);
    abonentObj[key.replace(/\w+_/g, '')] = valueToShow.toFixed(2);
  });

  const operatorObj = { actor: 'оператор' } as Obj;
  operatorEmosEntries.forEach(([key, value]) => {
    const valueToShow = marker === 'Neuro' ? value.pred : (value.okk_pred ?? 0);
    operatorObj[key.replace(/\w+_/g, '')] = valueToShow.toFixed(2);
  });

  return [abonentObj, operatorObj];
};

function filterDostoevskyEmo(emos: ValidAnalysis<FullAnalysis['tones']>) {
  const emosCopy = copySimpleObj(emos);
  delteOpts(emosCopy.abonent);
  delteOpts(emosCopy.operator);

  function delteOpts(obj: typeof emos['abonent']) {
    for (const key in obj) {
      if (key.includes('skip')) delete obj[key];
      if (key.includes('speech')) delete obj[key];
    }
  }
  return emosCopy;
}

function mapEmoToSmiley(emo: string) {
  const width = 30;
  const height = 30;
  if (emo.includes('neutral')) return <SmileyNeutral width={width} height={height} />;
  if (emo.includes('negative')) return <SmileyNegative width={width} height={height} />;
  if (emo.includes('positive')) return <SmileyPositive width={width} height={height} />;
  return emo;
}
