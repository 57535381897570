import React, { useRef, useState } from 'react';
import { cn, useOnClickOutside } from '@utils';
import { Arrow } from '@assets/icons';

import styles from './Dropdown.module.css';

type ListItem = {id: string; text: string};

export type Dropdown = {
  className?: string
  placeholder?: string
  list: ListItem[]
  onSelect: (item: ListItem) => void
  selectedItem?: ListItem
  dataCy?: string
};

export function Dropdown(x: Dropdown) {
  const { list, onSelect, selectedItem } = x;
  const ref = useRef<HTMLDivElement>(null);

  const [isExpanded, setIsExpanded] = useState(false);

  useOnClickOutside(ref, () => {
    setIsExpanded(false);
  });

  const handleSelect = (item: ListItem) => {
    setIsExpanded(false);
    onSelect?.(item);
  };

  return (
    <div className={cn(styles.dropdown, x.className)} ref={ref} data-cy={x.dataCy}>
      <div className={cn(styles.header, isExpanded && styles.header_expanded)}
           onClick={() => setIsExpanded(!isExpanded)}>
        <div className={styles.value}>
          { selectedItem ? selectedItem.text : x.placeholder }
        </div>
        <Arrow className={cn(styles.arrow, isExpanded && styles.arrowReversed)} />
      </div>

      { isExpanded && (
        <ul className={styles.list} data-cy="list">
          { list.length === 0
            ? <li className={styles.listItem} data-cy="empty">Нет данных...</li>
            : list.map(item => (
              <li key={item.id} className={styles.listItem}
                  onClick={() => handleSelect(item)}>{ item.text }</li>
            )) }
        </ul>
      ) }
    </div>
  );
}
