import React from 'react';

type Props = {
  className?: string
};

export function VoiceBar(x: Props) {
  return (
    <div className={x.className}>VoiceBar</div>
  );
};
