import React from 'react';
/*
  Основное назначение - предотвратить лишние перерендеры детей, которые находятся в провайдере,
  но не являются потребителями контекста. Дети будут обновляться только
  при изменении их внутреннего состояния или потребляемого контекста.
  Следует быть осторожным, т.к. обновления пропсов будут проигнорированы.

  Применяется при:
    1) оборачивании контекст-провайдером нескольких компонентов на самом верхнем уровне (выше App)
    2) когда дети не зависят от пропсов извне
    3) когда дети рендерятся безусловно (т.е нет условного рендеринга, вроде value && <Component />).
*/
type Props = {
  children: React.ReactNode
};

function PureWrapper(x: Props) {
  return <>{ x.children }</>;
};

const MemoizedWrapper = React.memo(PureWrapper, () => true);
export { MemoizedWrapper as PureWrapper };
