import React, { useEffect, useRef, useState } from 'react';
import { Arrow } from '@assets/icons';
import { useOnClickOutside } from '@utils';
import { Calendar } from './Calendar';

import s from './DatePicker.module.css';

type Props = {
  onSelect: (d: [Date, Date?]) => void
  selectedDates: [Date?, Date?]
  highlightDates?: Date[]
  disabled?: boolean
  range?: boolean
  launchers: React.MutableRefObject<null | HTMLElement>[]
  onShow?: (el: HTMLDivElement) => void
};

export function DatePicker(x: Props) {
  const { onSelect, selectedDates, highlightDates, disabled, range, onShow } = x;
  const ref = useRef<HTMLDivElement>(null);

  const [dates, setDates] = useState(selectedDates);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const onClick = () => {
      if (disabled || !ref.current) return;
      setIsOpen(!isOpen);
      if (!isOpen) onShow?.(ref.current);
    };
    x.launchers.forEach(el => el.current?.addEventListener('click', onClick));
    return () => x.launchers.forEach(el => el.current?.removeEventListener('click', onClick));
  });

  useOnClickOutside(ref, e => {
    if (x.launchers.some(r => r.current === e.target)) return;

    const [dateFrom, dateTo] = selectedDates;
    const selectionIsNotComplete = range && dateFrom && !dateTo;
    if (selectionIsNotComplete) {
      onSelect([dateFrom, dateFrom]);
    }
    setIsOpen(false);
  });

  const [dateStart = new Date()] = dates;
  const month = dateStart.toLocaleDateString('ru', { month: 'long' });
  const monthCapitalized = month.slice(0, 1).toUpperCase() + month.slice(1);

  const handlePrevMonthClick = () => {
    setDates([new Date(dateStart.getFullYear(), dateStart.getMonth() - 1)]);
  };

  const handleNextMonthClick = () => {
    setDates([new Date(dateStart.getFullYear(), dateStart.getMonth() + 1)]);
  };

  const dataCy = 'date-picker';

  return (
    <div className={s.datePicker} ref={ref}>
      { isOpen && (
        <div className={s.calendarWrapper}>
          <div className={s.controls}>
            <div className={s.month} data-cy={`${dataCy}-month`}>{ monthCapitalized }</div>
            <div className={s.year} data-cy={`${dataCy}-year`}>{ dateStart.getFullYear() }</div>
            <Arrow className={s.prevMonth} data-cy={`${dataCy}-prev`}
                   onClick={handlePrevMonthClick} />
            <Arrow className={s.nextMonth} data-cy={`${dataCy}-next`}
                   onClick={handleNextMonthClick} />
          </div>

          <Calendar date={dateStart}
                    selectedDates={selectedDates}
                    dataCy={dataCy}
                    range={x.range}
                    onDatesSelect={onSelect} highlightDates={highlightDates} />
        </div>
      ) }
    </div>
  );
}
