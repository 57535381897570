import React from 'react';
import { cn } from '@utils/classNames';
import s from './Button.module.css';

type Props = {
  children: React.ReactNode
  className?: string
  onClick?: () => void
  type?: 'submit' | 'button' | 'reset'
  disabled?: boolean
  pressed?: boolean
  dataCy?: string
  theme?: 'primary' | 'danger'
};

export function Button(x: Props) {
  const { theme = 'primary' } = x;
  return (
    <button type={x.type ?? 'submit'} disabled={x.disabled}
            data-cy={x.dataCy}
            className={cn(
              s.button,
              theme === 'primary' && s.themePrimary,
              theme === 'danger' && s.themeDanger,
              x.pressed && s.themePrimaryPressed,
              x.className)}
            onClick={x.onClick}>
      { x.children }
    </button>
  );
};
