import * as api from '@api/masks';
import { State } from '../reducer';
import { getMasks } from './getMasks';

type Args = {
  state: State
  projectId: string
  mask: number[]
};

export async function saveMarkup(x: Args) {
  const { mask, projectId, state } = x;
  const { tagNames } = state;

  const defaultDispatch = () => state.dispatch({ type: 'updateStateAsync', stateSlice: { isLoading: false } });
  if (!tagNames) return defaultDispatch();

  const result = await api.updateMask({ mask, projectId, tagNames });
  if (!result) return defaultDispatch();
  return getMasks({ state });
}
