import { csv } from '@mocks/tagsInProjects';
import { parseMasksCSV, delay } from '@utils';
import { ITEM } from './constants';

type Args = {
  projectId: string
};

export async function removeMask(x: Args) {
  await delay(500);

  try {
    const resultStr = localStorage.getItem(ITEM);

    let result: ReturnType<typeof parseMasksCSV>;
    if (!resultStr) {
      result = parseMasksCSV(csv);
    } else {
      result = JSON.parse(resultStr);
    }

    delete result.projectMasks[x.projectId];
    localStorage.setItem(ITEM, JSON.stringify(result));
    return true;

  } catch(e) {
    console.log(e);
    return false;
  }
}
