import { ListItem } from '@components';
import * as subsetApi from '@api/baseSubset';
import { formatDate, isSameDate } from '@utils';

export const initialState = {
  project: undefined as undefined | ListItem,
  operator: undefined as undefined | ListItem,
  status: undefined as undefined | ListItem,
  datePickerDates: [] as [Date?, Date?],
  dateFrom: undefined as undefined | string,
  dateTo: undefined as undefined | string,
  dateEdge: 'from' as DateEdge,
  durationFrom: undefined as undefined | string,
  durationTo: undefined as undefined | string,
  dealId: undefined as undefined | string,
  file: undefined as undefined | File,
  isEditing: false,
  responseText: undefined as undefined | string,

  withEmo: false,
  withTags: false,
  withEmoTemp: false,

  dispatch: {} as Dispatch
};

export type Dispatch = React.Dispatch<Action>;
type FilterInfo = subsetApi.FilterInfo;
type State = typeof initialState & { savedState?: State};
type StateSlice = Partial<State> & { isLoading: boolean };
type DateEdge = 'from' | 'to';

type Action =
  | { type: 'initDispatch'; dispatch: Dispatch }
  | { type: 'selectOperator'; operator: ListItem | undefined }
  | { type: 'selectProject'; project: ListItem | undefined }
  | { type: 'selectStatus'; status: ListItem | undefined }
  | { type: 'selectDurationFrom'; time: string }
  | { type: 'selectDurationTo'; time: string }
  | { type: 'selectDealId'; dealId: string }
  | { type: 'selectDates'; dates: [Date?, Date?] }
  | { type: 'saveFilterRecog'; token: string }
  | { type: 'setWithEmo' }
  | { type: 'setWithEmoTemp' }
  | { type: 'setWithTags' }
  | { type: 'setDateEdge'; edge: DateEdge }
  | { type: 'updateStateAsync'; stateSlice: StateSlice }
  | { type: 'uploadFile'; file: File }
  | { type: 'reset' };

export function reducer(state: State, action: Action): State {
  const { type } = action;

  if (type === 'reset') {
    const { savedState, dispatch } = state;
    if (!savedState) return { ...initialState, dispatch };

    return { ...savedState, savedState, dispatch };

  } else if (type === 'selectDealId') {
    // выбор звонка и всего остального (кроме файла) - взаимоисключающие возможности
    const { dealId } = action;
    if (dealId === state.dealId) return state;
    const { savedState, file, dispatch } = state;
    return { ...initialState, savedState, file, dealId, isEditing: true, dispatch };

  } else if (type === 'selectOperator') {
    const { operator } = action;
    if (operator === state.operator) return state;
    return { ...state, operator, dealId: undefined, isEditing: true };

  } else if (type === 'selectProject') {
    const { project } = action;
    if (project === state.project) return state;
    return { ...state, project, dealId: undefined, isEditing: true };

  } else if (type === 'selectStatus') {
    const { status } = action;
    if (status === state.status) return state;
    return { ...state, status, dealId: undefined, isEditing: true };

  } else if (type === 'selectDates') {
    const datePickerDates = action.dates;
    let [dateFrom, dateTo] = action.dates;
    if (!dateFrom) return state;

    const rangeSelected = dateTo && !isSameDate(dateFrom, dateTo);
    if (rangeSelected) {
      [dateFrom, dateTo]
      = Number(dateFrom) < Number(dateTo) ? [dateFrom, dateTo] : [dateTo, dateFrom];

      return {
        ...state,
        dateEdge: 'from',
        datePickerDates,
        dateFrom: dateFrom ? formatDate(dateFrom, 'yyyy.mm.dd') : undefined,
        dateTo: dateTo ? formatDate(dateTo, 'yyyy.mm.dd') : undefined,
        isEditing: true,
        dealId: undefined,
      };
    }
    const { dateEdge } = state;
    return {
      ...state,
      datePickerDates,
      isEditing: true,
      dateFrom: dateEdge === 'from' ? formatDate(dateFrom, 'yyyy.mm.dd') : undefined,
      dateTo: dateEdge === 'to' ? formatDate(dateFrom, 'yyyy.mm.dd') : undefined,
    };

  } else if (type === 'selectDurationFrom') {
    const { time } = action;
    if (time === state.durationFrom) return state;
    return { ...state, durationFrom: time, dealId: undefined, isEditing: true };

  } else if (type === 'selectDurationTo') {
    const { time } = action;
    if (time === state.durationTo) return state;
    return { ...state, durationTo: time, dealId: undefined, isEditing: true };

  } else if (type === 'setWithEmo') {
    return { ...state, withEmo: !state.withEmo, dealId: undefined, isEditing: true };

  } else if (type === 'setWithEmoTemp') {
    return { ...state, withEmoTemp: !state.withEmoTemp, dealId: undefined, isEditing: true };

  } else if (type === 'setWithTags') {
    return { ...state, withTags: !state.withTags, dealId: undefined, isEditing: true };

  } else if (type === 'saveFilterRecog') {
    saveFilterRecog(state, action.token);
    return state;

  } else if (type === 'setDateEdge') {
    const { edge } = action;
    state.dateEdge = edge;
    return state;

  } else if (type === 'uploadFile') {
    const { file } = action;
    if (file === state.file) return state;
    return { ...state, file, isEditing: true };

  } else if (type === 'updateStateAsync') {
    return { ...state, ...action.stateSlice };

  } else if (type === 'initDispatch') {
    return { ...state, dispatch: action.dispatch };
  }

  const remnant: never = type;
  throw new Error(`unhandled action.type = ${remnant} in Filter Bar reducer`);
}

async function saveFilterRecog(state: State, token: string) {
  const [dateFrom, dateTo] = state.datePickerDates;
  const result = await subsetApi.saveFilterRecog({
    token, dealId: state.dealId,
    dateFrom: dateFrom ? formatDate(dateFrom, 'yyyy.mm.dd') : undefined,
    dateTo: dateTo ? formatDate(dateTo, 'yyyy.mm.dd') : undefined,
    durationFrom: state.durationFrom, durationTo: state.durationTo,
    operator: state.operator?.text, project: state.project?.id,
    result: state.status?.id,
  });

  const secondsReg = /(\sсек:<\/b> )(\d+)/;
  const callsReg = /(\sзвон:<\/b> )(\d+)/;

  const seconds = result.text.match(secondsReg)?.[2] ?? '';
  const calls = result.text.match(callsReg)?.[2] ?? '';

  const stateSlice: StateSlice = { responseText: `${calls} / ${seconds}сек`, isLoading: false };
  state.dispatch({ type: 'updateStateAsync', stateSlice });
}

export function init(state: State, filterInfo: FilterInfo | undefined): State {
  if (!filterInfo) return state;

  const filterInfoHandled = Object.fromEntries(
    Object.entries(filterInfo)
      .map(([key, value]) => ([key, value ? value : undefined]))
  );

  const {
    dr_f: durationFrom,
    dr_t: durationTo,
    cus: projectId,
    dt_f: dateFrom,
    dt_t: dateTo,
    id_c: dealId,
    oper: operatorId,
    res: status,
  } = filterInfoHandled;

  const stateSlice: StateSlice = {
    dateFrom, dateTo,
    dealId, durationFrom, durationTo,
    operator: operatorId ? { id: operatorId, text: operatorId } : undefined,
    project: projectId ? { id: projectId, text: projectId } : undefined,
    status: status ? { id: status, text: status } : undefined,
    isLoading: false,
  };

  const savedState = { ...state, ...stateSlice };
  return { ...savedState, savedState };
}
