import { isDeal, saveTags } from '@api/baseSubset';
import { handleTags } from '@components/Sidebar3/components/TagsBar';
import { Masks } from '@contexts/masks/reducer';
import { isObj } from '@type-guards';
import { State } from '../reducer';
import { getInfoByFilters } from './getInfoByFilters';

type Args = {
  state: State
  tagNames: string[]
  masks: Masks
  token: string
};

export async function resaveTags(x: Args) {
  const { state, masks, tagNames, token } = x;
  const stateCopy: State = { ...state, labeledTag: 'yes' };
  const deals = await getInfoByFilters({ state: stateCopy, token, withAnalysis: true });
  const resetLoader = () => state.dispatch({ type: 'updateStateAsync', stateSlice: { isLoading: false } });

  if (!isObj(deals)) return resetLoader();

  const callIds = Object.keys(deals);

  const start = performance.now();
  for (const id of callIds) {
    await resave({ deals, id, token, masks, tagNames });
  }
  const end = performance.now();

  const time = end - start;
  const callsNum = callIds.length;
  const avg = time / callsNum;

  console.log(`Звонков: ${callsNum}, время: ${time}; среднее: ${avg}`);

  return resetLoader();
}

type ResaveArgs = {
  deals: Record<string, unknown>
  id: string
  token: string
  masks: Masks
  tagNames: string[]
};

async function resave(x: ResaveArgs) {
  const { deals, id, token, masks, tagNames } = x;
  const callInfo = deals[id];
  if (!isDeal(callInfo)) return;

  const callToShow = callInfo?.calls.find(c => !Array.isArray(c.analisys));
  const tags = callToShow?.analisys?.tags;
  if (!tags) return;

  const projectId = callInfo.customer;

  const mask = masks[projectId];
  if (!mask) return;

  const tagsHandled = handleTags(mask, Object.values(tags), tagNames);
  const hasDelta = tagsHandled?.hasDelta;

  const callId = callInfo.calls.find(c => 'analisys' in c)?.call_id;
  if (!callId) return;

  const { idchain: idChain } = callInfo;

  await saveTags({ tags, callId, idChain, token, hasDelta: Boolean(hasDelta) });
}
