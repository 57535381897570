import React, { useContext, useEffect, useReducer } from 'react';
import { cn } from '@utils';
import { FiltersCtx, MasksCtx } from '@contexts';
import { Button, DropdownFilter } from '@components';
import { reducer, MarkSubset, TagSubset, initialState } from './reducer';

import s from './MasksBar.module.css';

type Props = {
  className?: string
};

export const pairsForSubset: [MarkSubset, string][] = [
  ['marked', 'Размеченные'],
  ['unmarked', 'Неразмеченные'],
  ['all', 'Все'],
];

const filtersByTag: [TagSubset, string][] = [
  ['with-tag', 'Проекты с тегами'],
  ['no-tag', 'Проекты без тегов'],
  ['no-matter', 'Не учитывать'],
];

export function MasksBar(x: Props) {
  const filtersCtx = useContext(FiltersCtx);
  const masksCtx = useContext(MasksCtx);
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    project, showAllTags, subsetByMark, tag, masks, tagNames,
    tagsFilterList, subsetByTag, tagsWereChanged, projectList,
    mask
  } = state;

  const { initialLists } = filtersCtx;
  const { isLoading } = masksCtx;

  useEffect(() => {
    if (!masks || !tagNames) masksCtx.asyncDispatch(masksCtx, { type: 'getMasks' });
  }, []);

  useEffect(() => {
    // FIXME попробовать синхронизировать без лишнего перерендера.
    if (isLoading) return;
    dispatch({ type: 'handleCtxChange', masksCtx, filtersCtx });
  }, [masksCtx]);

  if (!initialLists) return null;
  if (!masks || !tagNames) return null;

  const tagsList = tagNames.map(i => ({ id: i, text: i }));

  const projectHasMask = Boolean(project && project.id in masks);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    dispatch({
      type: 'changeMarkup',
      index,
      value: Number(e.target.checked),
      masksCtx: masksCtx
    });
  };

  const handleSaveMarkup = () => {
    if (!mask || !project) return;
    masksCtx.asyncDispatch(masksCtx, { type: 'saveMarkup', mask, projectId: project?.id });
  };

  const addTag = () => {
    const name = prompt('Введите имя нового тега');
    if (!name) return;
    if (tagNames.includes(name)) return alert('Тег уже существует!');
    masksCtx.asyncDispatch(masksCtx, { type: 'addTag', tagName: name });
  };

  const removeTag = () => {
    const name = prompt('Введите имя УДАЛЯЕМОГО тега');
    if (!name) return;
    if (!tagNames.includes(name)) return alert('Тег не существует!');
    masksCtx.asyncDispatch(masksCtx, { type: 'removeTag', tagName: name });
  };

  const addMask = () => {
    const projectId = prompt('Введите название проекта');
    if (!projectId) return;
    dispatch({ type: 'addMask', name: projectId, masksCtx });
  };

  const removeMask = () => {
    const id = prompt('Введите имя проекта');
    if (!id) return;
    masksCtx.asyncDispatch(masksCtx, { type: 'removeMask', projectId: id });
  };

  return (
    <div className={cn(x.className, isLoading && s.masksBarDisabled)}
         data-cy="masks-bar"
         data-disabled={isLoading}>
      <div className={s.projectControls}>
        <h4>Проект:</h4>
        <DropdownFilter className={s.dropdownProject}
                        headerPlaceholder="Проект"
                        dataCy='project'
                        shouldHighlight={Boolean(project)}
                        filterPlaceholder="Начните ввод..."
                        onSelect={item => dispatch({ type: 'setProject', project: item, masksCtx })}
                        selectedItem={project}
                        list={projectList ?? []} />

        <Button className={s.saveBtn}
                dataCy="save-btn"
                disabled={!tagsWereChanged}
                onClick={handleSaveMarkup}>
          Сохранить разметку
        </Button>
      </div>

      <div className={s.radiosWrapper} data-cy="mask-filters">
        { pairsForSubset.map(([eng, rus]) => (
          <label key={eng} className={s.radioLabel}>
            <input type="radio" name="mask-filter"
                   onChange={() => dispatch({ type: 'setSubsetByMark', subset: eng, masks })}
                   checked={subsetByMark === eng} />
            <span className={s.radioLabelText}>{ rus } </span>
          </label>
        )) }
      </div>

      { project && (
        <div className={s.tagsCaption}>
          <h4>Теги</h4>
          { (subsetByMark === 'marked' || subsetByMark === 'all') && (
            <label>
              <input className={s.tagsFilterInput} type="checkbox"
                     checked={showAllTags}
                     onChange={e => dispatch({ type: 'setShowAllTags' })} />
              <span data-cy="show-tags"
                    className={s.tagsFilterText}>Показать все теги</span>
            </label>
          ) }
        </div>
      ) }

      { project && (
        <ul className={s.tagsInfo} data-cy="tags-info">
          { tagNames.map((t, i) => (
            // если у проекта нет маски, просто выведем все теги,
            // если нужно показать все теги, просто покажем их
            // в ином случае, показываем, только если тег выделен
            (!projectHasMask || showAllTags || Boolean(masks[project.id]?.[i])) && (
              <li className={s.tagsInfoItem} key={i}>
                <label className={s.tagsInfoLabel}>
                  <span>{ t } </span>
                  <input type="checkbox" name="" className={s.tagsInfoInput}
                         key={project.id + i}
                         onChange={e => handleInputChange(e, i)}
                         defaultChecked={Boolean(masks[project.id]?.[i])} />
                </label>
              </li>
            )
          )) }
        </ul>
      ) }

      { subsetByMark === 'marked' && (
        <>
          <div className={s.filterByTagSelection}>
            <h4 className={s.filterByTagCaption}>Фильтр проектов по тегу:</h4>
            <DropdownFilter className={s.dropdownTag}
                            headerPlaceholder="Выберите тег"
                            dataCy='tag'
                            filterPlaceholder="Начните ввод..."
                            onSelect={item => dispatch({ type: 'tagFilterSelect', tag: item })}
                            shouldHighlight={Boolean(tag)}
                            selectedItem={tag}
                            list={tagsList} />
            <Button dataCy="filter-tag-add"
                    onClick={() => dispatch({ type: 'addTagToList', masks })}>+</Button>
            <Button dataCy="filter-tag-remove"
                    onClick={() => dispatch({ type: 'removeTagFromList', masks })}>-</Button>
          </div>

          <div className={s.selectedTags}>
            <h4>Выбранные теги:</h4>
            <div className={s.selectedTagsList}>
              { tagsFilterList.map(i => <span key={i}>{ i }, </span>) }
            </div>
          </div>

          <div className={s.filterByTagRadios} data-cy="tag-filters">
            { filtersByTag.map(([eng, rus]) => (
              <label key={eng} className={s.radioLabel}>
                <input type="radio"
                       data-cy={eng}
                       className={s.filterByTagInput}
                       checked={subsetByTag === eng}
                       disabled={tagsFilterList.length === 0}
                       onChange={() => dispatch({ type: 'setSubsetByTag', masks, subset: eng })}
                       name="filterByTag" />
                <span className={s.radioLabelText}>{ rus }</span>
              </label>
            )) }
          </div>
        </>
      ) }

      <div className={s.controls} data-cy="mask-controls">
        <Button onClick={addMask}
                dataCy="add-mask">Добавить маску</Button>
        <Button onClick={removeMask}
                dataCy="remove-mask"
                theme="danger">Удалить маску</Button>
        { project && (
          <>
            <Button onClick={addTag} dataCy="add-tag">Добавить тег</Button>

            <Button onClick={removeTag} theme="danger" dataCy="remove-tag">
              Удалить тег
            </Button>
          </>
        ) }
      </div>
    </div>
  );
};
