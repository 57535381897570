import { State } from '../reducer';

type Args = {
  state: State
};
export function handleRolesListChange(x: Args) {
  const { state } = x;
  const { configSaved, configTemp } = state;
  const rolesInitial = Object.keys(configSaved).sort();
  const rolesTemp = Object.keys(configTemp).sort();

  const rolesListsAreEqual = rolesInitial.toString() === rolesTemp.toString();
  const changesStack = [...state.changesStack];
  if (rolesListsAreEqual) changesStack.pop();
  else changesStack.push(1);

  return { ...state, changesStack };
}
