import * as api from '@api/baseSubset';
import * as h from '@contexts/callInfos/helpers';

export const initialState = {
  isLoading: false,
  dealDatas: {} as api.DealsObj, // загруженные звонки, по сути кеш
  callHistoryState: 'unknown' as 'unknown' | 'empty' | 'not-empty',
  historyCalls: [] as api.Deal[],
  dispatch: {} as Dispatch,
  asyncDispatch,
};

export type State = typeof initialState;
export type StateSlice = Partial<State> & { isLoading: boolean };
export type Dispatch = React.Dispatch<Action>;

type Action =
  | { type: 'updateStateAsync'; stateSlice: StateSlice }
  | { type: 'initDispatch'; dispatch: Dispatch }
;

export function CallInfoCtxReducer(state: State, action: Action): State {
  const { type } = action;
  if (state.isLoading && type !== 'updateStateAsync') return state;

  if (type === 'updateStateAsync') {
    const { stateSlice } = action;
    return { ...state, ...stateSlice };

  } else if (type === 'initDispatch') {
    return { ...state, dispatch: action.dispatch };
  }

  const remnant: never = type;
  throw new Error(`unhandled action.type = ${remnant} in CallInfo reducer`);
}

type ActionAsync =
| { type: 'getDealInfo'; id: string; token: string }
| { type: 'getDealHistory'; dealId: string; token: string; callInfo: api.Deal }
| {
  type: 'saveTags'
  tags: api.TagsAnalysis
  dealId: string
  hasDelta: boolean
  token: string
} | {
  type: 'saveSubstatus'
  idChain: string
  subs: api.TagsAnalysis
  dealId: string
  token: string
  callId: string
} | {
  type: 'saveEmotions'
  idChain: string
  callId: string
  tones: NonNullable<api.FullAnalysis['tones']>
  dealId: string
  token: string
} | {
  type: 'saveEmotionsTemp'
  idChain: string
  callId: string
  dealId: string
  token: string
  emotions: api.EmotionsTempType
} | {
  type: 'saveComment'
  commentText: string
  idChain: string
  token: string
  dealId: string
}
;

export async function asyncDispatch<T extends ActionAsync, U extends T['type']>(state: State, action: T & ActionAsync): Promise<AsyncDispatchResults[U]> {
  const { type } = action;
  const setIsLoading = (slice?: Partial<State>) => state.dispatch({ type: 'updateStateAsync', stateSlice: { isLoading: true, ...slice } });
  const { dispatch } = state;

  if (type === 'getDealInfo') {
    const { id, token } = action;
    setIsLoading();
    return h.getDealInfo({ id, token, state });

  } else if (type === 'saveTags') {
    const { token, tags, hasDelta, dealId } = action;
    setIsLoading();
    return h.saveTags({ hasDelta, state, tags, token, dealId });

  } else if (type === 'saveSubstatus') {
    const { callId, idChain, subs, dealId, token } = action;
    setIsLoading();
    return h.saveSubstatus({ subs, idChain, token, callId, dealId, state });

  } else if (type === 'saveEmotions') {
    const { callId, idChain, tones, dealId, token } = action;
    setIsLoading();
    return h.saveEmotions({ tones, idChain, token, callId, dealId, state });

  } else if (type === 'saveEmotionsTemp') {
    const { callId, idChain, emotions, dealId, token } = action;
    setIsLoading();
    return h.saveEmotionsTemp({ emotions, idChain, token, callId, dealId, state });

  } else if (type === 'saveComment') {
    const { commentText: infoComment, idChain, dealId, token } = action;
    setIsLoading();
    return h.saveComment({ comment: { idChain, infoComment }, dealId, state, token }); // FIXME перенести в другое место

  } else if (type === 'getDealHistory') {
    const { dealDatas } = state;
    const { callInfo, dealId, token } = action;
    if (!callInfo) return;

    const dealInfo = dealDatas[dealId];
    if (!dealInfo) return;

    const { idtable, customer } = dealInfo;

    setIsLoading();
    return h.getDealHistory({
      token, project: customer, idTable: idtable,
      dealId: String(dealId), dealDatas, dispatch, callInfo,
    });
  }
};

type AsyncDispatchResults = {
  getDealInfo: ReturnType<typeof h.getDealInfo>
  getDealHistory: ReturnType<typeof h.getDealHistory>
  saveTags: ReturnType<typeof h.saveTags>
  saveSubstatus: ReturnType<typeof h.saveSubstatus>
  saveEmotions: ReturnType<typeof h.saveEmotions>
  saveEmotionsTemp: ReturnType<typeof h.saveEmotionsTemp>
  saveComment: ReturnType<typeof h.saveComment>
};
