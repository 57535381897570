import React, { useContext, useRef, useState } from 'react';
import { Arrow } from '@assets/icons';
import { cn, useOnClickOutside } from '@utils';
import { FiltersCtx, AuthCtx, LabelValue } from '@contexts';

import s from './LabelFilter.module.css';

type Props = {
  className?: string
  disabled?: boolean
  title: string
};

const buttonValues: {value: LabelValue; text: string}[] = [
  { value: 'yes', text: 'Есть' },
  { value: 'no', text: 'Нет' },
  { value: 'no-matter', text: 'Все' },
  { value: 'delta', text: 'Дельта' }
];

export function LabelFilter(x: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const filtersCtx = useContext(FiltersCtx);
  const { labeledEmo, labeledTag, labeledEmoTemp, labeledLead } = filtersCtx;

  const { token } = useContext(AuthCtx);

  const [tagValue, setTagValue] = useState<LabelValue>(labeledTag);
  const [emoValue, setEmoValue] = useState<LabelValue>(labeledEmo);
  const [emoTempValue, setEmoTempValue] = useState<LabelValue>(labeledEmoTemp);
  const [leadValue, setLeadsValue] = useState<LabelValue>(labeledLead);

  const rowProps: RowProps[] = [
    { name: 'tags', label: 'Теги', value: tagValue, onChange: setTagValue, disabled: false },
    { name: 'emo', label: 'Эмоции', value: emoValue, onChange: setEmoValue, disabled: false },
    { name: 'emoTemp', label: 'Эмоции*', value: emoTempValue, onChange: setEmoTempValue, disabled: false },
    { name: 'leads', label: 'Лиды', value: leadValue, onChange: setLeadsValue, disabled: false },
  ];

  const close = () => {
    if (!isOpen) return;

    filtersCtx.asyncDispatch(filtersCtx, {
      type: 'selectLabels', labels: {
        emo: emoValue,
        emoTemp: emoTempValue,
        tag: tagValue,
        lead: leadValue,
      }, token
    });
    setIsOpen(false);
  };

  useOnClickOutside(ref, close);

  const handleCaptionClick = () => {
    if (x.disabled) return;
    if (isOpen) close();
    else setIsOpen(true);
  };

  const highlight
  = labeledEmo !== 'no-matter'
  || labeledEmoTemp !== 'no-matter'
  || labeledTag !== 'no-matter'
  || labeledLead !== 'no-matter';

  return (
    <div ref={ref}
         className={
           cn(s.labelFilter,
             highlight && s.selected,
             x.disabled && s.disabled,
             x.className)
         }>

      <div className={s.caption} onClick={handleCaptionClick}
           data-cy="dialogs-filter"
           data-disabled={x.disabled}>
        <span className={s.title}>{ x.title }</span>
        <Arrow className={cn(s.arrow, isOpen && s.arrowReversed)} />
      </div>

      { isOpen && (
        <div className={s.layoutTable}>
          <span></span>
          { buttonValues.map(i => (
            <h5 key={i.text}>{ i.text }</h5>
          )) }
          { rowProps.map(({ name, label, value, onChange, disabled }) => (
            <Row key={name}
                 value={value as LabelValue}
                 disabled={disabled}
                 name={name} label={label} onChange={onChange} />
          )) }
        </div>
      ) }
    </div>
  );
};

type RowProps = {
  name: string
  label: string
  value: LabelValue
  disabled: boolean
  onChange: (value: LabelValue) => void
};

function Row(x: RowProps) {
  const [selected, setSelected] = useState<LabelValue>(x.value);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(e.target.value as LabelValue);
    x.onChange(e.target.value as LabelValue);
  };

  return (
    <>
      <span>{ x.label }</span>
      { [0, 1, 2, 3].map(i => (
        <input key={i} type="radio" name={x.name}
               value={buttonValues[i].value}
               onChange={handleChange}
               disabled={x.disabled}
               checked={buttonValues[i].value === selected} />
      )) }
    </>
  );
}
