import React, { useContext } from 'react';
import { AuthCtx } from '@contexts';
import { cn } from '@utils';
import { Button } from '@components';

import s from './SelectionDB.module.css';
import { Base } from 'App';

type Props = {
  className?: string
  onSelect: (base: Base) => void
};

export function SelectionDB(x: Props) {
  const authCtx = useContext(AuthCtx);

  return (
    <div className={cn(s.selection, x.className)}>
      <Button className={s.logoutBtn}
              dataCy='exit'
              onClick={() => authCtx.dispatch({ type: 'logOut' })}
              type="button">
        Выйти
      </Button>

      <div className={s.baseButtons}>
        <Button className={s.base1}
                dataCy='base1'
                onClick={() => x.onSelect('Subset')}>
          Вход
        </Button>

        <Button disabled className={s.base2} dataCy='base2'>
          Аналитика
        </Button>

        <Button className={s.base3}
                dataCy="base3"
                disabled>
          Разработка
        </Button>
      </div>
    </div>
  );
};
