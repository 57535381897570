import React, { useContext, useState } from 'react';
import { cn } from '@utils';
import {
  FiltersCtx, AuthCtx,
  MasksCtxProvider, ConfigCtx
} from '@contexts';
import { bookmarkList, Bookmarks } from '@contexts/config/lists';

import { Dropdown, ListItem } from '@components';
import {
  FilterBar, EmotionsBar, TagsBar, ScriptBar,
  EmotionsTemp, MonitorBar, WordsBar, LeadsBar,
  VoiceBar, MasksBar, AnalysisBar, DictionariesBar,
  LexicBar, ConfigBar
} from './components';
import s from './Sidebar3.module.css';

const mainDropdownItems: typeof bookmarkList = [
  { text: 'Теги', id: 'tags' },
  { text: 'Эмоции', id: 'emotions' },
  { text: 'Лиды', id: 'leads' },
  { text: 'Скрипт', id: 'script' },
  { text: 'Эмоции*', id: 'emotions_star' },
  { text: 'Слова', id: 'words' },
  { text: 'Голос', id: 'voice' },
  { text: 'Лексика', id: 'lexic' },
];

const adminDropdownItems: typeof bookmarkList = [
  { text: 'Монитор', id: 'monitor' },
  { text: 'Словари', id: 'dictionaries' },
  { text: 'Конфигурация', id: 'config' },
];

const optionsDropdownItems: typeof bookmarkList = [
  { text: 'Маски', id: 'masks' },
  { text: 'Фильтр', id: 'filter' },
  { text: 'Анализ', id: 'analysis' },
];

const emptyItem = { id: '...', text: '...' };

type Props = {
  className?: string
};

export function Sidebar3(x: Props) {
  const filtersCtx = useContext(FiltersCtx);
  const authCtx = useContext(AuthCtx);
  const { configTemp } = useContext(ConfigCtx);
  const [bookmark, setBookmark] = useState<Bookmarks>('tags');

  const { initialLists, filterId, filterInfos, wordsToFind, deal } = filtersCtx;
  const { accessType, token } = authCtx;

  const wordsToHighlight = wordsToFind ? [wordsToFind] : undefined;

  if (!initialLists) return null;

  const bookmarksWithMaxHeight: Bookmarks[] = [
    'emotions_star', 'script', 'monitor',
    'words', 'emotions', 'analysis', 'lexic'
  ];

  const checkBookmarkVisibility = (b: Bookmarks): boolean => {
    if (!accessType) return false;
    const blist = configTemp[accessType]?.bookmarks;
    return blist ? blist.includes(b) : false;
  };

  const mainDropdownItemsToShow
    = mainDropdownItems.filter(i => checkBookmarkVisibility(i.id));

  const adminDropdownItemsToShow
    = adminDropdownItems.filter(i => checkBookmarkVisibility(i.id));

  const optionsDropdownItemsToShow
    = optionsDropdownItems.filter(i => checkBookmarkVisibility(i.id));

  const handleFilterSelect = (item: ListItem) => setBookmark(item.id as Bookmarks);

  const setSelectedItem = (list: typeof bookmarkList) =>
    list.find(item => item.id === bookmark) ?? emptyItem;

  const mainFilterItem = setSelectedItem(mainDropdownItemsToShow);
  const adminFilterItem = setSelectedItem(adminDropdownItemsToShow);
  const optionsFilterItem = setSelectedItem(optionsDropdownItemsToShow);

  const dealId = deal?.id || '';
  return (
    <div className={cn(s.sidebar3, x.className)} data-cy="sidebar3">
      <div className={s.dropdownsWrapper}>
        { mainDropdownItemsToShow.length > 0 && (
          <Dropdown className={s.dropdown}
                    dataCy="dropdown-main"
                    list={mainDropdownItemsToShow}
                    placeholder='...'
                    selectedItem={mainFilterItem}
                    onSelect={handleFilterSelect} />
        ) }

        { optionsDropdownItemsToShow.length > 0 && (
          <Dropdown className={s.dropdown}
                    dataCy="dropdown-options"
                    list={optionsDropdownItemsToShow}
                    placeholder='...'
                    selectedItem={optionsFilterItem}
                    onSelect={handleFilterSelect} />
        ) }

        { adminDropdownItemsToShow.length > 0 && (
          <Dropdown className={s.dropdown}
                    dataCy="dropdown-admin"
                    list={adminDropdownItemsToShow}
                    placeholder="..."
                    selectedItem={adminFilterItem}
                    onSelect={handleFilterSelect} />
        ) }
      </div>

      <div data-cy="bookmark-content"
           className={cn(s.content,
             bookmarksWithMaxHeight.includes(bookmark) && s.contentWithMaxHeight
           )}>
        { bookmark === 'script' && <ScriptBar dealId={deal?.id ?? ''} className={s.scriptBar} /> }
        { bookmark === 'filter' && (
          <FilterBar token={token}
                     className={s.bookmarkBar}
                     key={filterId?.id ?? 'нет'}
                     filterId={filterId?.id ?? 'нет'}
                     initialLists={initialLists}
                     accessType={accessType}
                     filterInfos={filterInfos} />
        ) }

        { bookmark === 'emotions' && <EmotionsBar className={s.bookmarkBar} /> }
        { bookmark === 'emotions_star' && (
          <EmotionsTemp className={s.bookmarkBar}
                        dealId={dealId}
                        wordsToHighlight={wordsToHighlight} />
        ) }
        { bookmark === 'monitor' && <MonitorBar token={token} className={cn(s.bookmarkBar, s.monitorBar)} /> }
        { bookmark === 'leads' && <LeadsBar className={s.bookmarkBar} /> }
        { bookmark === 'voice' && <VoiceBar className={s.bookmarkBar} /> }
        { bookmark === 'analysis' && <AnalysisBar className={s.bookmarkBar} /> }
        { bookmark === 'config' && <ConfigBar className={s.bookmarkBar} /> }

        <MasksCtxProvider>
          { bookmark === 'masks' && <MasksBar className={s.bookmarkBar} /> }
          { bookmark === 'tags' && <TagsBar className={s.bookmarkBar} /> }
        </MasksCtxProvider>

        { bookmark === 'dictionaries' && <DictionariesBar className={s.bookmarkBar} /> }
        { bookmark === 'words' && <WordsBar className={s.bookmarkBar} /> }
        { bookmark === 'lexic' && <LexicBar className={s.bookmarkBar} dealId={dealId} /> }
      </div>
    </div>
  );
};
