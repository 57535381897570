import * as api from '@api/baseSubset';
import { isObj } from '@type-guards';
import { StateSlice, State } from '../reducer';
import { getInfoByFilters } from './getInfoByFilters';

type Args = {
  state: State
  token: string
};
export async function validateCalls(x: Args) {
  const { state, token } = x;
  const { dispatch } = state;
  const resetLoader = () => dispatch({ type: 'updateStateAsync', stateSlice: { isLoading: false } });

  try {
    const dealInfos = await getInfoByFilters({ state, token, withAnalysis: true });

    if (!isObj(dealInfos)) {
      return resetLoader();
    }

    const allDealInfosValues = Object.values(dealInfos);
    const validDealInfosValues = allDealInfosValues.filter(api.isDeal);

    let invalidDeals: unknown[] = [];
    if (allDealInfosValues.length !== validDealInfosValues.length) {
      invalidDeals = allDealInfosValues.filter(d => !api.isDeal(d));
    }

    const stateSlice: StateSlice = {
      invalidDeals,
      isLoading: false,
    };
    return dispatch({ type: 'updateStateAsync', stateSlice });

  } catch (e) {
    console.log(e);
    return resetLoader();
  }
};
