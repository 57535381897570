import * as api from '@api/dictionaries';
import { StateSlice, State } from '../reducer';

type Args = {
  state: State
  id: number
  token: string
};

export async function loadDictInfo(x: Args) {
  const { id, token } = x;
  const { dictInfos, dispatch } = x.state;
  const info = await api.getDictionary({ id, token });
  const stateSlice: StateSlice = { isLoading: false };

  if (api.isGetDictionaryResult(info)) {
    stateSlice.dictInfos = { ...dictInfos, [id]: info.dict };
  }

  dispatch({ type: 'updateStateAsync', stateSlice });
  return info;
};
