import React, { useContext } from 'react';
import { cn } from '@utils';
import { ConfigCtx } from '@contexts/config';
import { Controls, RoleConfig } from './components';

import s from './ConfigBar.module.css';

type Props = {
  className?: string
};

export function ConfigBar(x: Props) {
  const configCtx = useContext(ConfigCtx);
  const { isLoading, configTemp } = configCtx;
  const roles = Object.keys(configTemp);

  return (
    <div data-cy="config-bar"
         className={cn(s.wrapper, x.className, isLoading && s.wrapperDisabled)}
         data-disabled={isLoading}>
      <Controls />

      <div className={s.lists}>
        { roles.map(i => <RoleConfig key={i} role={i} />) }
      </div>
    </div>
  );
};
