import { ListItem } from '@components';
import { Dictionary } from '@utils';
import * as api from '@api/baseSubset';
import * as h from './helpers';
import { asyncDispatch } from './asyncDispatch';

export const initialState = {
  isLoading: false,
  initialLists: undefined as undefined | InitialLists,

  project: undefined as undefined | ListItem,
  operator: undefined as undefined | ListItem,
  deal: undefined as undefined | ListItem,
  selectedDates: undefined as undefined | [Date, Date?],
  status: undefined as undefined | ListItem,
  substatus: undefined as undefined | ListItem,
  filterId: undefined as undefined | ListItem,
  dictionary: undefined as undefined | ListItem,
  tableId: '',
  historyCall: undefined as undefined | ListItem,
  labeledTag: 'no-matter' as LabelValue,
  labeledEmo: 'no-matter' as LabelValue,
  labeledEmoTemp: 'no-matter' as LabelValue,
  labeledLead: 'no-matter' as LabelValue,

  projects: [] as ListItem[],
  operators: [] as ListItem[],
  dealList: [] as ListItem[],
  availableDates: [] as Date[],
  statuses: [] as ListItem[],
  substatuses: [] as ListItem[],
  filterIds: [] as ListItem[],
  wordsToFind: undefined as undefined | string,

  savedDeals: [] as string[], // FIXME вроде принадлежит Sidebar2
  invalidDeals: undefined as unknown[] | undefined,
  filterInfos: undefined as undefined | api.InitialSubsetLists['filters'],

  dispatch: {} as Dispatch,
  asyncDispatch,
};

export type InitialState = typeof initialState;
export type State = typeof initialState;
export type StateSlice = Partial<State> & { isLoading: boolean };
export type Dispatch = React.Dispatch<Action>;

export type Action =
  | { type: 'initDispatch'; dispatch: Dispatch }
  | { type: 'updateStateAsync'; stateSlice: StateSlice }
  | { type: 'searchByCallId'; deal: api.Deal }
  | { type: 'selectDeal'; deal: api.Deal }
  | { type: 'saveDeal'; deal: SavedDeal }
  | { type: 'saveLoadedDeals' }
  | { type: 'addHistoryCallsToSet'; historyCallIds: string[] }
  | { type: 'selectCallInHistory'; call: ListItem }
  | { type: 'reloadSelection' }
;

export function FiltersReducer(state: State, action: Action): State {
  const { type } = action;
  if (state.isLoading && type !== 'updateStateAsync') return state;

  if (type === 'reloadSelection') {
    const newState: State = {
      ...initialState,
      initialLists: state.initialLists,
      filterInfos: state.filterInfos,
      dispatch: state.dispatch,
    };

    if (state.initialLists) {
      Object.assign(newState, { ...state.initialLists });
    }
    return newState;

  } else if (type === 'updateStateAsync') {
    return { ...state, ...action.stateSlice };

  } else if (type === 'searchByCallId' || type === 'selectDeal') {
    return h.handleDealSelect({ state, action });

  } else if (type === 'saveDeal') {
    const { savedDeals: savedCalls } = state;
    const { id } = action.deal;
    const callIsSaved = Boolean(savedCalls.includes(id));

    if (callIsSaved) {
      return { ...state, savedDeals: savedCalls.filter(c => c !== id) };
    }
    return { ...state, savedDeals: savedCalls.concat(id) };

  } else if (type === 'selectCallInHistory') {
    const { call } = action;
    return { ...state, historyCall: call };

  } else if (type === 'initDispatch') {
    return { ...state, dispatch: action.dispatch };

  } else if (type === 'addHistoryCallsToSet') {
    const { historyCallIds } = action;
    return { ...state, savedDeals: [...new Set([...state.savedDeals, ...historyCallIds])], };

  } else if (type === 'saveLoadedDeals') {
    return { ...state, savedDeals: [...state.dealList.map(listItem => String(listItem.id))], };
  }

  const remnant: never = type;
  throw new Error(`unhandled action.type = ${remnant} in AppReducer`);
}

export type InitialLists = {
  filterIds: ListItem[]
  projects: ListItem[]
  operators: ListItem[]
  statuses: ListItem[]
  availableDates: Date[]
  operatorsDictionary: Dictionary<string> | undefined
};

export type SavedDeal = {
  id: string
  operator: string
  project: string
  status: string
  idTable: number
  idChain: string
  script?: string
  infoComment?: string
  date: string
};

export type LabelValue = 'yes' | 'no' | 'no-matter'| 'delta';
