import { FiltersCtx, MasksCtx, State } from '../reducer';
import * as h from '.';

type Args = {
  state: State
  masksCtx: Readonly<MasksCtx>
  filtersCtx: Readonly<FiltersCtx>
};

export function handleCtxChange(x: Args): State {
  const { masksCtx, state, filtersCtx } = x;

  const { masks, tagNames } = masksCtx;
  if (!masks || !tagNames) return state;

  const { initialLists } = filtersCtx;
  if (!initialLists) return state;

  const newState = { ...state, tagNames, masks };

  const { initialProjectsList } = state;

  if (!initialProjectsList) {
    newState.initialProjectsList = initialLists.projects;
  }

  return h.applyFilters(newState);
}
