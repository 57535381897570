import { delay } from '@utils';
import { GetMasksResult } from './getMasks';
import { ITEM } from './constants';

type Args = {
  tagName: string
};

export async function removeTag(x: Args) {
  await delay(500);

  const { tagName } = x;

  const masks = localStorage.getItem(ITEM);
  if (!masks) return false;

  try {
    const result: GetMasksResult = JSON.parse(masks);
    const { tagNames, projectMasks } = result;
    const index = tagNames.indexOf(tagName);
    if (index === -1) return false;

    tagNames.splice(index, 1);
    Object.values(projectMasks).forEach(m => m?.splice(index, 1));

    localStorage.setItem(ITEM, JSON.stringify(result));
    return true;

  } catch(e) {
    console.log('Ошибка при удалении тега...');
    return false;
  }
}
