import { PureWrapper } from '@components';
import React, { createContext, useEffect, useReducer } from 'react';
import { DictionaryCtxReducer, initialState } from './reducer';

export const DictionaryCtx = createContext(initialState);
const { Provider } = DictionaryCtx;

type Props = {
  children: React.ReactNode
};

export function DictionaryCtxProvider(x: Props) {
  const [state, dispatch] = useReducer(DictionaryCtxReducer, initialState);
  useEffect(() => dispatch({ type: 'initDispatch', dispatch }), []);

  if (typeof state.dispatch !== 'function') return null;

  return (
    <Provider value={{ ...state, dispatch }}>
      <PureWrapper>
        { x.children }
      </PureWrapper>
    </Provider>
  );
};
