import { basicRequest } from './basicRequest';
import { RequestDicts } from './types';

type Args = {
  id: number
  token: string
};

export async function deleteDictionary(x: Args): Promise<unknown> {
  const { id, token } = x;
  const sendingObject: RequestDicts = {
    token,
    process: 'dict_work',
    data: { proc: 'del', id }
  };

  const formData = JSON.stringify(sendingObject);
  return basicRequest({ formData });
};
