export type SaveCommentArgs = {
  token: string
  infoComment: string
  idChain: string
};

export async function saveComment(x: SaveCommentArgs) {
  const url = 'https://dashboards.reffection.com/dashboards/api/save_comment.php';
  const formData = JSON.stringify({
    token: x.token,
    info_comment: x.infoComment,
    idchain: x.idChain,
  });

  try {
    await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: formData,
    });

  } catch (e) {
    console.log(e);
  }
};
