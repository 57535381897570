import * as api from '@api/masks';
import { State, StateSlice } from '../reducer';

type Args = {
  state: State
};

export async function getMasks(x: Args) {
  const result = await api.getMasks();

  if (!api.isGetMasksResult(result)) {
    const stateSlice: StateSlice = { isLoading: false };
    return x.state.dispatch({ type: 'updateStateAsync', stateSlice });
  }

  const { projectMasks, tagNames } = result;
  const stateSlice: StateSlice = {
    masks: projectMasks,
    tagNames,
    isLoading: false,
  };

  return x.state.dispatch({ type: 'updateStateAsync', stateSlice });
}
