type Format = 'dd.mm.yyyy' | 'yyyy.mm.dd' | 'yyyy-mm-dd' | 'dd.mm';

export function formatDate(date: Date, format: Format = 'dd.mm.yyyy'): string {
  const rusLocale = date.toLocaleDateString('ru');

  if (format === 'yyyy.mm.dd') {
    return rusLocale.replace(/(\d\d).(\d\d)\.(\d{4})/, '$3.$2.$1');
  } else if (format === 'yyyy-mm-dd') {
    return rusLocale.replace(/(\d\d).(\d\d)\.(\d{4})/, '$3-$2-$1');
  } else if (format === 'dd.mm.yyyy') {
    return rusLocale;
  } else if (format === 'dd.mm') {
    return rusLocale.slice(0, 5);
  }

  const remnantFormat: never = format;
  return remnantFormat;
}
