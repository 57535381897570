export function mapTextToHTMLForHighlight(str: string, words?: string[], color?: string, dataset?: string) {
  if (!words || !color) return str;

  let a = str;

  words.forEach(w => {
    const regExp = new RegExp(w, 'g');

    a = a.replace(regExp, m => {
      return `<span ${dataset ? dataset : ''} style="font-weight: bold; background-color:${color}">${m}</span>`;
    });
  });

  return a;
}
