import React, { useContext } from 'react';
import { ListItem } from '@components';
import { ConfigCtx } from '@contexts';
import { Bookmarks, Panels } from '@contexts/config/lists';
import { Aspect } from '@contexts/config/reducer';

import s from '../ConfigBar.module.css';

const nonSwitchableInputsForAdmin: (Bookmarks | Panels)[] = ['sidebar3', 'config'];

type Props = {
  role: string
  title: string
  list: ListItem[]
  aspect: Aspect
};

export function AspectList(x: Props) {
  const { configTemp, dispatch } = useContext(ConfigCtx);

  const { title, list, role, aspect } = x;
  const listInStore = configTemp[role]?.[aspect] ?? [] as string[];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const isChecked = e.target.checked;
    dispatch({ type: 'changeRoleConfig', role, aspect, isChecked, id });
  };

  const checkboxIsDisabled = (id: string) => {
    return role === 'admin' && (nonSwitchableInputsForAdmin as string[]).includes(id);
  };

  return (
    <div className={s.aspect}>
      <h4 className={s.bookmarksCaption}>{ title }</h4>

      <ul className={s.bookmarksList} data-cy={aspect}>
        { list.map(i => (
          <li key={i.id}
              className={s.listItem}>
            <label className={s.listItemLabel}>
              <span>{ i.text }</span>
              <input type="checkbox"
                     disabled={checkboxIsDisabled(i.id)}
                     checked={listInStore.includes(i.id)}
                     onChange={e => handleChange(e, i.id)} />
            </label>
          </li>
        )) }
      </ul>
    </div>
  );
};
