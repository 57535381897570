import React, { Fragment, useContext, useEffect, useState } from 'react';
import { cn, copySimpleObj, useControlBtn } from '@utils';
import { FiltersCtx, AuthCtx, CallInfoCtx, ConfigCtx, MasksCtx } from '@contexts';
import * as subsetApi from '@api/baseSubset';
import { isEmptyObj } from '@type-guards';
import { Button } from '@components';

import s from './TagsBar.module.css';

const THRESHOLD = 0.6;

type Props = {
  className?: string
};

export function TagsBar(x: Props) {
  const filtersCtx = useContext(FiltersCtx);
  const maskCtx = useContext(MasksCtx);
  const authCtx = useContext(AuthCtx);
  const { configTemp } = useContext(ConfigCtx);
  const callCtx = useContext(CallInfoCtx);

  const { project, dealList, deal } = filtersCtx;
  const { token, accessType } = authCtx;
  const { masks, tagNames } = maskCtx;
  const { dealDatas } = callCtx;

  const { showExtraInfo, handleControlClick } = useControlBtn();
  const callInfo = deal && dealDatas[deal.id];
  const callToShow = callInfo?.calls.find(c => !Array.isArray(c.analisys));
  const [tags, setTags] = useState<subsetApi.TagsAnalysis | undefined>(
    copySimpleObj(callToShow?.analisys?.tags)
  );

  const access3 = accessType === 'admin';
  const isLoading = filtersCtx.isLoading || callCtx.isLoading;

  const emptyWrapper = (message?: string) => (
    <div className={cn(s.tagsBar, s.tagsBarEmpty, x.className)} data-cy="tags-bar">
      { message && <h4>{ message }</h4> }
      { access3 && dealList.length > 0 && (
        <Button className={s.resaveBtn}
                disabled={isLoading}
                onClick={() => masks && tagNames
                  && filtersCtx.asyncDispatch(filtersCtx, { type: 'resaveTags', masks, tagNames, token })}>
          Пересохранить выборку
        </Button>
      ) }
    </div>
  );

  useEffect(() => {
    if (!masks || !tagNames) maskCtx.asyncDispatch(maskCtx, { type: 'getMasks' });
  }, []);

  useEffect(() => setTags(copySimpleObj(callToShow?.analisys?.tags)), [callToShow]);

  const projectId = project?.id;
  if (!callToShow || !projectId) return emptyWrapper();
  if (!tags || isEmptyObj(tags)) return emptyWrapper('Теги не обработаны.');
  if (!masks || !tagNames) return emptyWrapper('Маски не загружены...');

  const mask = masks[projectId];
  if (!mask) return emptyWrapper('Для данного проекта нет маски.');

  const dictionary = callToShow.analisys?.dicts?.tags;
  if (!dictionary) return emptyWrapper('Отсутствует словарь...');

  const commonInfo = handleTags(mask, Object.values(tags), tagNames);
  if (!commonInfo) return emptyWrapper('Либо присутствует тег, который не существует в масках тегов, либо сервер не присылает имена тегов.');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    if (!tags) return;

    const isChecked = e.target.checked;
    const tagOldValue = tags[key];
    if (!tagOldValue) return;

    tags[key] = { ...tagOldValue, okk_true: isChecked ? 1 : 0 };
    setTags({ ...tags });
  };

  const tagsArr = Object.entries(tags ?? {});
  const { ratingOperator, ratingNeuro, total, deltas, hasDelta } = commonInfo;

  const handleSave = () => {
    if (!tags) return;
    if (!callInfo) return;

    const callId = callInfo.calls.find(c => 'analisys' in c)?.call_id;
    if (!callId) return;

    const { idchain: idChain, deal_id: dealId } = callInfo;

    callCtx.asyncDispatch(callCtx, {
      type: 'saveTags', tags, idChain,
      dealId: String(dealId), callId, hasDelta, token
    });
  };

  const showNeuro = configTemp[accessType]?.neuro.includes('show') || showExtraInfo;

  const showControlBtn = accessType === 'control_department'; // FIXME

  return (
    <div className={cn(s.tagsBar, x.className)}
         data-cy="tags-bar"
         data-disabled={isLoading}>

      <div className={s.commonInfo}>
        <h4 className={s.column1}>Проект:
          <span className={s.commonInfoValue}>{ projectId }</span>
        </h4>

        <h4 className={s.column1}>Качество по оператору:
          <span className={s.commonInfoValue}>{ ratingOperator } / { total }</span>
        </h4>

        { showNeuro && (
          <>
            <h4 className={s.column2}>Расхождения:
              <span className={s.commonInfoValue}>{ deltas }</span>
            </h4>

            <h4 className={s.column2}>Качество по нейросети:
              <span className={s.commonInfoValue}>{ ratingNeuro } / { total }</span>
            </h4>
          </>
        ) }
      </div>

      <div className={s.list}>
        { tagsArr.map(([key, value]) => (
          <Fragment key={key}>
            <div className={s.autoInfo}>
              <span className={s.itemTitle}>{ dictionary[key] }</span>
              { showNeuro && (
                <>
                  <input className={s.autoCheckbox}
                         checked={value.true === 1 || value.pred >= THRESHOLD}
                         readOnly
                         disabled={isLoading}
                         data-cy="neuro-value"
                         type="checkbox" />
                  <span className={s.percent}>{ Math.round(value.pred * 100) }%</span>
                </>
              ) }
              <input type="checkbox" readOnly disabled
                     className={s.column4}
                     checked={mask[tagNames.indexOf(dictionary[key])] === 1} />
            </div>
            <input className={s.manualCheckbox} type="checkbox"
                   data-cy="okk-value"
                   checked={value.okk_true === 1}
                   disabled={isLoading}
                   onChange={e => handleChange(e, key)} />
          </Fragment>
        )) }
      </div>

      { tagsArr.length > 0 && (
        <div className={s.controls}>
          <Button className={s.saveBtn}
                  disabled={isLoading}
                  onClick={handleSave}>Сохранить</Button>
          { showControlBtn && (
            <Button className={s.saveBtn}
                    disabled={isLoading}
                    onClick={handleControlClick}>Контроль</Button>
          ) }
        </div>
      ) }
    </div>
  );
};

type TagValues = {
  okk_pred?: number | undefined
  okk_true?: number | undefined
  name?: string | undefined
  pred: number
  delta?: 1 | 0
}[];

export function handleTags(mask: number[], tags: TagValues, tagNames: string[]) {
  let ratingOperator = 0;
  let ratingNeuro = 0;
  let deltas = 0;
  let hasDelta = false;

  for (let i = 0; i < tagNames.length; i++) {
    const tagName = tagNames[i];
    const tag = tags.find(t => t.name === tagName);

    if (!tag) return;
    if ('delta' in tag) delete tag.delta; // удалить строчку, после пересохранения звонков с 1-го фераля.

    let operatorEstimate = 0;
    let neuroEstimate = 0;

    if (mask[i] === 1) {
      if (tag.okk_true === 1) {
        ratingOperator += 1;
        operatorEstimate = 1;
      }
      if (tag.pred >= THRESHOLD) {
        ratingNeuro += 1;
        neuroEstimate = 1;
      }
      deltas += Number(operatorEstimate !== neuroEstimate ? 1 : 0);
      if (operatorEstimate !== neuroEstimate) hasDelta = true;
    }
  }

  const totalItems = mask.reduce((acc, value) => acc + Number(value), 0);

  return { ratingOperator, ratingNeuro, total: totalItems, deltas, hasDelta };
}
