import React, { useContext, useEffect, useState } from 'react';
import { ValidDialogAnalysis } from '@api/baseSubset';
import { cn, mapTextToHTMLForHighlight } from '@utils';
import { FiltersCtx, AuthCtx, CallInfoCtx, DictionaryCtx } from '@contexts';

import { isEmptyObj } from '@type-guards';
import { DropdownFilter, ListItem } from '@components';
import s from './WordsBar.module.css';

type Props = {
  className?: string
};

const metrics = ['этап', 'тон', 'темп', 'переб', 'лексика'];

export function WordsBar(x: Props) {
  const filtersCtx = useContext(FiltersCtx);
  const dictCtx = useContext(DictionaryCtx);
  const authCtx = useContext(AuthCtx);
  const callCtx = useContext(CallInfoCtx);
  const { dealDatas } = useContext(CallInfoCtx);
  const [selectedDict, setSelectedDict] = useState<ListItem>();

  // FIXME подумать, может быть требуется создать отдельный хук для получения диалогов?
  const { initialLists, deal } = filtersCtx;

  const { dictionaries, dictInfos } = dictCtx;
  const { token } = authCtx;
  const dictInfo = selectedDict && dictInfos[Number(selectedDict.id)];
  const callInfo = deal && dealDatas[deal.id];

  useEffect(() => { // FIXME может лучше вынести в отдельный компонент?
    if (dictionaries) return;
    dictCtx.asyncDispatch(dictCtx, { type: 'getDictsList', token });
  }, []);

  // FIXME временно
  useEffect(() => {
    callCtx.asyncDispatch(callCtx, { type: 'getDealInfo', id: '18935797', token });
  }, []);

  if (!initialLists) return null;
  if (!callInfo) return null;

  const callToShow = callInfo.calls.find(c => c.analisys);
  const analisys = callToShow?.analisys?.dialogs;

  const callSelectedButNotAnalized = !analisys || Array.isArray(analisys) || isEmptyObj(analisys);
  if (callSelectedButNotAnalized) {
    return <h3 className={cn(s.wrapper, x.className)}>Слова не обработаны</h3>;
  }

  const mappedDialog = mapToChart(analisys);

  const handleSelectDict = (item: ListItem) => { // FIXME просто скопировал...
    const dictId = item.id;
    setSelectedDict(item);

    if (!(dictId in dictInfos)) {
      dictCtx.asyncDispatch(dictCtx, { type: 'loadDictInfo', id: Number(dictId), token });
    }
  };

  return (
    <div className={cn(s.wordsBar, x.className)} data-cy="words">
      <div className={s.dictionarySelection}>
        <h4>Словарь</h4>
        <DropdownFilter className={s.dropdownDictionary}
                        headerPlaceholder="Словарь"
                        dataCy='dictionaries'
                        filterPlaceholder="Начните ввод..."
                        onSelect={handleSelectDict}
                        shouldHighlight={Boolean(selectedDict)}
                        selectedItem={selectedDict}
                        list={dictionaries ?? []} />
      </div>
      <div className={s.togglers}>
        { ['Этапы', 'Теги', 'Обязательные', 'Возражения', 'Эмоции']
          .map((v, i) => (
            <label key={i}>
              <span className={s.toggler}>{ v }</span>
              <input type="radio" name="togglers" />
            </label>
          )) }
      </div>
      <div className={s.metrics}>
        { metrics.map(v => (
          <span key={v} className={s.metricName}>{ v }</span>
        )) }
      </div>

      <div className={s.rows}>
        { mappedDialog.map((d, i) => (
          <div className={cn(s.row, d.speaker[0] === 'a' && s.abonent)} key={i}>
            <div className={s.titles}>
              <h4 className={s.speaker}>
                { d.speaker[0].toUpperCase() }:
              </h4>
              { metrics.map(v => (
                <div key={v} className={s.metricInputsWrapper}>
                  <input className={s.metricInput} type="text" disabled value="20" />
                  <input className={s.metricInput} type="text" maxLength={2} />
                </div>
              )) }
            </div>
            <span className={s.text}
                  dangerouslySetInnerHTML={
                    { __html: mapTextToHTMLForHighlight(d.speech, dictInfo?.words, dictInfo?.color) }
                  } />
          </div>
        )) }
      </div>
    </div>
  );
};

function mapToChart(data: ValidDialogAnalysis) {
  const acc: {speech: string; speaker: string}[] = [];

  for (let i = 0; ; i++) {
    const speech = (data.speech)[i];
    const speaker = (data.speaker)[i];
    if (!speech || !speaker) break;
    acc.push({ speech, speaker });
  }

  return acc;
}
