import React, { useContext, useEffect, useState } from 'react';
import { useDialog } from '@contexts/callInfos/hooks';
import { cn, mapTextToHTMLForHighlight } from '@utils';
import { AuthCtx, DictionaryCtx, StateSaver } from '@contexts';
import { Button, DropdownFilter, ListItem } from '@components';

import s from './LexicBar.module.css';

type Props = {
  className?: string
  dealId: string
};

function LexicBar(x: Props) {
  const dictCtx = useContext(DictionaryCtx);
  const savedState = useContext(StateSaver).LexicBar;
  const { token } = useContext(AuthCtx);
  const [selectedDict, setSelectedDict] = useState<ListItem | undefined>(savedState?.selectedDict);
  const [dictList, setDictList] = useState<ListItem[]>(savedState?.dictList ?? []);
  const { dialog, error } = useDialog(x.dealId);

  const { dictionaries, dictInfos } = dictCtx;

  useEffect(() => {
    if (dictionaries) return;
    dictCtx.asyncDispatch(dictCtx, { type: 'getDictsList', token });
  }, []);

  const emptyWrapper = (message?: string) => (
    <div className={cn(s.wrapper, x.className)}>
      { message && <h4>{ message }</h4> }
    </div>
  );

  if (error === 'no callInfo') {
    return emptyWrapper('Диалог не выбран.');
  } else if (error === 'no analysis') {
    return emptyWrapper('Диалог не проанализирован.');
  } else if (error === 'invalid analysis') {
    return emptyWrapper('Невалидный анализ диалога.');
  }
  if (!dialog) return null;

  const handleDictSelection = (item: ListItem) => {
    setSelectedDict(item);
    savedState.selectedDict = item;
  };

  const handleAddClick = () => {
    if (!selectedDict) return;
    if (dictList.find(l => l.id === selectedDict.id)) return;

    const newList = [...dictList, selectedDict];
    setDictList(newList);
    if (!(selectedDict.id in dictInfos)) {
      dictCtx.asyncDispatch(dictCtx, { type: 'loadDictInfo', id: Number(selectedDict.id), token });
    }

    savedState.dictList = newList;
  };

  const handleRemoveClick = () => {
    if (!selectedDict) return;
    if (!dictList.find(l => l.text === selectedDict.text)) return;

    const newList = dictList.filter(d => d.id !== selectedDict.id);
    setDictList(newList);

    savedState.dictList = newList;
  };

  const highlightSpeech = (str: string) => {
    let result = str;

    dictList.forEach(d => {
      const dictId = d.id;
      if (!(dictId in dictInfos)) return;

      const info = dictInfos[Number(d.id)];
      if (!info) return;

      const { color, words } = info;
      result = mapTextToHTMLForHighlight(result, words, color, `data-cy="dict-${dictId}-word"`);
    });

    return { __html: result };
  };

  const operatorSpeech = dialog.filter(d => d.speaker === 'operator').map(d => d.speech);
  const abonentSpeech = dialog.filter(d => d.speaker === 'abonent').map(d => d.speech);

  return (
    <div className={cn(s.wrapper, x.className)}>
      <div className={s.dictionarySelection}>
        <h4 className={s.selectTitle}>Выбрать словарь:</h4>
        <DropdownFilter className={s.dropdownDictionary}
                        headerPlaceholder="Словарь"
                        shouldHighlight={Boolean(selectedDict)}
                        dataCy='dictionaries'
                        filterPlaceholder="Начните ввод..."
                        onSelect={handleDictSelection}
                        selectedItem={selectedDict}
                        list={dictionaries ?? []} />

        <Button className={s.addBtn} onClick={handleAddClick} dataCy="add">+</Button>
        <Button className={s.removeBtn} onClick={handleRemoveClick} dataCy="remove">-</Button>
      </div>

      <div className={s.selectedDicts}>
        <h4 className={s.selectedDictsTitle}>Словари и вхождения (О/А):</h4>

        <div className={s.dictList} data-cy="list">
          { dictList.length === 0 && <span>Словари не выбраны...</span> }
          { dictList.length > 0 && dictList.map(i => (
            <span key={i.id}
                  data-cy={`dict-id-${i.id}`}
                  style={{
                    backgroundColor: dictInfos[Number(i.id)]?.color ?? '',
                    fontWeight: 'bold'
                  }}>
              { i.text } (
              { countWords(operatorSpeech, dictInfos[Number(i.id)]?.words) }/
              { countWords(abonentSpeech, dictInfos[Number(i.id)]?.words) })
            </span>
          )) }
        </div>
      </div>

      <div className={s.rows} data-cy="dialog">
        { dialog.map((d, i) => (
          <div className={cn(s.row, d.speaker[0] === 'a' && s.abonent)} key={i}>
            <div className={s.titles}>
              <h4 className={s.speaker}>
                { d.speaker[0].toUpperCase() }:
              </h4>
            </div>
            <span className={s.text}
                  data-cy={`${d.speaker[0] === 'a' ? 'abonent' : 'operator'}-phrase`}
                  dangerouslySetInnerHTML={highlightSpeech(d.speech)} />
          </div>
        )) }
      </div>
    </div>
  );
};

function countWords(speech: string[], wordsToCount: string[] | undefined) {
  if (!wordsToCount) return 0;

  let result = 0;

  speech.forEach(str => {
    wordsToCount.forEach(w => {
      const regExp = new RegExp(w, 'g');
      const matchResult = str.match(regExp);
      if (!matchResult) return;
      result += matchResult.length;
    });
  });

  return result;
}

const MemoizedLexicBar = React.memo(LexicBar);
export { MemoizedLexicBar as LexicBar };
