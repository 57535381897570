import * as api from '@api/masks';
import { State } from '../reducer';
import { getMasks } from './getMasks';

type Args = {
  state: State
  tagName: string
};

export async function removeTag(x: Args) {
  const { tagName, state } = x;
  const defaultDispatch = () => state.dispatch({ type: 'updateStateAsync', stateSlice: { isLoading: false } });

  const result = await api.removeTag({ tagName });
  if (!result) {
    alert('Не удалось удалить тег...');
    defaultDispatch();
  }

  return getMasks({ state });
}
