import React, { useContext, useEffect, useRef } from 'react';

import { Filters, filtersDict } from '@contexts/config/lists';
import { CallFind, Loader } from '@assets/icons';
import { cn, encodeOperatorList, formatDate } from '@utils';
import { FiltersCtx, AuthCtx, ConfigCtx, DictionaryCtx, CallInfoCtx } from '@contexts';

import { DatePicker, DropdownFilter, Button } from '@components';
import { ChartsKind, CommonInfo, DealSelection } from '../../components';
import { InputSearch, CallAudioLoader, LabelFilter } from './components';

import s from './BasicSelection.module.css';

type Props = {
  className?: string
  onSelectChart: (chart: ChartsKind) => void
  onExit: () => void
};

export function BasicSelection(x: Props) {
  const authCtx = useContext(AuthCtx);
  const configCtx = useContext(ConfigCtx);
  const filtersCtx = useContext(FiltersCtx);
  const callCtx = useContext(CallInfoCtx);
  const dictCtx = useContext(DictionaryCtx);

  const { accessType, token } = authCtx;

  const datePickerRef = useRef(null);
  const {
    project, projects, operator, operators, status, statuses, filterIds, filterId,
    deal, dealList, selectedDates, availableDates,
    tableId, wordsToFind, initialLists,
    labeledEmoTemp, labeledEmo, labeledTag, labeledLead, substatuses, substatus,
    dictionary, dispatch
  } = filtersCtx;

  const { dictionaries } = dictCtx;

  // Для демо-режима кодируем имена операторов именами депутатов госдумы )
  const { operatorEncoded, operatorsListEncoded } = encodeOperatorList({ initialLists, operator, operators });

  useEffect(() => {
    if (dictionaries) return;
    dictCtx.asyncDispatch(dictCtx, { type: 'getDictsList', token });
  }, []);

  useEffect(() => {
    if (initialLists) return;
    filtersCtx.asyncDispatch(filtersCtx, { type: 'getInitialLists', accessType, token });
  }, []);

  const checkFilterVisibility = (filter: Filters) => {
    const { configTemp } = configCtx;
    const filters = configTemp[accessType]?.filters;
    if (!filters) return false;
    return filters.includes(filter);
  };

  const handleInputCallIdBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!value) return;

    const callInfo = await callCtx.asyncDispatch(callCtx, { type: 'getDealInfo', id: value, token });
    e.target.value = '';
    if (!callInfo) return;

    filtersCtx.dispatch({ type: 'searchByCallId', deal: callInfo });
  };

  const handleInputTableIdBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!value) return;
    filtersCtx.asyncDispatch(filtersCtx, { type: 'selectTableId', tableId: value, token });
  };

  const handleJumpToChartsClick = async () => {
    if (!deal) return;

    const dealId = deal.id;
    await callCtx.asyncDispatch(callCtx, { type: 'getDealInfo', id: dealId, token });
    x.onSelectChart('speech');
  };

  const isLoading = filtersCtx.isLoading || dictCtx.isLoading || callCtx.isLoading;

  const isAdmin = accessType === 'admin';
  const filterIdIsDisabled = Boolean(isLoading || operator || selectedDates || status || project || tableId);
  const toChartsIsDisabled = !deal || isLoading;
  const saveButtonIsDisabled = Boolean(!dealList.length || filterId) || isLoading;
  const subStatusIsDisabled = isLoading || status?.text !== 'Выслушал, отказался';
  const regularFilterDisabled = isLoading || (!selectedDates && !filterId);
  const dictSearchIsDisabled = isLoading;

  return (
    <div className={cn(s.basisSelection, x.className)}>
      <CommonInfo onResetClick={() => dispatch({ type: 'reloadSelection' })} />

      <div className={cn(s.selectors, x.className)} data-cy="basic-selection">
        { checkFilterVisibility('search_by_call_id') && (
          <div className={s.searchByCallIdWrapper}>
            <span>{ filtersDict.search_by_call_id }</span>
            <InputSearch className={s.searchByCallId}
                         disabled={isLoading}
                         dataCy='call-id'
                         type='number'
                         icon={CallFind}
                         onBlur={handleInputCallIdBlur} />
          </div>
        ) }

        { checkFilterVisibility('search_by_table_id') && (
          <div className={s.tableId}>
            <span className={s.tableIdTitle}>
              { filtersDict.search_by_table_id }
            </span>
            <InputSearch className={s.tableIdInput}
                         name="table-id"
                         type="number"
                         disabled={regularFilterDisabled}
                         keyValue={tableId}
                         dataCy='table-id'
                         defaultValue={tableId}
                         onBlur={handleInputTableIdBlur} />
          </div>
        ) }

        { checkFilterVisibility('filter_id') && (
          <DropdownFilter className={s.dropdownSetId}
                          dataCy='set-id'
                          headerPlaceholder={filtersDict.filter_id}
                          filterPlaceholder="Начните ввод..."
                          onSelect={item => filtersCtx.asyncDispatch(filtersCtx, { type: 'selectFilterId', filterId: item, token })}
                          selectedItem={filterId}
                          shouldHighlight={Boolean(filterId)}
                          disabled={filterIdIsDisabled}
                          list={filterIds} />
        ) }

        { checkFilterVisibility('project') && (
          <DropdownFilter className={s.dropdownProject}
                          dataCy='project'
                          headerPlaceholder={filtersDict.project}
                          filterPlaceholder="Начните ввод..."
                          onSelect={item => filtersCtx.asyncDispatch(filtersCtx, { type: 'selectProject', project: item, token })}
                          selectedItem={project}
                          shouldHighlight={Boolean(project)}
                          disabled={regularFilterDisabled}
                          list={projects} />
        ) }

        { checkFilterVisibility('operator') && (
          <DropdownFilter className={s.dropdownOperator}
                          dataCy='operator'
                          headerPlaceholder={filtersDict.operator}
                          filterPlaceholder="Начните ввод..."
                          onSelect={item => filtersCtx.asyncDispatch(filtersCtx, { type: 'selectOperator', operator: item, token })}
                          selectedItem={operatorEncoded}
                          shouldHighlight={Boolean(operatorEncoded)}
                          disabled={regularFilterDisabled}
                          list={operatorsListEncoded ?? []} />
        ) }

        { checkFilterVisibility('status') && (
          <DropdownFilter className={s.dropdownStatus}
                          dataCy="status"
                          headerPlaceholder={filtersDict.status}
                          filterPlaceholder="Начните ввод..."
                          onSelect={item => filtersCtx.asyncDispatch(filtersCtx, { type: 'selectStatus', status: item, token })}
                          selectedItem={status}
                          shouldHighlight={Boolean(status)}
                          disabled={regularFilterDisabled}
                          list={statuses} />
        ) }

        { checkFilterVisibility('substatus') && (
          <DropdownFilter className={s.dropdownSubStatus}
                          dataCy="sub-status"
                          headerPlaceholder={filtersDict.substatus}
                          filterPlaceholder="Начните ввод..."
                          onSelect={item => filtersCtx.asyncDispatch(filtersCtx, { type: 'selectSubstatus', substatus: item, token })}
                          selectedItem={substatus}
                          shouldHighlight={Boolean(substatus)}
                          disabled={subStatusIsDisabled}
                          list={substatuses} />
        ) }

        { checkFilterVisibility('search_by_words') && (
          <div className={s.wordsSearchWrapper}>
            <span className={s.wordsTitle}>{ filtersDict.search_by_words }</span>

            <InputSearch className={s.wordsInput}
                         name="words"
                         dataCy='words'
                         disabled={isLoading}
                         defaultValue={wordsToFind}
                         keyValue={wordsToFind}
                         onBlur={e => filtersCtx.asyncDispatch(filtersCtx, { type: 'selectByWords', str: e.target.value, token })} />
          </div>
        ) }

        { checkFilterVisibility('dialogs_markup') && (
          <div className={s.labelFilterWrapper}>
            <LabelFilter key={labeledEmo + labeledEmoTemp + labeledTag + labeledLead}
                         className={s.labelFilter}
                         title={filtersDict.dialogs_markup}
                         disabled={regularFilterDisabled} />
          </div>
        ) }

        { checkFilterVisibility('search_by_dicts') && (
          <DropdownFilter className={s.dropdownDictionaries}
                          headerPlaceholder={filtersDict.search_by_dicts}
                          dataCy="search-by-dictinaries"
                          filterPlaceholder="Начните ввод..."
                          onSelect={item => filtersCtx.asyncDispatch(filtersCtx, { type: 'selectDictionary', dictionary: item, token })}
                          shouldHighlight={Boolean(dictionary)}
                          selectedItem={dictionary}
                          disabled={dictSearchIsDisabled}
                          list={dictionaries ?? []} />
        ) }

        { checkFilterVisibility('audio_upload') && (
          <CallAudioLoader className={s.callAudioLoader} />
        ) }

        <div className={s.dealSelectionWrapper}>
          { isLoading
            ? <Loader className={s.loader} data-cy="loader" />
            : <h1 data-cy="deals-length">{ dealList.length }</h1> }

          <DealSelection className={s.dealSelection} shouldHighlight={Boolean(deal)} />
        </div>

        <div className={s.controls}>
          { isAdmin && (
            <Button className={s.changeDB}
                    dataCy='change-db'
                    onClick={x.onExit}>
              Сменить БД
            </Button>
          ) }
          { !isAdmin && (
            <Button className={s.exit}
                    dataCy='exit'
                    onClick={() => authCtx.dispatch({ type: 'logOut' })}>
              Выйти
            </Button>
          ) }

          <Button className={s.showChartBtn} type="button"
                  dataCy="jump-to-charts"
                  disabled={toChartsIsDisabled}
                  onClick={handleJumpToChartsClick}>
            К графикам
          </Button>
        </div>

        <div className={s.lastRow}>
          <Button className={s.saveBtn} disabled={saveButtonIsDisabled}
                  dataCy="save-loaded-deals"
                  onClick={() => dispatch({ type: 'saveLoadedDeals' })}>Выбрать</Button>

          <span ref={datePickerRef}
                data-cy="date-picker"
                data-disabled={isLoading}
                className={s.datePickerLauncher}>
            { showDate(selectedDates ?? []) }
          </span>

          <div className={s.datePickerWrapper}>
            <DatePicker range={true}
                        onShow={el => el.scrollIntoView(false)}
                        onSelect={dates => filtersCtx.asyncDispatch(filtersCtx, { type: 'selectDates', dates, token })}
                        selectedDates={selectedDates ?? []}
                        launchers={[datePickerRef]}
                        disabled={isLoading}
                        highlightDates={availableDates} />
          </div>

        </div>
      </div>
    </div>
  );
};

function showDate(selectedDates: [Date?, Date?]) {
  const [dateFrom, dateTo] = selectedDates;
  if (!dateFrom) return 'Выберите дату';

  let dateFromStr = formatDate(dateFrom);
  if (!dateTo) return dateFromStr;

  const dateToStr = formatDate(dateTo);
  if (dateFromStr === dateToStr) return dateFromStr;

  dateFromStr = formatDate(dateFrom, 'dd.mm');

  return `${dateFromStr} - ${dateToStr}`;
};
