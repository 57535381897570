import React from 'react';
import { cn, isSameDate } from '@utils';
import s from './Calendar.module.css';

const days = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

type Props = {
  onDatesSelect?: (date: [Date, Date?]) => void
  date: Date
  selectedDates?: [Date?, Date?]
  className?: string
  highlightDates?: Date[]
  dataCy?: string
  range?: boolean
};

function Calendar(x: Props) {
  const { onDatesSelect, date, selectedDates = [], className, highlightDates = [], range } = x;
  const ref = React.useRef<HTMLDivElement>(null);
  const hightLightObj = Object.fromEntries(highlightDates.map(i => [i.toDateString(), true]));

  const dates = createDatesArray(date);
  const currentMonth = date.getMonth();

  const handleDateSelect = (d: Date) => {
    const el = ref.current;
    if (!el) return;

    if (!range) {
      el.dataset.showRange = 'false';
      el.dataset.handleHover = 'false';
      return onDatesSelect?.([d, d]);
    }

    if (selectedDates.length === 2) { // диапазон выбран, но начат новый выбор.
      onDatesSelect?.([d]);
      el.dataset.direction = 'forward';
      el.dataset.showRange = 'true';
      return;
    }

    if (range && selectedDates[0]) {
      const datesLocal: [Date, Date] = [selectedDates[0], d];
      if (el.dataset.direction === 'backward') datesLocal.reverse();

      el.dataset.direction = 'forward';
      el.dataset.showRange = 'true';
      el.dataset.handleHover = 'false';
      return onDatesSelect?.(datesLocal);
    }

    onDatesSelect?.([d]);
  };

  const handleMouseEnter = (d: Date) => {
    const el = ref.current;
    if (!el) return;
    if (selectedDates.length !== 1) return;

    const timeStampStart = Number(selectedDates[0]);
    const timeStamp2Current = Number(d);

    el.dataset.direction = timeStampStart < timeStamp2Current ? 'forward' : 'backward';
    el.dataset.showRange = 'true';
  };

  const handleMouseLeave = () => {
    const el = ref.current;
    if (!el) return;
    if (selectedDates.length > 1) return;
    el.dataset.showRange = 'false';
  };

  return (
    <div className={cn(s.calendar, className)} ref={ref}
         data-show-range='true'
         data-direction='forward'
         data-handle-hover={selectedDates.length === 2 ? 'false' : 'true'}>
      { days.map((day, i) => (
        <span className={i < 5 ? s.weekdays : s.weekends}
              key={day}>
          { day }
        </span>
      )) }

      { dates.map((d, index)=> (
        <span key={d.getTime()}
              className={cn(
                s.date,
                (d.getDay() > 0 && d.getDay() < 6) ? s.weekdays : s.weekends,
                (d.getMonth() === currentMonth) ? s.currentMonth : s.otherMonth,
                (selectedDates[0] && isSameDate(selectedDates[0], d) && s.dateFromSelected),
                (index === 0
                  && selectedDates[0]
                  && (Number(selectedDates[0]) < Number(d))
                  && (!selectedDates[1] || (Number(selectedDates[1]) > Number(d)))
                  && s.nextMonthStartSelection), // если не окончен выбор и перешли на следующий месяц
                (selectedDates[1] && isSameDate(selectedDates[1], d) && s.dateToSelected),
                (d.toDateString() in hightLightObj) && s.dateHighlighted,
              )}
              onClick={() => handleDateSelect(d)}
              onMouseEnter={() => handleMouseEnter(d)}
              onMouseLeave={handleMouseLeave}
              data-cy={`${x.dataCy}-${d.toLocaleDateString('ru')}`}>
          { d.getDate() }
        </span>
      )) }
    </div>
  );
}

export { Calendar };

function createDatesArray(date: Date) {
  const currentMonth = date.getMonth();
  const year = date.getFullYear();
  const firstDateOfMonth = new Date(year, currentMonth);
  const lastDateOfMonth = new Date(year, currentMonth + 1, 0);

  const calendarStartDate = firstDateOfMonth;
  while(calendarStartDate.getDay() !== 1) {
    calendarStartDate.setDate(calendarStartDate.getDate() - 1);
  }

  const calendarEndDate = lastDateOfMonth;
  while(calendarEndDate.getDay() !== 0) {
    calendarEndDate.setDate(calendarEndDate.getDate() + 1);
  }

  const dates = [];
  for (let day = calendarStartDate; day.getTime() <= calendarEndDate.getTime();) {
    const d = new Date(day);
    dates.push(d);
    day.setDate(day.getDate() + 1);
  }

  return dates;
}
