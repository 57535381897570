import * as h from './helpers';

export type Masks = {
  [projName: string]: number[] | undefined
};

export const initialState = {
  tagNames: undefined as undefined | string[],
  masks: undefined as undefined | Masks,
  isLoading: false,
  dispatch: {} as Dispatch,
  asyncDispatch,
};

export type State = typeof initialState;
export type StateSlice = Partial<State> & { isLoading: boolean };
export type Dispatch = React.Dispatch<Action>;

type Action =
  | { type: 'initDispatch'; dispatch: Dispatch }
  | { type: 'updateStateAsync'; stateSlice: StateSlice }
;

export function MasksCtxReducer(state: State, action: Action): State {
  const { type } = action;
  if (state.isLoading && type !== 'updateStateAsync') return state;

  if (type === 'updateStateAsync') {
    const { stateSlice } = action;
    return { ...state, ...stateSlice };

  } else if (type === 'initDispatch') {
    return { ...state, dispatch: action.dispatch };
  }

  const remnant: never = type;
  throw new Error(`unhandled action.type = ${remnant} in masks reducer`);
};

type ActionAsync =
  | { type: 'getMasks' }
  | { type: 'addTag'; tagName: string }
  | { type: 'removeTag'; tagName: string }
  | { type: 'removeMask'; projectId: string }
  | { type: 'saveMarkup'; projectId: string; mask: number[] }
;

async function asyncDispatch<T extends ActionAsync, U extends T['type']>(state: State, action: T & ActionAsync): Promise<AsyncDispatchResults[U]> {
  const { type } = action;
  const setIsLoading = (slice?: Partial<State>) => state.dispatch({ type: 'updateStateAsync', stateSlice: { isLoading: true, ...slice } });

  if (type === 'saveMarkup') {
    const { mask, projectId } = action;
    setIsLoading();
    return h.saveMarkup({ mask, projectId, state });

  } else if (type === 'getMasks') {
    setIsLoading();
    return h.getMasks({ state });

  } else if (type === 'removeMask') {
    const { projectId } = action;
    setIsLoading();
    return h.removeMask({ projectId, state });

  } else if (type === 'addTag') {
    const { tagName } = action;
    setIsLoading();
    return h.addTag({ state, tagName });

  } else if (type === 'removeTag') {
    const { tagName } = action;
    setIsLoading();
    return h.removeTag({ state, tagName });
  }

};

type AsyncDispatchResults = {
  getMasks: Promise<void>
  addTag: Promise<void>
  removeTag: Promise<void>
  removeMask: Promise<void>
  saveMarkup: Promise<void>
};
