import React, { useEffect, useReducer, useRef } from 'react';
import { cn } from '@utils';
import { InitialLists } from '@contexts';
import * as subsetApi from '@api/baseSubset';
import { Button, DatePicker, DropdownFilter } from '@components';
import { initialState, reducer, init } from './reducer';

import s from './FilterBar.module.css';
// TODO добавить возможность удалять фильтр

type Props = {
  initialLists: InitialLists
  className?: string
  token: string
  accessType: string
  filterId: string
  filterInfos: subsetApi.FilterInfos | undefined
};
const stages = [
  ['тегов', 'with-tags'], ['эмоций', 'with-emo'], ['эмоций*', 'with-emo-star']
];
const stageDispatchers = ['setWithEmo', 'setWithEmoTemp', 'setWithTags'] as const;

export function FilterBar(x: Props) {
  const { projects, operators, statuses } = x.initialLists;
  const filterInfo = x.filterInfos?.[Number(x.filterId)];
  const datePickerLauncher1 = useRef(null);
  const datePickerLauncher2 = useRef(null);

  const [state, dispatch] = useReducer(reducer, initialState, () => init(initialState, filterInfo));

  useEffect(() => dispatch({ type: 'initDispatch', dispatch }), []);

  const {
    project, operator, status, datePickerDates,
    dateFrom, dateTo,
    durationTo, durationFrom, file, isEditing,
    withEmo, withEmoTemp, withTags,
  } = state;

  const inputFile = useRef<HTMLInputElement>(null);

  const handleFileInputChange = () => {
    if (!inputFile.current?.files) return;
    dispatch({ type: 'uploadFile', file: inputFile.current.files[0] });
  };

  const handleDateSelect = (dates: [Date, Date?]) => {
    dispatch({ type: 'selectDates', dates });
  };

  const handleRemoveClick = () => {
    const id = prompt('Введите ID фильтра');
    if (!id) return;
    console.log(`Удаление фильтра № ${id}....`);
  };

  const isAdmin = x.accessType === 'admin';
  const stagesValues = [withEmo, withEmoTemp, withTags];

  return (
    <form className={cn(s.filterBar, x.className)} data-cy="filter-bar"
          onSubmit={e => e.preventDefault()}>

      <div className={cn(s.row, s.ids)}>
        <h4 data-cy="filter-number"
            className={s.rowTitle}>№ { isEditing ? 'нет' : x.filterId }&nbsp;</h4>

        <span className={s.rowTitle}>ID звонка:</span>
        <input className={s.callIdInput}
               key={state.dealId}
               data-cy="deal-id"
               defaultValue={state.dealId}
               disabled={!isAdmin}
               onBlur={e => dispatch({ type: 'selectDealId', dealId: e.target.value })}
               type="text" name="callId" autoComplete="off" />
      </div>

      <DropdownFilter className={s.dropdownProject}
                      headerPlaceholder="Проект"
                      dataCy="project"
                      filterPlaceholder="Начните ввод..."
                      onSelect={p => dispatch({ type: 'selectProject', project: p })}
                      selectedItem={project}
                      shouldHighlight={Boolean(project)}
                      disabled={!isAdmin}
                      list={projects} />

      <DropdownFilter className={s.dropdownStatus}
                      headerPlaceholder="Статус"
                      dataCy="status"
                      filterPlaceholder="Начните ввод..."
                      onSelect={i => dispatch({ type: 'selectStatus', status: i })}
                      disabled={!isAdmin}
                      selectedItem={status}
                      shouldHighlight={Boolean(status)}
                      list={statuses} />

      <DropdownFilter className={s.dropdownOperator}
                      headerPlaceholder="Оператор"
                      dataCy="operator"
                      filterPlaceholder="Начните ввод..."
                      onSelect={o => dispatch({ type: 'selectOperator', operator: o })}
                      selectedItem={operator}
                      shouldHighlight={Boolean(operator)}
                      disabled={!isAdmin}
                      list={operators} />

      <div className={cn(s.row, s.dates)}>
        <span className={s.rowTitle}>Даты:</span>
        <div className={s.datesContainer}>

          <span className={s.pretext}>c</span>
          <span ref={datePickerLauncher1}
                data-cy="date-from"
                onClick={() => dispatch({ type: 'setDateEdge', edge: 'from' })}
                className={cn(s.datePicker, s.dateInput, !dateFrom && s.dateInputEmpty)}>
            { dateFrom ? dateFrom : 'гггг.мм.дд' }
          </span>
          <span className={s.pretext}>по</span>
          <span ref={datePickerLauncher2}
                data-cy="date-to"
                onClick={() => dispatch({ type: 'setDateEdge', edge: 'to' })}
                className={cn(s.datePicker, s.dateInput, !dateTo && s.dateInputEmpty)}>
            { dateTo ? dateTo : 'гггг.мм.дд' }
          </span>

          <div className={s.datePickerWrapper}>
            <DatePicker launchers={[datePickerLauncher1, datePickerLauncher2]}
                        selectedDates={datePickerDates}
                        range={true}
                        disabled={!isAdmin}
                        onSelect={handleDateSelect} />
          </div>
        </div>
      </div>

      <div className={cn(s.row, s.duration)}>
        <span className={s.rowTitle}>Длит, cек:</span>
        <div className={s.datesContainer}>
          <span className={s.pretext}>с</span>

          <input type="text" name="duration-from" className={s.durationInput}
                 value={durationFrom ?? ''}
                 onChange={e => dispatch({ type: 'selectDurationFrom', time: e.target.value })}
                 disabled={!isAdmin}
                 autoComplete="off" />

          <span className={s.pretext}>по</span>

          <input type="text" name="duration-to" className={s.durationInput}
                 value={durationTo ?? ''}
                 onChange={e => dispatch({ type: 'selectDurationTo', time: e.target.value })}
                 disabled={!isAdmin}
                 autoComplete="off" />

        </div>
      </div>
      <div className={cn(s.row, s.stages)}>
        <span className={s.stagesTitle}>Наличие:</span>
        { stages.map(([rus, dataCy], index) => (
          <label key={rus} className={s.stageLabel}>
            <input type="checkbox"
                   data-cy={dataCy}
                   checked={stagesValues[index]}
                   onChange={() => dispatch({ type: `${stageDispatchers[index]}` })} />
            <span className={s.stageValue}>{ rus }</span>
          </label>
        )) }
      </div>

      { isAdmin && (
        <div className={s.buttonsContainer}>
          <Button className={s.reset} onClick={() => dispatch({ type: 'reset' })}>
            Сбросить</Button>

          <label className={s.upload} data-cy="uploader">
            <input ref={inputFile} hidden type="file" name=""
                   onChange={handleFileInputChange}
                   className={s.uploadFile} />
            <span className={s.uploadDesc}>{ file?.name ?? 'Загрузить' }</span>
          </label>
        </div>
      ) }

      <div className={s.response}>
        <Button type="button" className={s.submit}
                onClick={() => dispatch({ type: 'saveFilterRecog', token: x.token })}>Запрос</Button>
        <span data-cy="response-text">{ state.responseText ?? '' }</span>
      </div>

      <Button dataCy="remove-filter" onClick={handleRemoveClick}>Удалить фильтр</Button>

    </form>
  );
};
