import React, { useContext, useEffect, useState } from 'react';
import { Auth, SelectionDB, Sidebar1, Sidebar2, Sidebar3, } from '@components';
import { AuthCtx, ConfigCtx } from '@contexts';

import { Panels } from '@contexts/config/lists';
import s from './App.module.css';

export type Base = 'Subset';

export function App() {
  const authCtx = useContext(AuthCtx);
  const configCtx = useContext(ConfigCtx);

  const [currentBase, setBase] = useState<Base | undefined>();

  const { accessType, isAuth } = authCtx;
  const { configTemp } = configCtx;
  const isAdmin = accessType === 'admin';

  useEffect(() => {
    if (accessType && !isAdmin) setBase('Subset');
  }, [accessType]);

  const checkPanelVisibility = (panel: Panels) => {
    if (!isAuth || !currentBase) return false;
    const panels = configTemp[accessType]?.panels;
    return panels ? panels.includes(panel) : false;
  };

  const showSelectionDB = isAuth && !currentBase && isAdmin;
  const showSidebar1 = isAuth && currentBase;

  return (
    <div className={s.app}>
      <div className={s.sidebar1Wrapper} data-cy="sidebar1">
        { !isAuth && <Auth className={s.auth} /> }
        { showSelectionDB && <SelectionDB className={s.selectionDB} onSelect={setBase} /> }
        { showSidebar1 && <Sidebar1 className={s.sidebar1} onExit={() => setBase(undefined)} /> }
      </div>

      <div className={s.sidebar2}>
        { checkPanelVisibility('sidebar2') && <Sidebar2 /> }
      </div>

      <div className={s.sidebar3Wrapper}>
        { checkPanelVisibility('sidebar3') && <Sidebar3 className={s.sidebar3} /> }
      </div>
    </div>
  );
};
