import React, { createContext, useEffect, useReducer } from 'react';
import { MasksCtxReducer, initialState } from './reducer';

export const MasksCtx = createContext(initialState);
const { Provider } = MasksCtx;

type Props = {
  children: React.ReactNode
};

export function MasksCtxProvider(x: Props) {
  const [state, dispatch] = useReducer(MasksCtxReducer, initialState);
  useEffect(() => dispatch({ type: 'initDispatch', dispatch }), []);

  if (typeof state.dispatch !== 'function') return null;

  return (
    <Provider value={{ ...state, dispatch }}>
      { x.children }
    </Provider>
  );
};
