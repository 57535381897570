import * as authApi from '@api/auth';
import * as h from './helpers';

export const initialState = {
  isLoading: false,
  isAuth: false,
  accessType: '',
  token: '',
  error: '' as authApi.Result,
  dispatch: {} as Dispatch,
  asyncDispatch,
};

export type State = typeof initialState;
export type StateSlice = Partial<State> & { isLoading: boolean };
export type Dispatch = React.Dispatch<Action>;

type Action =
  | { type: 'updateStateAsync'; stateSlice: StateSlice }
  | { type: 'initDispatch'; dispatch: Dispatch }
  | { type: 'logOut' }
;

export function AuthCtxReducer(state: State, action: Action): State {
  const { type } = action;
  if (state.isLoading && type !== 'updateStateAsync') return state;

  if (type === 'updateStateAsync') {
    const { stateSlice } = action;
    return { ...state, ...stateSlice };

  } else if (type === 'logOut') {
    return { ...initialState, dispatch: state.dispatch };

  } else if (type === 'initDispatch') {
    return { ...state, dispatch: action.dispatch };
  }

  const remnant: never = type;
  throw new Error(`unhandled action.type = ${remnant} in Auth reducer`);
}

type ActionAsync =
| { type: 'logIn'; login: string; password: string }
;

async function asyncDispatch<T extends ActionAsync, U extends T['type']>(state: State, action: T & ActionAsync): Promise<AsyncDispatchResults[U]> {
  const { type } = action;
  const setIsLoading = (slice?: Partial<State>) => state.dispatch({ type: 'updateStateAsync', stateSlice: { isLoading: true, ...slice } });
  const { dispatch } = state;

  if (type === 'logIn') {
    setIsLoading();
    return h.logIn({ ...action, dispatch });
  }
};

type AsyncDispatchResults = {
  logIn: ReturnType<typeof h.logIn>
};
