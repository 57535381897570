import { z } from 'zod';
import md5 from 'md5';

type Args = {
  login: string
  password: string
};

export type AuthResult = z.infer<typeof AuthResult>;

export type Access = z.infer<typeof Access>;
export type Result = z.infer<typeof Result>;

export async function auth(x: Args) {
  const url = 'https://dashboards.reffection.com/dashboards/api/dash_auth.php';
  const { login, password } = x;

  // демо
  // const login = 'demo1';
  // const password = 'demoqwe123';

  // оператор
  // const login = 'test1' as string;
  // const password = 'Go2DashBoard' as string;

  // отдел контроля качества
  // const login = 'test2' as string;
  // const password = 'Run2DashBoard' as string;

  // админ
  // const login = 'admin1' as string;
  // const password = 'Jk5Rt1Q!' as string;

  const sendingObject = {
    token: 'BCCA165471A6BB90D531EC08879941C7',
    l: md5(login),
    p: md5(password),
  };

  const formData = JSON.stringify(sendingObject);
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', },
      body: formData,
    });

    const r = await response.json() as unknown;
    return r;

  } catch (e) {
    alert('Ошибка сетевого подключения. Повторите попытку позже...');
    console.log(e);
  }
}

export function isAuthResult(r: unknown): r is AuthResult {
  return AuthResult.safeParse(r).success;
}

const Result = z.union([
  z.literal('token error'),
  z.literal('wrong login'),
  z.literal('wrong password'),
  z.literal('')
]);

const Access = z.union([z.literal('true'), z.literal('false')]);

const AuthResult = z.object({
  access: Access,
  access_type: z.string(),
  result: Result,
  token: z.string(),
});
