import { Aspect, State } from '../reducer';

type Args = {
  isChecked: boolean
  state: State
  role: string
  aspect: Aspect
  id: string
};

export function roleOptionsChange(x: Args): State {
  const { isChecked, state, aspect, role, id } = x;
  const { configSaved } = state;

  const configTemp = { ...state.configTemp };
  const roleOptions = configTemp[role];
  if (!roleOptions) return state;

  const listTemp = [...roleOptions[aspect]];
  const listSaved = configSaved[role]?.[aspect] ?? [] as string[];

  const func = isChecked ? addUniqueToList : deleteInList;
  const updatedList = func(id, listTemp);
  (roleOptions[aspect] as string[]) = updatedList;
  configTemp[role] = { ...roleOptions };

  const roleConfigChanged
      = [...listSaved].sort().toString() !== [...updatedList].sort().toString();

  const changesStack = [...state.changesStack];

  if (roleConfigChanged) {
    changesStack.push(1);
  } else {
    changesStack.pop();
  }

  return { ...state, configTemp, changesStack };
}

function addUniqueToList<T extends string>(value: T, list: T[]): T[] {
  const listCopy = [...list];
  if (!listCopy.includes(value)) listCopy.push(value);
  return listCopy;
}

function deleteInList<T extends string>(value: T, list: T[]): T[] {
  const listCopy = [...list];
  const index = listCopy.indexOf(value);
  if (index !== -1) listCopy.splice(index, 1);
  return listCopy;
}
