import { GetConfigResult } from '@api/config';
import { delay } from '@utils';
import { isGetConfigResult } from './getConfig';
import * as api from '.';

const item = 'config';
type Args = {
  info: GetConfigResult
};
export async function saveConfig(x: Args) {
  const { info } = x;
  await delay(500);

  localStorage.setItem(item, JSON.stringify(info));
  return api.getConfig();
}

export type SaveConfigResult = GetConfigResult;
export const isSaveConfigResult = isGetConfigResult;
