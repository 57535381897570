import { MasksCtx, State } from '../reducer';

type Args = {
  state: State
  name: string
  masksCtx: Readonly<MasksCtx>
};

export function addMask(x: Args): State {
  const { state, name, masksCtx } = x;

  const { masks, tagNames } = masksCtx;
  if (!masks || !tagNames) return state;

  const nameIsNotUnique = name in masks;
  if (nameIsNotUnique) {
    alert('Такой проект уже существует!');
    return state;
  }

  const newState: State = {
    ...state,
    mask: tagNames.map(str => 0),
    project: { id: name, text: name }
  };

  return newState;
}
