import { getBookmarks, getFilters, getPanels } from '@contexts/config/lists';
import { GetConfigResult } from '@api/config';

export const configMock: GetConfigResult = {
  admin: {
    bookmarks: [...getBookmarks()],
    filters: [...getFilters()],
    panels: [...getPanels()],
    neuro: ['show'],
  },

  control_department: {
    filters: [
      'search_by_call_id', 'search_by_table_id', 'filter_id', 'project',
      'operator', 'status', 'search_by_words', 'dialogs_markup',
    ],

    bookmarks: [
      'tags', 'emotions', 'emotions_star', 'leads', 'lexic', 'script', 'masks', 'dictionaries',
    ],

    panels: [
      'sidebar2', 'sidebar3'
    ],

    neuro: [],
  },

  operator: {
    filters: ['search_by_call_id', 'project', 'operator', 'status'],
    bookmarks: [],
    panels: [],
    neuro: [],
  },

  demo: {
    filters: ['search_by_call_id', 'project', 'operator', 'status', 'audio_upload'],
    bookmarks: [],
    panels: [],
    neuro: [],
  },
};
