import React from 'react';
import Sunny from '@assets/images/sunny.png';

type Props = {
  className?: string
};

export function AnalysisBar(x: Props) {
  return (
    <div className={x.className}>
      <img src={Sunny} alt="Солнышко" width="380" height="370" />
    </div>
  );
};
