import { MasksCtx, State } from '../reducer';

type Args = {
  state: State
  index: number
  value: number
  masksCtx: Readonly<MasksCtx>
};

export function changeMarkup(x: Args): State {
  const { state, index, value, masksCtx } = x;

  const { project, mask } = state;
  if (!project || !mask) return state;

  const { tagNames, masks } = masksCtx;
  if (!tagNames || !masks) return state;

  const newMask = [...mask];
  newMask[index] = value;

  const projectId = project.id;

  const old = masks[projectId] ?? tagNames.map(str => 0);
  const maskWasChanged = old.join('') !== newMask.join('');

  const newState: State = {
    ...state,
    mask: newMask,
    tagsWereChanged: maskWasChanged,
  };

  return newState;
}
