import React, { useLayoutEffect, useRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { ValidDialogAnalysis } from '@api/baseSubset';

type Props = {
  data: ValidDialogAnalysis
  className: string
  callId: string
};

function SpeechChart(x: Props) {
  const { data: dataProp, className, callId } = x;
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!ref || !ref.current) return;
    const mappedData = mapResponseToChart(dataProp);
    const data = mappedData;

    const chart = am4core.create(ref.current, am4charts.XYChart);

    const xAxis = chart.xAxes.push(new am4charts.ValueAxis());
    xAxis.renderer.minGridDistance = 30;
    xAxis.renderer.labels.template.fontSize = 12;
    xAxis.title.text = 'Реплики';
    xAxis.title.fontSize = 14;
    const yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.renderer.minGridDistance = 30;
    yAxis.renderer.labels.template.fontSize = 12;
    yAxis.title.text = 'Темп';
    yAxis.title.fontSize = 14;

    const opSeries = chart.series.push(new am4charts.LineSeries());
    opSeries.data = data.operator;
    opSeries.dataFields.valueX = 'index';
    opSeries.dataFields.valueY = 'tempo';
    opSeries.fill = am4core.color('#0000ff');
    opSeries.stroke = am4core.color('#0000ff');

    const bulletOp = opSeries.bullets.push(new am4charts.CircleBullet());
    bulletOp.circle.radius = 3;
    bulletOp.strokeWidth = 1;

    const abSeries = chart.series.push(new am4charts.LineSeries());
    abSeries.data = data.abonent;
    abSeries.dataFields.valueX = 'index';
    abSeries.dataFields.valueY = 'tempo';
    abSeries.fill = am4core.color('#00acef');
    abSeries.stroke = am4core.color('#00acef');

    const bulletAb = abSeries.bullets.push(new am4charts.CircleBullet());
    bulletAb.circle.radius = 3;
    bulletOp.strokeWidth = 1;

    const filledDistanceBellow = chart.series.push(new am4charts.LineSeries());
    filledDistanceBellow.data = data.filledDistanceBelow;
    filledDistanceBellow.dataFields.valueX = 'index';
    filledDistanceBellow.dataFields.openValueY = 'open';
    filledDistanceBellow.dataFields.valueY = 'close';
    filledDistanceBellow.sequencedInterpolation = true;
    filledDistanceBellow.fillOpacity = 0.2;
    filledDistanceBellow.fill = am4core.color('red');
    filledDistanceBellow.strokeOpacity = 0;

    const filledDistanceAbove = chart.series.push(new am4charts.LineSeries());
    filledDistanceAbove.data = data.filledDistanceAbove;
    filledDistanceAbove.dataFields.valueX = 'index';
    filledDistanceAbove.dataFields.openValueY = 'open';
    filledDistanceAbove.dataFields.valueY = 'close';
    filledDistanceAbove.sequencedInterpolation = true;
    filledDistanceAbove.fillOpacity = 0.2;
    filledDistanceAbove.fill = am4core.color('green');
    filledDistanceAbove.strokeOpacity = 0;

    chart.paddingLeft = 0;
    yAxis.renderer.labels.template.paddingLeft = 5;
    yAxis.renderer.labels.template.paddingRight = 5;
    xAxis.title.align = 'right';

    return () => chart.dispose();
  });

  return (
    <div ref={ref} className={className}
         data-cy="speech-chart"
         data-call-id={callId}></div>
  );
};

function mapResponseToChart(data: ValidDialogAnalysis) {
  const operator: {tempo: number; index: number}[] = [];
  const abonent: {tempo: number; index: number}[] = [];

  for (let i = 0; ; i++) {
    const speaker = (data.speaker)[i];
    if (!speaker) break;

    const tempo = (data.word_tempo)[i];
    if (speaker === 'operator') {
      operator.push({ index: i + 1, tempo });
    } else {
      abonent.push({ index: i + 1, tempo });
    }
  }

  const interPolatedOperators = interpolation(operator);
  const interPolatedAbonents = interpolation(abonent);

  const filledDistanceBelow = [];
  const filledDistanceAbove = [];

  let prevPointO;
  let prevPointA;
  for (let i = 0; i < interPolatedOperators.length; i++) {
    const pointO = interPolatedOperators[i];
    const pointA = interPolatedAbonents.find(item => item.index === pointO.index);

    if (pointA) {
      if (prevPointO && prevPointA) {
        const crossing = am4core.math.getLineIntersection(
          { y: pointO.tempo, x: pointO.index },
          { y: prevPointO.tempo, x: prevPointO.index },

          { y: pointA.tempo, x: pointA.index },
          { y: prevPointA.tempo, x: prevPointA.index }
        );
        const hasIntersection = crossing.x > prevPointO.index && crossing.x < pointO.index;

        if (hasIntersection) {
          interPolatedOperators.splice(i, 0, { tempo: crossing.y, index: crossing.x });
          interPolatedAbonents.splice(i, 0, { tempo: crossing.y, index: crossing.x });
          prevPointO = prevPointA = { tempo: crossing.x, index: crossing.y };
          i -= 1;
          continue;
        }
      }

      if (pointA.tempo >= pointO.tempo) {
        filledDistanceAbove.push({
          index: pointO.index,
          open: pointA.tempo,
          close: pointO.tempo,
        });
      }
      if (pointA.tempo <= pointO.tempo) {
        filledDistanceBelow.push({
          index: pointO.index,
          open: pointA.tempo,
          close: pointO.tempo,
        });
      }

      prevPointO = pointO;
      prevPointA = pointA;
    }
  };
  // во избежание некорректного выделения
  filledDistanceBelow.push(filledDistanceBelow[filledDistanceBelow.length - 1]);
  filledDistanceBelow.unshift(filledDistanceBelow[0]);
  filledDistanceAbove.push(filledDistanceAbove[filledDistanceAbove.length - 1]);
  filledDistanceAbove.unshift(filledDistanceAbove[0]);

  return { operator, abonent, filledDistanceAbove, filledDistanceBelow };

  function interpolation(arr: typeof operator) {
    const res: typeof operator = [];

    arr.forEach((val, index, array) => {
      if (index === 0) return res.push(val);

      const prevI = array[index - 1].index;
      const currI = val.index;
      const d = currI - prevI;
      if (d === 1) return res.push(val);

      const currT = val.tempo;
      const prevT = array[index - 1].tempo;
      for (let i = 1; i < d; i++) {
        res.push({
          index: prevI + i,
          tempo: prevT + i * (currT - prevT) / d,
        });
      }
      return res.push(val);
    });

    return res;
  }
}

const MemoizedSpeechChart = React.memo(SpeechChart,
  (prevProps, nextProps) => prevProps.callId === nextProps.callId
);

export { MemoizedSpeechChart as SpeechChart };
