import * as api from '@api/masks';
import { State } from '../reducer';
import { getMasks } from './getMasks';

type Args = {
  state: State
  projectId: string
};

export async function removeMask(x: Args) {
  const { projectId, state } = x;
  const defaultDispatch = () => state.dispatch({ type: 'updateStateAsync', stateSlice: { isLoading: false } });

  const result = await api.removeMask({ projectId });
  if (!result) {
    alert('Неудачно...');
    return defaultDispatch();
  }

  return getMasks({ state });
}
