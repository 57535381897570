import { delay } from '@utils';
import { GetMasksResult } from './getMasks';
import { ITEM } from './constants';

type Args = {
  tagName: string
};

export async function addTag(x: Args) {
  await delay(500);

  const { tagName } = x;

  const masks = localStorage.getItem(ITEM);
  if (!masks) return false;

  try {
    const result: GetMasksResult = JSON.parse(masks);
    const { tagNames, projectMasks } = result;
    if (tagNames.includes(tagName)) return false;

    tagNames.push(tagName);
    Object.values(projectMasks).forEach(m => m?.push(0));

    localStorage.setItem(ITEM, JSON.stringify(result));
    return true;

  } catch(e) {
    console.log('Ошибка при добавлении тега...');
    return false;
  }
}
