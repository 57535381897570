import * as api from '@api/baseSubset';
import { StateSlice, State } from '../reducer';

type Args = {
  id: string
  token: string
  state: State
  force?: boolean
};

export async function getDealInfo(x: Args) {
  const { id, token, state, force } = x;
  const { dispatch, dealDatas } = state;

  const shouldLoad = !dealDatas[id] || force;
  if (!shouldLoad) {
    dispatch({
      type: 'updateStateAsync', stateSlice: {
        isLoading: false,
        callHistoryState: 'unknown',
        historyCalls: [],
      }
    });
    return dealDatas[id];
  }

  const deals = await api.getDeals({ deal_id: id, token });

  if (!api.isGetDealsResult(deals)) {
    return dispatch({ type: 'updateStateAsync', stateSlice: { isLoading: false, } });
  }

  const stateSlice: StateSlice = {
    isLoading: false,
    callHistoryState: 'unknown',
    dealDatas: { ...dealDatas, [id]: deals[id] },
    historyCalls: [],
  };

  dispatch({ type: 'updateStateAsync', stateSlice });
  return deals[id];
};
