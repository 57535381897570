import React, { useState } from 'react';
import { EyeOff, Eye } from '@assets/icons';
import { Field } from '../Field';

type Props = {
  value: string
  onChange(v: string): void
  className?: string
  invalid?: boolean
  dataCy?: string
};

export function PasswordField(x: Props) {
  const [type, setType] = useState<'password' | 'text'>('password');

  return (
    <Field className={x.className} type={type} placeholder="Пароль"
           value={x.value} onChange={x.onChange}
           invalid={x.invalid}
           dataCy='password'
           errorMessage="Неверный пароль"
           autoComplite="current-password"
           icon={type === 'password' ? EyeOff : Eye}
           onIconClick={() => setType(type === 'password' ? 'text' : 'password')} />
  );
};
