import { GetConfigResult } from '@api/config';
import { copySimpleObj } from '@utils';
import * as h from './helpers';

export const initialState = {
  isLoading: false,
  configSaved: {} as GetConfigResult,
  configTemp: {} as GetConfigResult,
  changesStack: [] as number[],
  dispatch: {} as Dispatch,
  asyncDispatch,
};

const emptyRoleConfig: GetConfigResult[string] = {
  bookmarks: [],
  filters: [],
  panels: [],
  neuro: [],
};

export type State = typeof initialState;
export type StateSlice = Partial<State> & { isLoading: boolean };
export type Dispatch = React.Dispatch<Action>;

type Action =
  | { type: 'createRole'; name: string }
  | { type: 'removeRole'; name: string }
  | { type: 'reset' }
  | { type: 'changeRoleConfig'; role: string; aspect: Aspect; isChecked: boolean; id: string }
  | { type: 'updateStateAsync'; stateSlice: StateSlice }
  | { type: 'initDispatch'; dispatch: Dispatch }
;

export type Aspect = keyof NonNullable<GetConfigResult[string]>;

export function ConfigCtxReducer(state: State, action: Action): State {
  const { type } = action;
  if (state.isLoading && type !== 'updateStateAsync') return state;

  if (type === 'updateStateAsync') {
    const { stateSlice } = action;
    return { ...state, ...stateSlice };

  } else if (type === 'changeRoleConfig') {
    const { aspect, isChecked, role, id } = action;
    return h.roleOptionsChange({ aspect, id, isChecked, role, state });

  } else if (type === 'createRole') {
    const { name } = action;
    const { configTemp } = state;
    if (name in configTemp) return state;

    configTemp[name] = copySimpleObj(emptyRoleConfig);
    return h.handleRolesListChange({ state });

  } else if (type === 'removeRole') {
    const { name } = action;
    const { configTemp } = state;
    if (!(name in configTemp)) return state;

    const configTempCopy = { ...configTemp };
    delete configTempCopy[name];
    return h.handleRolesListChange({ state: { ...state, configTemp: configTempCopy } });

  } else if (type === 'reset') {
    return { ...state, configTemp: copySimpleObj(state.configSaved), changesStack: [] };

  } else if (type === 'initDispatch') {
    return { ...state, dispatch: action.dispatch };
  }

  const remnant: never = type;
  throw new Error(`unhandled action.type = ${remnant} in dictionary reducer`);
}

type ActionAsync =
  | { type: 'getConfig' }
  | { type: 'saveConfig'; info: GetConfigResult }
;

async function asyncDispatch<T extends ActionAsync, U extends T['type']>(state: State, action: T & ActionAsync): Promise<AsyncDispatchResults[U]> {
  const { type } = action;
  const setIsLoading = (slice?: Partial<State>) => state.dispatch({ type: 'updateStateAsync', stateSlice: { isLoading: true, ...slice } });

  if (type === 'saveConfig') {
    const { info } = action;
    setIsLoading();
    return h.saveConfig({ state, info });

  } else if (type === 'getConfig') {
    setIsLoading();
    return h.getConfig({ state });
  }
};

type AsyncDispatchResults = {
  saveConfig: ReturnType<typeof h.saveConfig>
  getConfig: ReturnType<typeof h.getConfig>
};
