import React, { useContext, useEffect, useRef, useState } from 'react';
import { GetDictionaryResult } from '@api/dictionaries';
import { cn } from '@utils';
import { AuthCtx, DictionaryCtx, StateSaver } from '@contexts';
import { Button, DropdownFilter, ListItem } from '@components';

import s from './DictionariesBar.module.css';

type Props = {
  className?: string
};

export function DictionariesBar(x: Props) {
  const savedState = useContext(StateSaver).DictionariesBar;
  const [selectedDict, setSelectedDictOrigin] = useState<ListItem | undefined>(savedState.selectedDict);
  const setSelectedDict: typeof setSelectedDictOrigin = v => {
    setSelectedDictOrigin(v);
    if (typeof v !== 'function') savedState.selectedDict = v;
    else savedState.selectedDict = v(savedState.selectedDict);
  };

  const { token } = useContext(AuthCtx);
  const dictCtx = useContext(DictionaryCtx);
  const wordRef = useRef<HTMLInputElement>(null);

  const { dictionaries, dictInfos, isLoading } = dictCtx;

  useEffect(() => {
    if (dictionaries) return;
    dictCtx.asyncDispatch(dictCtx, { type: 'getDictsList', token });
  }, []);

  const dictInfo = selectedDict && dictInfos[Number(selectedDict.id)];
  const dictId = selectedDict?.id;
  const dictName = selectedDict?.text;
  const wordsSet = new Set(dictInfo?.words);

  const handleDictSelection = (item: ListItem) => {
    const itemId = item.id;
    setSelectedDict(item);

    if (!(itemId in dictInfos)) {
      dictCtx.asyncDispatch(dictCtx, { type: 'loadDictInfo', id: Number(itemId), token });
    }
  };

  const createDictionary = async () => {
    const name = prompt('СОЗДАНИЕ: введите название словаря:');
    if (!name) return;

    const result = await dictCtx.asyncDispatch(dictCtx, { type: 'create', name, token });

    if (!result) return;
    const listItem: ListItem = { id: String(result.id), text: result.dict_name };
    setSelectedDict(listItem);
  };

  const deleteDictionary = () => {
    const id = prompt('УДАЛЕНИЕ: введите id словаря:');
    if (!id) return;
    if (!dictionaries?.find(d => d.id === id)) return;

    dictCtx.asyncDispatch(dictCtx, { type: 'delete', id: Number(id), token });
    setSelectedDict(undefined);
  };

  const selectColor = (color: string) => {
    if (!dictInfo || !selectedDict) return;
    const name = selectedDict.text;
    const data = { ...dictInfo, color };
    dictCtx.asyncDispatch(dictCtx, { type: 'update', id: Number(selectedDict.id), data, token, name });
  };

  const addWord = () => {
    if (!wordRef.current) return;
    if (!dictInfo || !selectedDict) return;
    const value = wordRef.current.value;
    wordRef.current.value = '';
    if (wordsSet.has(value)) return;

    wordsSet.add(value);
    const data = { ...dictInfo, words: Array.from(wordsSet) };
    const name = selectedDict.text;
    dictCtx.asyncDispatch(dictCtx, { type: 'update', id: Number(selectedDict.id), data, token, name });
  };

  const removeWord = () => {
    if (!wordRef.current) return;
    if (!dictInfo || !selectedDict) return;
    const value = wordRef.current.value;
    wordRef.current.value = '';
    if (!wordsSet.has(value)) return;

    const confirm = prompt(`Вы действительно хотите удалить слово ${value} из словаря?`, 'Да/Нет');
    if (confirm !== 'Да') return;

    wordsSet.delete(value);
    const data = { ...dictInfo, words: Array.from(wordsSet) };
    const name = selectedDict.text;
    dictCtx.asyncDispatch(dictCtx, { type: 'update', id: Number(selectedDict.id), data, token, name });
  };

  return (
    <div className={cn(s.dictionariesBar, x.className, isLoading && s.disabled)}
         data-cy="dictionaries-bar"
         data-disabled={isLoading}>
      <div className={s.captions}>
        <h4>Словарь</h4>
        <DropdownFilter className={s.dropdownDictionary}
                        headerPlaceholder="Словарь"
                        dataCy='dictionaries'
                        shouldHighlight={Boolean(selectedDict)}
                        filterPlaceholder="Начните ввод..."
                        onSelect={handleDictSelection}
                        selectedItem={selectedDict}
                        list={dictionaries ?? []} />

        <Button onClick={createDictionary} dataCy="add-dict">Создать</Button>
        <Button onClick={deleteDictionary} dataCy="remove-dict">Удалить</Button>

        <h4 className={s.colorTitle}>Цвет</h4>
        <ColorInput key={dictInfo?.color}
                    onSelect={selectColor}
                    defalutValue={dictInfo?.color} />
      </div>

      <div className={s.wordsListTitle}>
        <span>Список слов</span>
        <span>ID словаря: { dictId ?? 'н/д' }</span>
      </div>

      <div className={s.wordsList} data-cy="words-list">
        { dictInfo && (dictInfo.words.length > 0
          ? dictInfo.words.map(w => <span key={w}>{ w },</span>)
          : <span>Словарь пока пуст...</span>) }
        { !dictInfo && <span>Выберите словарь</span> }
      </div>

      <div className={s.wordControls}>
        <h4>Добавить/удалить слово</h4>
        <input className={s.input}
               ref={wordRef}
               type="text"
               name="word"
               data-cy="word-input"
               autoComplete='off' />
        <Button onClick={addWord} dataCy="add-word">+</Button>
        <Button onClick={removeWord} dataCy="remove-word">-</Button>
      </div>

      <Button className={s.loadDictsBtn}
              disabled={!dictId}
              dataCy="export-csv"
              onClick={() => dictName && loadCSV(dictName, dictInfos[Number(dictId)])}>
        Экспорт в CSV
      </Button>
    </div>
  );
};

type ColorInputProps = {
  defalutValue?: string
  onSelect: (color: string) => void
  keyValue?: React.Key
};

function ColorInput(x: ColorInputProps) {
  const { defalutValue = '#000000', onSelect, keyValue } = x;
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const input = ref.current;
    if (!input) return;

    const handleChange = () => {
      const color = input.value;
      return onSelect(color);
    };
    input.addEventListener('change', handleChange);
    return () => document.removeEventListener('change', handleChange);
  });

  return (
    <input className={s.colorInput}
           key={keyValue}
           ref={ref}
           defaultValue={defalutValue}
           type="color"
           name="color" />
  );
}

function loadCSV(name: string, data: GetDictionaryResult['dict'] | undefined) {
  if (!data) return;

  const caption = name + '\r\n';
  const replacer = (str: string) => {
    let strCopy = str;
    strCopy = strCopy.replace(/"/g, '""');

    const regexp = /[",;\n]/g;
    if (regexp.test(strCopy)) {
      strCopy = `"${strCopy}"`;
    }
    return strCopy;
  };

  const rows = data.words.map(replacer).join('\r\n');
  const csv = caption + rows;

  const blob = new Blob([csv], { type: 'text/csv' });
  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.download = 'dictionary.csv';
  a.click();
};
