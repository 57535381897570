import { delay, randomInteger } from '@utils';

type Args = {
  file: File
};

const item = 'demoAudioIds';
export async function uploadAudio(x: Args) {
  await delay(500);

  const id = 'D' + randomInteger(10000000, 99999999);

  const resultStr = localStorage.getItem(item);
  if (!resultStr) {
    localStorage.setItem(item, JSON.stringify([id]));
    return id;
  }

  const result = JSON.parse(resultStr);
  result.push(id);
  localStorage.setItem(item, JSON.stringify(result));

  return id;
};
