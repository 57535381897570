import * as api from '@api/baseSubset';
// Загружает информацию только по выделенному звонку

type Args = {
  callInfo: api.Deal
};

export function loadCallJSON(x: Args) {
  const { callInfo } = x;
  const deal = { [callInfo.deal_id]: callInfo };
  const blob = new Blob([JSON.stringify(deal, null, 2)], { type: 'application/json' });
  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.download = 'selection.json';
  a.click();
}
