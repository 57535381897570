import React, { memo, useLayoutEffect, useRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

type Props = {
  data: { [name: string]: number | string | Date }[] // обязательно поле 'date'
  equalityKey: unknown
  className?: string
  colors?: string[]
  fieldNameInTooltip?: boolean
  hasLegend?: boolean
};

function DynamicsByDatesChart(x: Props){
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!ref || !ref.current) return;

    const chart = am4core.create(ref.current, am4charts.XYChart);
    chart.data = x.data;
    chart.dateFormatter.intlLocales = 'ru';

    chart.paddingBottom = 0;
    chart.paddingLeft = 0;

    if (x.hasLegend) {
      const legend = new am4charts.Legend();
      chart.legend = legend;
      legend.position = 'bottom';
      legend.labels.template.fontSize = 12;
      legend.markers.template.width = 12;
      legend.markers.template.height = 12;
      legend.itemContainers.template.paddingTop = 0;
      legend.itemContainers.template.paddingBottom = 25;
      chart.bottomAxesContainer.height = 10;
    }

    if (x.colors) {
      const colors = x.colors.map(c => am4core.color(c));
      const defaultColors = [...chart.colors.list];
      chart.colors.list = [...colors, ...defaultColors];
    }

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.baseInterval = { timeUnit: 'day', count: 1 };
    dateAxis.dateFormats.setKey('day', { month: 'short', day: 'numeric' });

    dateAxis.dataFields.date = 'date';
    dateAxis.renderer.grid.template.visible = false;
    dateAxis.renderer.labels.template.visible = false;
    dateAxis.renderer.labels.template.paddingBottom = 0;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.strictMinMax = true;
    valueAxis.renderer.grid.template.visible = false;
    valueAxis.renderer.labels.template.visible = false;
    valueAxis.min = 0;

    valueAxis.renderer.inside = true; // сдвинуть график до левого края

    function createSeries(name: string) {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.dateX = 'date';
      series.dataFields.valueY = name;

      if (x.fieldNameInTooltip) {
        series.columns.template.tooltipText = name + ': {valueY}';
      } else {
        series.columns.template.tooltipText = '{valueY}';
      }

      series.stacked = true;
      series.tooltipXField = 'tooltip'; // каким-то образом отключает другую подсказку...
    }

    Object.keys(x.data[0])
      .filter(key => key !== 'date')
      .forEach(key => createSeries(key));

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineX.disabled = true;
    chart.cursor.lineY.disabled = true;
    valueAxis.cursorTooltipEnabled = false;

    return () => chart.dispose();
  });

  return <div ref={ref} className={x.className}></div>;
};

const MemoizedChart = memo(DynamicsByDatesChart, (prevProps, nextProps) => {
  return prevProps.equalityKey === nextProps.equalityKey;
});
export { MemoizedChart as DynamicsByDatesChart };
