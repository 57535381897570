export type Args = {
  token: string
  operator?: string
  project?: string
  result?: string
  dateFrom?: string
  dateTo?: string
  durationFrom?: string
  durationTo?: string
  segment?: string
  dealId?: string
};

export async function saveFilterRecog(x: Args) {
  const url = 'https://dashboards.reffection.com/dashboards/api/gen_process.php';

  const formData = JSON.stringify({
    process: 'save_filter_recog',
    token: x.token,
    data: {
      oper: x.operator ?? '',
      cus: x.project ?? '',
      res: x.result ?? '',
      dt_f: x.dateFrom ?? '',
      dt_t: x.dateTo ?? '',
      dr_f: x.durationFrom ?? '',
      dr_t: x.durationTo ?? '',
      seg: '', // segment
      id_c: x.dealId ?? '',
    },
  });

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: formData,
    });

    const r = await response.json();
    return r;

  } catch (e) {
    console.log(e);
  }
};
