import { z } from 'zod';
import { parseAndLogIfError } from '../parseAndLogIfError';
import { DealsObj } from './commonTypes';

type Args = {
  token: string
  idTable: number
  dealId: string
  project: string
};

export type GetDealHistoryResult = z.infer<typeof GetDealHistoryResult>;
export function isGetDealHistoryResult(o: unknown): o is GetDealHistoryResult {
  return parseAndLogIfError(GetDealHistoryResult, o);
};

export async function getDealHistory(x: Args) {
  const url = 'https://dashboards.reffection.com/dashboards/api/get_history_call.php';
  const formData = JSON.stringify({
    token: x.token,
    idtable: x.idTable,
    deal_id: x.dealId,
    customer: x.project,
  });

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: formData,
    });

    const r = await response.json() as unknown;
    return r;

  } catch (e) {
    console.log(e);
    const r = [] as unknown;
    return r;
  }
};

const GetDealHistoryResult = DealsObj;
