import { z } from 'zod';
import { parseAndLogIfError } from '@api/parseAndLogIfError';
import { RequestDicts } from './types';
import { basicRequest } from './basicRequest';

export async function loadList(token: string): Promise<unknown> {
  const sendingObject: RequestDicts = {
    token,
    process: 'dict_work',
    data: { proc: 'lst', }
  };

  const formData = JSON.stringify(sendingObject);
  return basicRequest({ formData });
};

const LoadListResult = z.object({
  id: z.number(),
  dict_name: z.string(),
}).array();

export type LoadListResult = z.infer<typeof LoadListResult>;
export function isLoadListResult(o: unknown): o is LoadListResult {
  return parseAndLogIfError(LoadListResult, o);
};
