import { z } from 'zod';
import { parseAndLogIfError } from '@api/parseAndLogIfError';
import { csv } from '@mocks/tagsInProjects';
import { parseMasksCSV, delay } from '@utils';
import { ITEM } from './constants';

export async function getMasks(): Promise<unknown> {
  await delay(500);

  const result = localStorage.getItem(ITEM);
  if (result) return JSON.parse(result);

  localStorage.setItem(ITEM, JSON.stringify(parseMasksCSV(csv)));
  return JSON.parse(localStorage.getItem(ITEM) ?? '{}');
}

const GetMaksResult = z.object({
  tagNames: z.string().array(),
  projectMasks: z.record(z.union([
    z.union([z.literal(0), z.literal(1)]).array(),
    z.undefined(),
  ])),
});

export type GetMasksResult = z.infer<typeof GetMaksResult>;
export function isGetMasksResult(o: unknown): o is GetMasksResult {
  return parseAndLogIfError(GetMaksResult, o);
};
