import React, { useEffect, useRef, useState } from 'react';
import { Search, Arrow, Copy } from '@assets/icons';
import { cn, useOnClickOutside } from '@utils';

import s from './DropdownFilter.module.css';

export type ListItem = {id: string; text: string};

type Props = {
  list: ListItem[]
  onSelect: (item: ListItem) => void
  selectedItem?: ListItem
  className?: string
  filterPlaceholder?: string
  headerPlaceholder?: string
  disabled?: boolean
  dataCy?: string
  enableCopy?: boolean
  shouldHighlight: boolean
};

export function DropdownFilter(x: Props) {
  const { list, onSelect, selectedItem, disabled } = x;

  const headerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [isExpanded, setIsExpanded] = useState(false);
  const [filter, setFilter] = useState('');

  useOnClickOutside(headerRef, () => {
    setIsExpanded(false);
    setFilter('');
  });

  useEffect(() => inputRef.current?.focus());

  const handleSelect = (item: ListItem) => {
    setIsExpanded(false);
    onSelect?.(item);
  };

  const handleCopyClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    const text = selectedItem?.id;
    if (text) {
      navigator.clipboard.writeText(text);
    }
  };

  const filteredList = list
    .filter(({ text }) => text.toLowerCase().includes(filter.toLowerCase()));

  return (
    <div className={cn(s.dropdownFilter,
      x.className)}
         ref={headerRef}>
      { !isExpanded && (
        <div data-cy={x.dataCy}
             className={cn(s.header,
               x.shouldHighlight && s.selected)}
             data-disabled={disabled}
             onClick={() => !disabled && setIsExpanded(true)}>
          <div className={s.value}>
            { selectedItem ? selectedItem.text : x.headerPlaceholder }
          </div>
          { !disabled && x.enableCopy && (
            <Copy className={s.copy} onClick={handleCopyClick} />
          ) }
          <Arrow className={s.arrow} />
        </div>
      ) }

      { isExpanded && (
        <>
          <label className={cn(s.search, x.shouldHighlight && s.selected)}>
            <Search className={s.searchIcon} />
            <input className={s.input} type="text" placeholder={x.filterPlaceholder}
                   data-cy={`${x.dataCy}-input`}
                   value={filter} onChange={e => setFilter(e.target.value)} ref={inputRef} />
            <Arrow className={cn(s.arrow, s.arrowReversed)} />
          </label>

          <ul className={s.list} data-cy={`${x.dataCy}-list`}>
            { filteredList.length === 0
              ? <li className={s.listItem} data-cy="empty">Нет данных...</li>
              : filteredList.map(item => (
                <li key={item.id} className={s.listItem}
                    onClick={() => handleSelect(item)}>
                  { item.text }
                </li>
              )) }
          </ul>
        </>
      ) }
    </div>
  );
};
