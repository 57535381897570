import React from 'react';
import { bookmarkList, filtersList, panelsList, neuroList } from '@contexts/config/lists';
import { AspectList } from '../components';

import s from '../ConfigBar.module.css';

type RoleConfigProps = {
  role: string
};

export function RoleConfig(x: RoleConfigProps) {
  const { role } = x;

  return (
    <details className={s.roleOptions}>
      <summary className={s.roleTitle}>{ role }</summary>
      <AspectList title="Панели"
                  list={panelsList} role={role} aspect="panels" />
      <AspectList title="Фильтры"
                  list={filtersList} role={role} aspect="filters" />
      <AspectList title="Закладки"
                  list={bookmarkList} role={role} aspect="bookmarks" />
      <AspectList title="Нейросеть"
                  list={neuroList} role={role} aspect="neuro" />
    </details>
  );
};
