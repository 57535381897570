export function parseMasksCSV(csv: string) {
  const arr = csv.split('\n');
  const tagNames = arr[0].split(',').slice(1);
  const projectMasks = arr.slice(1)
    .map(str => [
      str.replace(/(\w+),[0,1]+/, '$1'), // название проекта
      str.replace(/\w+,([0,1]+)/, '$1').split(',').map(s => Number(s))
    ]);
  const projectMasksObj = Object.fromEntries(projectMasks) as {[projName: string]: number[] | undefined};

  return {
    tagNames,
    projectMasks: projectMasksObj
  };
}
