import { TagsAnalysis } from './commonTypes';

type Args = {
  token: string
  idChain: string
  callId: string
  tags: TagsAnalysis
  hasDelta: boolean
};

export async function saveTags(x: Args) {
  const url = 'https://dashboards.reffection.com/dashboards/api/gen_process.php';

  const saveTagsBody = JSON.stringify({
    process: 'save_emotions_tags',
    token: x.token,
    data: {
      idchain: x.idChain,
      analisys: { tags: x.tags },
      call_id: x.callId,
      deltas: { tag_d: Number(x.hasDelta) }
    }
  });

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: saveTagsBody,
    });

    const r = await response.json();
    return r;

  } catch (e) {
    console.log(e);
  }
};
