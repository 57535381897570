import { ListItem } from '@components';
import { State, MasksCtx } from '../reducer';

type Args = {
  state: State
  project: ListItem
  masksCtx: Readonly<MasksCtx>
};

export function setProject(x: Args): State {
  const { state, project, masksCtx } = x;
  const { tagNames, masks } = masksCtx;
  if (!tagNames || !masks) return state;

  const projectId = project.id;
  const oldMask = masks[projectId];
  const mask = oldMask ? [...oldMask] : tagNames.map(str => 0);

  const newState: State = {
    ...state,
    project,
    tagsWereChanged: false,
    mask
  };

  return newState;
}
