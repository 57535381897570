import { ListItem } from '@components';
import React from 'react';

/*
  Полностью мутабельный. Нужен для сохранения состояния различных
  компонент, когда их полностью размонтируют.
  Нет провайдера. Общий для всего приложения.
*/
export const StateSaver = React.createContext<StateSaver>({
  LexicBar: {},
  DictionariesBar: {},
});

type StateSaver = {
  LexicBar: { // имя сохраняемого компонента
    // к-во свойств = к-ву примененных useState в компоненте
    dictList?: ListItem[] // сохраняемое состояние из useState
    selectedDict?: undefined | ListItem
  }

  DictionariesBar: {
    selectedDict?: ListItem
  }
};
