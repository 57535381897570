// данные этого звонка становятся данными всех звонков, которых якобы
// отправили на анализ
export const callInfo = {
  'info_comment': '',
  'idchain': '5189666b-612f-468d-adf4-2e8236d19481',
  'idtable': 31420930,
  'deal_id': '20376090',
  'customer': 'avtomir_hyundai_nsk_1911',
  'dt': '2022-04-01 10:03:12.237',
  'operator': 'ур.Лопырева (Нелли)',
  'comment': 'неудобно\r\nв кредит',
  'time': '10:03:12',
  'date': '2022-04-01',
  'result': 'Уточнить решение',
  'script': 'https://forms.reffection.com/#/play/avtomir_hyundai_nsk_1911',
  'calls': [
    {
      'call_id': 'mix_13061_16e014__2022_04_01__10_03_10_950',
      'url': 'https://lk.reffection.com:8080/Records/20220401/1003/mix_13061_16e014__2022_04_01__10_03_10_950.mp3',
      'duration': 49,
      'filter_id': 112,
      'analisys': {
        'dialogs': {
          'end': {
            '0': '00:00:02.039',
            '1': '00:00:09.166',
            '2': '00:00:13.759',
            '3': '00:00:17.337',
            '4': '00:00:26.080',
            '5': '00:00:29.817',
            '6': '00:00:32.719',
            '7': '00:00:35.668',
            '8': '00:00:38.789',
            '9': '00:00:43.519',
            '10': '00:00:47.213',
            '11': '00:00:47.559',
            '12': '00:00:48.219'
          },
          'start': {
            '0': '00:00:01.360',
            '1': '00:00:02.199',
            '2': '00:00:08.699',
            '3': '00:00:14.510',
            '4': '00:00:17.599',
            '5': '00:00:25.939',
            '6': '00:00:30.099',
            '7': '00:00:33.579',
            '8': '00:00:35.709',
            '9': '00:00:39.199',
            '10': '00:00:43.059',
            '11': '00:00:47.139',
            '12': '00:00:47.253'
          },
          'speech': {
            '0': 'алло',
            '1': 'здравствуйте меня зовут нелли я представляю автомир официального дилера хендай илья вы ранее общались с моим коллегой относительно',
            '2': 'ага автомобиля угу хендэ актуально для вас приобретение в течение месяца в новосибирске',
            '3': 'а сколько у вас кредит какой короче скажите',
            '4': 'а кредитные условия зависят от множества факторов это выбранный банк первоначальный взнос срок и так далее давайте я вас сейчас тогда с менеджером соединю он сориентирует',
            '5': 'не вас сейчас не надо сейчас не надо я сейчас занят короче',
            '6': 'хорошо когда вам будет удобно перезвонить',
            '7': 'через минут тридцать сорок вот так вот',
            '8': 'хорошо мы слышно с вами он сразу свяжемся мне звонит сразу',
            '9': 'а этот к сожалению как его такой технической товарищ возможности нет мы можем вас только переключить',
            '10': 'угу угу тогда мы с вами свяжемся в течение тридцати сорока минут спасибо за',
            '11': 'понятно',
            '12': 'уделенное хорошо время'
          },
          'tag_01': {
            '0': 0.6983997821807861,
            '1': 0.7009657025337219,
            '2': 0.5703107714653015,
            '3': 0.6055939793586731,
            '4': 0.6116674542427063,
            '5': 0.5169507265090942,
            '6': 0.6616801023483276,
            '7': 0.5471136569976807,
            '8': 0.5667052865028381,
            '9': 0.5309289693832397,
            '10': 0.5485271215438843,
            '11': 0.6269650459289551,
            '12': 0.6126171350479126
          },
          'tag_02': {
            '0': 0.5583796501159668,
            '1': 0.7112605571746826,
            '2': 0.5250545740127563,
            '3': 0.6081896424293518,
            '4': 0.7369031310081482,
            '5': 0.581108570098877,
            '6': 0.6626392602920532,
            '7': 0.5969853401184082,
            '8': 0.6229851245880127,
            '9': 0.6187806725502014,
            '10': 0.6022617220878601,
            '11': 0.7013681530952454,
            '12': 0.6356651782989502
          },
          'tag_03': {
            '0': 0.7302187085151672,
            '1': 0.6395095586776733,
            '2': 0.611127495765686,
            '3': 0.6630994081497192,
            '4': 0.6042425632476807,
            '5': 0.6225390434265137,
            '6': 0.6714494228363037,
            '7': 0.682499349117279,
            '8': 0.6385508179664612,
            '9': 0.5890763998031616,
            '10': 0.6227661371231079,
            '11': 0.6835165023803711,
            '12': 0.6331338286399841
          },
          'tag_04': {
            '0': 0.5778913497924805,
            '1': 0.5884643197059631,
            '2': 0.5080410838127136,
            '3': 0.6449849605560303,
            '4': 0.5810889601707458,
            '5': 0.6076698303222656,
            '6': 0.5856979489326477,
            '7': 0.5009362697601318,
            '8': 0.5567141771316528,
            '9': 0.6352497935295105,
            '10': 0.5202402472496033,
            '11': 0.5860078930854797,
            '12': 0.5616658926010132
          },
          'tag_05': {
            '0': 0.5934360027313232,
            '1': 0.5754573345184326,
            '2': 0.5649217367172241,
            '3': 0.6786203980445862,
            '4': 0.7163500785827637,
            '5': 0.4677327275276184,
            '6': 0.6911067962646484,
            '7': 0.6929633617401123,
            '8': 0.6097822189331055,
            '9': 0.5879935026168823,
            '10': 0.6086223125457764,
            '11': 0.7013681530952454,
            '12': 0.671831488609314
          },
          'tag_06': {
            '0': 0.7098262906074524,
            '1': 0.6155401468276978,
            '2': 0.601917564868927,
            '3': 0.7549659013748169,
            '4': 0.6171866655349731,
            '5': 0.6732529997825623,
            '6': 0.6565455198287964,
            '7': 0.6779261827468872,
            '8': 0.6223205327987671,
            '9': 0.665890097618103,
            '10': 0.6276662349700928,
            '11': 0.7178711295127869,
            '12': 0.7134137153625488
          },
          'tag_07': {
            '0': 0.6987772583961487,
            '1': 0.6551555395126343,
            '2': 0.733609139919281,
            '3': 0.7474252581596375,
            '4': 0.6483825445175171,
            '5': 0.6880542635917664,
            '6': 0.7046215534210205,
            '7': 0.7818812131881714,
            '8': 0.7032674551010132,
            '9': 0.6474819183349609,
            '10': 0.7819250822067261,
            '11': 0.777101993560791,
            '12': 0.7049886584281921
          },
          'tag_08': {
            '0': 0.5977487564086914,
            '1': 0.5704287886619568,
            '2': 0.46973395347595215,
            '3': 0.5739381909370422,
            '4': 0.5582027435302734,
            '5': 0.5060977339744568,
            '6': 0.48873990774154663,
            '7': 0.5235395431518555,
            '8': 0.47899118065834045,
            '9': 0.5356590151786804,
            '10': 0.46080851554870605,
            '11': 0.46148237586021423,
            '12': 0.4916691184043884
          },
          'tag_09': {
            '0': 0.5916634798049927,
            '1': 0.5396523475646973,
            '2': 0.5042788982391357,
            '3': 0.667186975479126,
            '4': 0.5333800315856934,
            '5': 0.5981284379959106,
            '6': 0.6133612990379333,
            '7': 0.5163283348083496,
            '8': 0.5333532691001892,
            '9': 0.5535296201705933,
            '10': 0.596275269985199,
            '11': 0.6200920343399048,
            '12': 0.5726487636566162
          },
          'tag_10': {
            '0': 0.56554114818573,
            '1': 0.5602725744247437,
            '2': 0.5624788999557495,
            '3': 0.657823920249939,
            '4': 0.5906751155853271,
            '5': 0.538773238658905,
            '6': 0.6730169653892517,
            '7': 0.6281610727310181,
            '8': 0.6915279626846313,
            '9': 0.5844777822494507,
            '10': 0.66203373670578,
            '11': 0.6902999877929688,
            '12': 0.7097116708755493
          },
          'emo_aro': {
            '0': {
              'pred': 0,
              'true': 0
            },
            '1': {
              'pred': 0,
              'true': 0
            },
            '2': {
              'pred': 0,
              'true': 0
            },
            '3': {
              'pred': 0,
              'true': 0
            },
            '4': {
              'pred': 0,
              'true': 0
            },
            '5': {
              'pred': 0,
              'true': 0
            },
            '6': {
              'pred': 0,
              'true': 0
            },
            '7': {
              'pred': 0,
              'true': 0
            },
            '8': {
              'pred': 0,
              'true': 0
            },
            '9': {
              'pred': 0,
              'true': 0
            },
            '10': {
              'pred': 0,
              'true': 0
            },
            '11': {
              'pred': 0,
              'true': 0
            },
            '12': {
              'pred': 0,
              'true': 0
            }
          },
          'emo_val': {
            '0': {
              'pred': 0,
              'true': 0
            },
            '1': {
              'pred': 0,
              'true': 0
            },
            '2': {
              'pred': 0,
              'true': 0
            },
            '3': {
              'pred': 0,
              'true': 0
            },
            '4': {
              'pred': 0,
              'true': 0
            },
            '5': {
              'pred': 0,
              'true': 0
            },
            '6': {
              'pred': 0,
              'true': 0
            },
            '7': {
              'pred': 0,
              'true': 0
            },
            '8': {
              'pred': 0,
              'true': 0
            },
            '9': {
              'pred': 0,
              'true': 0
            },
            '10': {
              'pred': 0,
              'true': 0
            },
            '11': {
              'pred': 0,
              'true': 0
            },
            '12': {
              'pred': 0,
              'true': 0
            }
          },
          'speaker': {
            '0': 'abonent',
            '1': 'operator',
            '2': 'abonent',
            '3': 'abonent',
            '4': 'operator',
            '5': 'abonent',
            '6': 'operator',
            '7': 'abonent',
            '8': 'operator',
            '9': 'operator',
            '10': 'abonent',
            '11': 'abonent',
            '12': 'operator'
          },
          'interrupt': {
            '0': -1.36,
            '1': -0.16,
            '2': 0.47,
            '3': -0.75,
            '4': -0.26,
            '5': 0.14,
            '6': -0.28,
            '7': -0.86,
            '8': -0.04,
            '9': -0.41,
            '10': 0.46,
            '11': 0.07,
            '12': 0.31
          },
          'word_tempo': {
            '0': 1.47,
            '1': 2.58,
            '2': 2.57,
            '3': 2.83,
            '4': 3.07,
            '5': 3.09,
            '6': 2.29,
            '7': 3.35,
            '8': 3.57,
            '9': 3.7,
            '10': 3.37,
            '11': 2.38,
            '12': 3.11
          },
          'dostoevsky_skip': {
            '0': 0.018,
            '1': 0.04,
            '2': 0.029,
            '3': 0.135,
            '4': 0.091,
            '5': 0.116,
            '6': 0.025,
            '7': 0.238,
            '8': 0.15,
            '9': 0.029,
            '10': 0.125,
            '11': 0,
            '12': 0.005
          },
          'dostoevsky_speech': {
            '0': 0,
            '1': 0.285,
            '2': 0.059,
            '3': 0.004,
            '4': 0.036,
            '5': 0.001,
            '6': 0,
            '7': 0,
            '8': 0.002,
            '9': 0.109,
            '10': 0.502,
            '11': 0,
            '12': 0
          },
          'dostoevsky_neutral': {
            '0': 0.13,
            '1': 0.073,
            '2': 0.106,
            '3': 0.115,
            '4': 0.062,
            '5': 0.117,
            '6': 0.031,
            '7': 0.108,
            '8': 0.005,
            '9': 0.087,
            '10': 0.016,
            '11': 0.131,
            '12': 0.019
          },
          'dostoevsky_negative': {
            '0': 0.001,
            '1': 0.188,
            '2': 0.035,
            '3': 0.048,
            '4': 0.138,
            '5': 0.144,
            '6': 0.011,
            '7': 0.03,
            '8': 0.238,
            '9': 0.138,
            '10': 0.02,
            '11': 0.001,
            '12': 0.009
          },
          'dostoevsky_positive': {
            '0': 0,
            '1': 0.002,
            '2': 0.021,
            '3': 0.002,
            '4': 0.051,
            '5': 0.009,
            '6': 0.381,
            '7': 0.007,
            '8': 0.114,
            '9': 0.035,
            '10': 0.023,
            '11': 0,
            '12': 0.355
          }
        },
        'tones': {
          'abonent': {
            'dostoevsky_skip': {
              'pred': 0.116,
              'true': -1
            },
            'dostoevsky_speech': {
              'pred': 0.001,
              'true': -1
            },
            'dostoevsky_neutral': {
              'pred': 0.115,
              'true': -1
            },
            'dostoevsky_negative': {
              'pred': 0.03,
              'true': -1
            },
            'dostoevsky_positive': {
              'pred': 0.007,
              'true': -1
            }
          },
          'operator': {
            'dostoevsky_skip': {
              'pred': 0.034,
              'true': -1
            },
            'dostoevsky_speech': {
              'pred': 0.019,
              'true': -1
            },
            'dostoevsky_neutral': {
              'pred': 0.046,
              'true': -1
            },
            'dostoevsky_negative': {
              'pred': 0.138,
              'true': -1
            },
            'dostoevsky_positive': {
              'pred': 0.082,
              'true': -1
            }
          }
        },
        'dicts': {
          'subs': {
            'used': 'обращались в ОП / есть менеджер',
            'bad_geo': 'ГЕО',
            'bad_legal': 'физ/юр.лицо',
            'bad_place': 'расположение ДЦ',
            'no_budget': 'бюджет ',
            'other_brand': 'другая марка',
            'other_model': 'другая модель',
            'second_hand': 'б/у',
            'non_targeted': 'нецелевой',
            'time_purchase': 'срок покупки',
            'negative_perception': 'негатив'
          },
          'tags': {
            'tag_01': 'Приветствие',
            'tag_02': 'Интерес',
            'tag_03': 'Гео',
            'tag_04': 'Обращение',
            'tag_05': 'УТП',
            'tag_06': 'Потребность',
            'tag_07': 'Модель',
            'tag_08': 'Физ/юрлицо',
            'tag_09': 'Оплата',
            'tag_10': 'Переключение'
          },
          'watson': {
            'emo_1': 'Грусть',
            'emo_2': 'Радость',
            'emo_3': 'Страх',
            'emo_4': 'Недовольство',
            'emo_5': 'Гнев'
          },
          'dostoevsky': {
            'emo_1': 'dostoevsky_positive',
            'emo_2': 'dostoevsky_neutral',
            'emo_3': 'dostoevsky_negative',
            'emo_4': 'dostoevsky_speech',
            'emo_5': 'dostoevsky_skip'
          }
        },
        'tags': {
          'tag_01': {
            'name': 'Приветствие',
            'pred': 0.7009657025337219,
            'true': 0
          },
          'tag_02': {
            'name': 'Интерес',
            'pred': 0.7369031310081482,
            'true': 0
          },
          'tag_03': {
            'name': 'Гео',
            'pred': 0.7302187085151672,
            'true': 0
          },
          'tag_04': {
            'name': 'Обращение',
            'pred': 0.6449849605560303,
            'true': 0
          },
          'tag_05': {
            'name': 'УТП',
            'pred': 0.7163500785827637,
            'true': 0
          },
          'tag_06': {
            'name': 'Потребность',
            'pred': 0.7549659013748169,
            'true': 0
          },
          'tag_07': {
            'name': 'Модель',
            'pred': 0.7819250822067261,
            'true': 0
          },
          'tag_08': {
            'name': 'Физ/юрлицо',
            'pred': 0.5977487564086914,
            'true': 0
          },
          'tag_09': {
            'name': 'Оплата',
            'pred': 0.667186975479126,
            'true': 0
          },
          'tag_10': {
            'name': 'Переключение',
            'pred': 0.7097116708755493,
            'true': 0
          }
        }
      }
    }
  ]
};
