import React, { useLayoutEffect, useRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { ValidDialogAnalysis } from '@api/baseSubset';

type Props = {
  className?: string
  data: ValidDialogAnalysis
  callId: string
};

const DurationTheresholds = {
  operator: 0,
  abonent: 0,
  interruptions: 0,
  pauses: 1.5
};

function TempoChart(x: Props) {
  const { className, data, callId } = x;
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!ref || !ref.current) return;
    const chart = am4core.create(ref.current, am4charts.XYChart);

    const colorSet = new am4core.ColorSet();
    colorSet.saturation = 0.4;

    const mappedData = mapDataToChart(data);

    const abonentPart = mappedData
      .filter(d => d.category === 'А' && Math.abs(d.duration) > DurationTheresholds.abonent);
    const operatorPart = mappedData
      .filter(d => d.category === 'О' && Math.abs(d.duration) > DurationTheresholds.operator);
    const pausesPart = mappedData
      .filter(d => d.category === 'Па' && Math.abs(d.duration) > DurationTheresholds.pauses);
    const interruptionsPart = mappedData
      .filter(d => d.category === 'Пе' && Math.abs(d.duration) > DurationTheresholds.interruptions);

    const orderedArray = [...abonentPart, ...operatorPart, ...pausesPart, ...interruptionsPart];
    chart.data = orderedArray;

    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.fontSize = 12;

    categoryAxis.renderer.labels.template.adapter.add('text', (label, target) => {
      if (!target.dataItem) return label;
      const v = target.dataItem.properties.category;
      if (v === 'А' || v === 'О') return label;
    });

    const timeAxis = chart.xAxes.push(new am4charts.ValueAxis());
    timeAxis.renderer.minGridDistance = 30;
    timeAxis.renderer.tooltipLocation = 0;
    timeAxis.renderer.fontSize = 12;
    timeAxis.title.align = 'right';
    timeAxis.title.text = 'сек';
    timeAxis.title.fontSize = 14;

    const series = chart.series.push(new am4charts.ColumnSeries());
    series.columns.template.height = am4core.percent(70);

    series.dataFields.openValueX = 'start';
    series.dataFields.valueX = 'end';
    series.dataFields.categoryY = 'category';
    series.columns.template.propertyFields.fill = 'color'; // get color from data
    series.columns.template.propertyFields.stroke = 'color';
    series.columns.template.strokeOpacity = 1;
    series.columns.template.tooltipText = 'Длит: {duration}';
    series.columns.template.tooltipPosition = 'pointer';

    chart.marginTop = 0;
    chart.paddingTop = 5;
    chart.paddingLeft = 0;
    categoryAxis.renderer.labels.template.paddingLeft = 0;

    return () => chart.dispose();
  });

  return (
    <div ref={ref} className={className} data-cy="tempo-chart" data-call-id={callId}></div>
  );
};

type ChartData = {
  start: string
  end: string
  category: string
  color: am4core.Color
  duration: number
}[];

function mapDataToChart(data: ValidDialogAnalysis) {
  const d = data;
  const timeReg = /\d\d:(\d\d):(\d\d).(\d\d\d)/;

  const res: ChartData = [];

  const replacer = (_: string, m: string, s: string, ms: string) => {
    return String(Number(ms) / 1000 + Number(s) + Number(m) * 60);
  };

  for (let i = 0; ; i++) {
    const start = d.start[i];
    if (!start) break;
    const startInSec = start.replace(timeReg, replacer);

    const end = d.end[i];
    if (!end) break;
    const endInSec = end.replace(timeReg, replacer);

    const speaker = d.speaker;
    if (!speaker) break;
    const isOperator = speaker[i] === 'operator';

    res.push({
      start: Number(startInSec).toFixed(3),
      category: isOperator ? 'О' : 'А',
      color: am4core.color(isOperator ? '#0000ff' : '#00acef'),
      end: Number(endInSec).toFixed(3),
      duration: Number(endInSec) - Number(startInSec),
    });

    const interrupt = d.interrupt;

    if (interrupt[i] > 0 && i > 0) {
      const isRealInterrupt = speaker[i] !== d.speaker[i - 1];
      if (isRealInterrupt) {
        const duration = interrupt[i];
        const startInterrupt = startInSec;
        const endInterrupt = d.end[i - 1].replace(timeReg, replacer);
        res.push({
          start: startInterrupt,
          category: 'Пе',
          color: am4core.color('#ff0000'),
          end: endInterrupt,
          duration: duration
        });
      }
    }

    if (interrupt[i] <= 0 && i > 0) {
      const duration = interrupt[i];
      const startPause = d.end[i - 1].replace(timeReg, replacer);
      const endPause = startInSec;

      res.push({
        start: startPause,
        category: 'Па',
        color: am4core.color('#aeaeae'),
        end: endPause,
        duration: -duration,
      });
    }
  }
  return res;
}

const MemoizedTempoChart = React.memo(TempoChart,
  (prevProps, nextProps) => prevProps.callId === nextProps.callId
);

export { MemoizedTempoChart as TempoChart };
