import React, { Fragment, useContext, useEffect, useReducer, useRef } from 'react';
import { GetStatsResult } from '@api/baseSubset';
import { cn, formatDate } from '@utils';
import { FiltersCtx, AuthCtx } from '@contexts';

import { Button, DatePicker } from '@components';
import { DynamicsByDatesChart, SourceRow, SourceRowProps } from './components';

import { initialState, reducer } from './reducer';

import s from './MonitorBar.module.css';

const sourceRowsMock: SourceRowProps[] = [
  {
    source: 'Нейросеть', totalErrors: 13, avgErrorsValue: 15, errors: [
      { desc: 'один канал', num: 4 },
      { desc: 'ссылка', num: 8 },
      { desc: 'сборка', num: 1 },
    ],
    equalityKey: '0'
  },
  {
    source: 'База данных', totalErrors: 10, avgErrorsValue: 6, errors: [
      { desc: 'Ошибка БД-1', num: 3 },
      { desc: 'Ошибка БД-2', num: 7 },
    ],
    equalityKey: '0'
  },
  {
    source: 'Сервер', totalErrors: 9, avgErrorsValue: 12, errors: [
      { desc: 'сервер', num: 8 },
      { desc: 'другое', num: 1 },
    ],
    equalityKey: '0'
  },
  {
    source: 'Дашборд', totalErrors: 0, avgErrorsValue: 2, errors: [
      { desc: 'нет диалогов', num: 0 },
      { desc: 'другое', num: 0 },
    ],
    equalityKey: '0'
  },
];

type Props = {
  className?: string
  token: string
};

export function MonitorBar(x: Props) {
  const filtersCtx = useContext(FiltersCtx);
  const authCtx = useContext(AuthCtx);

  const { token } = authCtx;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { stats, isLoading } = state;

  const datePickerLauncher1 = useRef(null);
  const datePickerLauncher2 = useRef(null);

  useEffect(() => dispatch({ type: 'initDispatch', dispatch }), []);

  const {
    operator, project, status,
    selectedDates: selectedDate, filterId, tableId, invalidDeals
  } = filtersCtx;

  const { datesRange, chartsEqualityKey } = state;
  const [dateFrom, dateTo] = datesRange;

  const validateBtnIsEnabled = (operator || project || status
    || selectedDate || filterId || tableId) && !isLoading;

  const saveBtnIsEnabled = invalidDeals && invalidDeals.length > 0 && !isLoading;
  return (
    <div className={cn(s.wrapper, x.className)}>
      <div className={cn(s.row, s.datesRow)}>
        <span className={s.rowTitle}>Ошибки</span>
        <div className={s.datesContainer}>

          <span className={s.pretext}>c</span>
          <span ref={datePickerLauncher1}
                className={cn(s.datePicker, s.dateInput)}>
            { formatDate(dateFrom) }</span>
          <span className={s.pretext}>по</span>
          <span ref={datePickerLauncher2}
                className={cn(s.datePicker, s.dateInput, !dateTo && s.dateInputEmpty)}>
            { dateTo ? formatDate(dateTo) : 'дд.мм.гггг' }</span>

          <div className={s.datePickerWrapper}>
            <DatePicker launchers={[datePickerLauncher1, datePickerLauncher2]}
                        selectedDates={datesRange}
                        range={true}
                        disabled={isLoading}
                        onSelect={dates => dispatch({ type: 'selectDates', dates })} />
          </div>

          <Button className={s.resetRange}
                  disabled={isLoading}
                  onClick={() => dispatch({ type: 'resetDatesRange' })}>Сбросить</Button>
          <Button className={s.request}
                  disabled={isLoading}
                  onClick={() => dispatch({ type: 'loadStats', token: x.token })}>Запросить</Button>
        </div>
      </div>
      <div className={s.statsChartsWrapper}>
        <div className={s.stats}>
          <div className={s.statsCaption}>
            <h4>Общая cтатистика:</h4>
            { !stats && <span>н/д</span> }
          </div>
          { stats && (
            <div className={s.statsGrid}>
              <h4 className={s.totalTitle}>Всего</h4>
              <h4 className={s.averageTitle}>Ср.</h4>
              { mapStatsToChartsInfo(stats).map((chart, index) => (
                <Fragment key={index}>
                  { chart.entries.map(entry => (
                    <Fragment key={entry.title}>
                      <span className={s.statsTitle}>{ entry.title }</span>
                      <span className={s.total}>{ entry.total }</span>
                      <span className={s.average}>{ entry.avg }</span>
                    </Fragment>
                  )) }
                  <DynamicsByDatesChart className={cn(s.chart, chart.className)}
                                        colors={chart.colors}
                                        data={chart.data}
                                        hasLegend={chart.hasLegend}
                                        fieldNameInTooltip={chart.fieldNameInTooltip}
                                        equalityKey={chartsEqualityKey} />
                </Fragment>
              )) }
            </div>
          ) }
        </div>

        { stats && (
          <div className={s.errorChartsWrapper}>
            <h4 className={s.chartsCaption}>Ошибки и их источники:</h4>
            <div className={s.rowsWrapper}>
              <h4 className={s.totalTitle}>Всего</h4>
              <h4 className={s.averageTitle}>Ср.</h4>
              { sourceRowsMock.map((i, index) => (
                <SourceRow key={index}
                           avgErrorsValue={i.avgErrorsValue}
                           totalErrors={i.totalErrors}
                           errors={i.errors}
                           equalityKey={chartsEqualityKey}
                           source={i.source} />
              )) }
              <Button className={s.reprocessBtn}>Обработать повторно</Button>
              <span className={s.reprocessExplanation}>(звонки за выбранный период)</span>
            </div>
          </div>
        ) }

        <div className={s.invalidCalls}>
          <p className={s.invalidCallsTitle}>
            Невалидные звонки, шт.:
            <span className={s.invalidCallsValue}>
              { !invalidDeals && 'н/д' }
              { invalidDeals && `${invalidDeals.length}` }
            </span>
          </p>
          <div className={s.controls}>
            <Button className={s.validateBtn}
                    disabled={!validateBtnIsEnabled}
                    onClick={() => filtersCtx.asyncDispatch(filtersCtx, { type: 'validateCalls', token })}>Валидация</Button>
            <Button className={s.saveBtn} disabled={!saveBtnIsEnabled}>Сохранить IDs</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

type RequiredData = {
  data: React.ComponentProps<typeof DynamicsByDatesChart>['data']
  entries: {
    title: string
    total: number | string
    avg: number | string
  }[]
  className: string
  colors?: string[]
  fieldNameInTooltip?: boolean
  hasLegend?: boolean
}[];

function mapStatsToChartsInfo(stats: GetStatsResult): RequiredData {
  type Data = RequiredData[number]['data'];

  const recognition: Data = [];
  const totalTime: Data = [];
  const avgTime: Data = [];

  let recognitionTotal = 0;
  let successTotal = 0;
  let errorsTotal = 0;
  let noFilesTotal = 0;
  let avgTimeTotal = 0;
  let summaryTimeTotal = 0;

  const statsArray = stats.work_stats;

  for (let i = 0; i < statsArray.length; i++) {
    const value = statsArray[i];
    recognition.push({
      'Успешно': value.suc_dialogs,
      'Ошибки': value.error_dialogs,
      'Нет файлов': value.no_file_dialogs,
      date: value.dt
    });

    recognitionTotal += value.work_count;
    successTotal += value.suc_dialogs;
    errorsTotal += value.error_dialogs;
    noFilesTotal += value.no_file_dialogs;

    totalTime.push({ 'Общее время': value.sum_tm_recogs, date: value.dt });
    summaryTimeTotal += value.sum_tm_recogs;

    avgTime.push({ 'Среднее время': value.avg_tm_work, date: value.dt });
    avgTimeTotal += value.avg_tm_work;
  }

  return [
    {
      data: recognition,
      entries: [
        {
          title: 'В базе',
          total: 'н/д',
          avg: 'н/д'
        },
        {
          title: 'Отправлено',
          total: recognitionTotal,
          avg: Math.round(recognitionTotal / statsArray.length)
        },
        {
          title: 'Успешно',
          total: successTotal,
          avg: Math.round(successTotal / statsArray.length)
        },
        {
          title: 'Ошибок',
          total: errorsTotal,
          avg: Math.round(errorsTotal / statsArray.length)
        },
        {
          title: 'Диалоги без файлов',
          total: noFilesTotal,
          avg: Math.round(noFilesTotal / statsArray.length)
        },
      ],
      className: s.recognitionChart,
      colors: ['darkgrey', 'red', 'purple'],
      fieldNameInTooltip: true,
      hasLegend: true
    },
    {
      data: totalTime,
      entries: [{
        title: 'Общая длит. распознавания',
        total: summaryTimeTotal,
        avg: Math.round(summaryTimeTotal / statsArray.length)
      }],
      className: s.totalTimeChart,
      colors: ['darkgrey'],
    },
    {
      data: avgTime,
      entries: [{
        title: 'Ср. время распознавания',
        total: avgTimeTotal,
        avg: Math.round(avgTimeTotal / statsArray.length)
      }],
      className: s.avgTimeChart,
      colors: ['darkgrey'],
    },
  ];
}
