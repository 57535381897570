import { DropdownFilter, ListItem } from '@components';
import { FiltersCtx, AuthCtx, CallInfoCtx } from '@contexts';
import React, { useContext } from 'react';

type Props = {
  className?: string
  shouldHighlight: boolean
};
// FIXME перенести в components для BasicSelection

export function DealSelection(x: Props) {
  const filtersCtx = useContext(FiltersCtx);
  const { token } = useContext(AuthCtx);
  const callCtx = useContext(CallInfoCtx);
  const { deal, dealList } = filtersCtx;

  const isLoading = filtersCtx.isLoading || callCtx.isLoading;
  const selectCallIdIsEnabled = !isLoading && dealList.length;

  const handleSelect = async (item: ListItem) => {
    const callInfo = await callCtx.asyncDispatch(callCtx, { type: 'getDealInfo', id: item.id, token });
    if (!callInfo) return;
    filtersCtx.dispatch({ type: 'selectDeal', deal: callInfo });
  };

  return (
    <DropdownFilter className={x.className}
                    headerPlaceholder="id звонка"
                    onSelect={handleSelect}
                    selectedItem={deal}
                    shouldHighlight={x.shouldHighlight}
                    dataCy="call-id-dropdown"
                    disabled={!selectCallIdIsEnabled}
                    enableCopy={true}
                    list={dealList} />
  );
};
