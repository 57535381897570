import * as api from '@api/baseSubset';
import { encodeList } from '@utils';
import { Dispatch, StateSlice } from '../reducer';

export async function getInitialLists(token: string, dispatch: Dispatch, isDemo: boolean) {
  const resetLoader = () => dispatch({ type: 'updateStateAsync', stateSlice: { isLoading: false } });

  try {
    const res = await api.getInitialLists(token);
    if (!api.isGetInitialListsResult(res)) return resetLoader();

    const operators = res.operators.map(item => ({ id: item.id, text: item.operator }));
    let operatorsDictionary;
    if (isDemo) operatorsDictionary = encodeList(operators);

    const projects = res.projects.map(item => ({ id: item, text: item }));
    const statuses = res.statuses.map(item => ({ id: item, text: item }));
    const availableDates = res.dates.map(date => new Date(date.replace(/-/g, '/')));
    const filterIds = res.filter_id.sort((a, b) => (b - a)).map(item => ({ id: String(item), text: String(item) }));
    const filterInfos = res.filters;

    const stateSlice: StateSlice = {
      isLoading: false,
      operators, projects, statuses, availableDates, filterIds,
      initialLists: {
        operators, projects, statuses, availableDates,
        filterIds, operatorsDictionary,
      },
      filterInfos,
    };

    dispatch({ type: 'updateStateAsync', stateSlice });

  } catch(e) {
    console.log(e);
    resetLoader();
  }
};
