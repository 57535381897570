import { z } from 'zod';
import { configMock } from '@mocks/configMock';
import { delay } from '@utils';
import { VERSION } from '../../constants';
import { parseAndLogIfError } from '../parseAndLogIfError';

const item = 'config';

export async function getConfig(): Promise<unknown> {
  await delay(500);

  const versionItem = localStorage.getItem('version');
  if (versionItem !== VERSION) {
    localStorage.setItem('version', VERSION);
    localStorage.setItem(item, JSON.stringify(configMock));
    return configMock;
  }

  const result = localStorage.getItem(item);

  if (!result) {
    localStorage.setItem(item, JSON.stringify(configMock));
    return configMock;
  }

  const parsedResult = JSON.parse(result);
  return parsedResult;
}

export type GetConfigResult = z.infer<typeof GetConfigResult>;
export function isGetConfigResult(o: unknown): o is GetConfigResult {
  return parseAndLogIfError(GetConfigResult, o);
};

const Bookmarks = z.union([
  z.literal('script'),
  z.literal('tags'),
  z.literal('filter'),
  z.literal('emotions'),
  z.literal('emotions_star'),
  z.literal('analysis'),
  z.literal('monitor'),
  z.literal('words'),
  z.literal('leads'),
  z.literal('voice'),
  z.literal('masks'),
  z.literal('dictionaries'),
  z.literal('lexic'),
  z.literal('config')
]);

const Filters = z.union([
  z.literal('search_by_call_id'),
  z.literal('search_by_table_id'),
  z.literal('filter_id'),
  z.literal('project'),
  z.literal('operator'),
  z.literal('status'),
  z.literal('substatus'),
  z.literal('search_by_words'),
  z.literal('dialogs_markup'),
  z.literal('audio_upload'),
  z.literal('search_by_dicts'),
]);

const Panels = z.union([z.literal('sidebar2'), z.literal('sidebar3')]);

const Neuro = z.literal('show');

const GetConfigResult = z.record(z.object({
  bookmarks: Bookmarks.array(),
  filters: Filters.array(),
  panels: Panels.array(),
  neuro: Neuro.array(),
}).optional());
