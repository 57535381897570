import { GetConfigResult } from '@api/config';
import { IsSameType } from '@utils';

export type Panels = NonNullable<GetConfigResult[string]>['panels'][number];
export type Filters = NonNullable<GetConfigResult[string]>['filters'][number];
export type Bookmarks = NonNullable<GetConfigResult[string]>['bookmarks'][number];
export type Neuro = NonNullable<GetConfigResult[string]>['neuro'][number];

// имитация исчерпывающей проверки
// однако, следует внимательно проверить на наличие повторений
export const getFilters = (): readonly Filters[] => {
  const flist = ['dialogs_markup', 'filter_id', 'operator',
    'project', 'search_by_call_id', 'search_by_table_id', 'search_by_words',
    'status', 'substatus', 'audio_upload', 'search_by_dicts'] as const;
    type A = IsSameType<typeof flist[number], Filters>;
    const remnant: readonly A[] = flist;
    return remnant;
};

type FiltersRus =
  | 'Поиск по ID звонка'
  | 'Поиск по ID таблицы'
  | 'ID фильтра'
  | 'Проект'
  | 'Оператор'
  | 'Статус'
  | 'Подстатус'
  | 'Поиск слов'
  | 'Разметка диалогов'
  | 'Загрузить запись'
  | 'Поиск по словарям'
;

export const filtersDict: Record<Filters, FiltersRus> = {
  search_by_call_id: 'Поиск по ID звонка',
  search_by_table_id: 'Поиск по ID таблицы',
  filter_id: 'ID фильтра',
  project: 'Проект',
  operator: 'Оператор',
  status: 'Статус',
  substatus: 'Подстатус',
  search_by_words: 'Поиск слов',
  dialogs_markup: 'Разметка диалогов',
  audio_upload: 'Загрузить запись',
  search_by_dicts: 'Поиск по словарям',
};

export const filtersList = getFilters().map(i => ({ id: i, text: filtersDict[i] }));

export const getPanels = (): readonly Panels[] => {
  const plist = ['sidebar2', 'sidebar3'] as const;

    type A = IsSameType<typeof plist[number], Panels>;
    const remnant: readonly A[] = plist;
    return remnant;
};

type PanelsRus = | 'Выборка' | 'Настройка и разметка';

const panelsDict: Record<Panels, PanelsRus> = {
  sidebar2: 'Выборка',
  sidebar3: 'Настройка и разметка',
};

export const panelsList = getPanels().map(i => ({ id: i, text: panelsDict[i] }));

export const getBookmarks = (): readonly Bookmarks[] => {
  const blist = ['analysis', 'config', 'dictionaries',
    'emotions', 'emotions_star', 'filter', 'leads', 'lexic', 'masks',
    'monitor', 'script', 'tags', 'voice', 'words'] as const;

    type A = IsSameType<typeof blist[number], Bookmarks>;
    const remnant: readonly A[] = blist;
    return remnant;
};

type BookmarksRus =
  | 'Теги'
  | 'Эмоции'
  | 'Лиды'
  | 'Скрипт'
  | 'Эмоции*'
  | 'Слова'
  | 'Голос'
  | 'Лексика'
  | 'Фильтр'
  | 'Монитор'
  | 'Анализ'
  | 'Конфигурация'
  | 'Маски'
  | 'Словари';

export const bookmarksDict: Record<Bookmarks, BookmarksRus> = {
  tags: 'Теги',
  emotions: 'Эмоции',
  leads: 'Лиды',
  script: 'Скрипт',
  emotions_star: 'Эмоции*',
  words: 'Слова',
  voice: 'Голос',
  lexic: 'Лексика',
  filter: 'Фильтр',
  monitor: 'Монитор',
  analysis: 'Анализ',
  config: 'Конфигурация',
  masks: 'Маски',
  dictionaries: 'Словари',
};

export const bookmarkList = getBookmarks().map(i => ({ id: i, text: bookmarksDict[i] }));

type NeuroRus = 'Показать оценку';
export const neuroDict: Record<Neuro, NeuroRus> = { show: 'Показать оценку', };

export const getNeuro = (): readonly Neuro[] => {
  const nlist = ['show'] as const;

    type A = IsSameType<typeof nlist[number], Neuro>;
    const remnant: readonly A[] = nlist;
    return remnant;
};

export const neuroList = getNeuro().map(i => ({ id: i, text: neuroDict[i] }));
