import { MasksCtx, FiltersCtx } from '@contexts';
import { ListItem } from '@components';
import { Masks } from '@contexts/masks/reducer';
import * as h from './helpers';

export const initialState = {
  project: undefined as ListItem | undefined,
  subsetByMark: 'all' as MarkSubset,
  subsetByTag: 'no-matter' as TagSubset,
  tagsWereChanged: false,
  showAllTags: false,
  tag: undefined as undefined | ListItem,
  tagsFilterList: [] as string[],
  mask: undefined as undefined | number[],
  projectList: undefined as undefined | ListItem[],
  initialProjectsList: undefined as undefined | ListItem[], // ссылка на значение контекста
  tagNames: undefined as undefined | string[], // ссылка на значение контекста
  masks: undefined as undefined | Masks // ссылка на значение контекста
};

export type MarkSubset = 'marked' | 'unmarked' | 'all';
export type TagSubset = 'with-tag' | 'no-tag' | 'no-matter';
export type Dispatch = React.Dispatch<Action>;

type ContextParameter<D> = D extends React.Context< infer T> ? T : never;
export type MasksCtx = ContextParameter<typeof MasksCtx>;
export type FiltersCtx = ContextParameter<typeof FiltersCtx>;

type Action =
  | { type: 'setSubsetByMark'; subset: MarkSubset; masks: Masks }
  | { type: 'setSubsetByTag'; subset: TagSubset; masks: Masks }
  | { type: 'setProject'; project: ListItem; masksCtx: MasksCtx }
  | { type: 'changeMarkup'; index: number; value: number; masksCtx: MasksCtx }
  | { type: 'tagFilterSelect'; tag: ListItem }
  | { type: 'setShowAllTags' }
  | { type: 'addMask'; name: string; masksCtx: MasksCtx }
  | { type: 'handleCtxChange'; masksCtx: MasksCtx; filtersCtx: FiltersCtx }
  | { type: 'removeTagFromList'; masks: Masks }
  | { type: 'addTagToList'; masks: Masks }
;
export type State = typeof initialState;

export function reducer(state: State, action: Action): State {
  const { type } = action;

  if (type === 'setSubsetByMark') {
    const { subset } = action;
    return h.applyFilters({ ...state, subsetByMark: subset });

  } else if (type === 'setSubsetByTag') {
    const { subset } = action;
    return h.applyFilters({ ...state, subsetByTag: subset });

  } else if (type === 'changeMarkup') {
    const { index, value, masksCtx } = action;
    return h.changeMarkup({ state, index, value, masksCtx });

  } else if (type === 'setProject') {
    const { project, masksCtx } = action;
    return h.setProject({ project, state, masksCtx });

  } else if (type === 'setShowAllTags') {
    return { ...state, showAllTags: !state.showAllTags };

  } else if (type === 'tagFilterSelect') {
    const { tag } = action;
    return { ...state, tag };

  } else if (type === 'handleCtxChange') {
    const { masksCtx, filtersCtx } = action;
    return h.handleCtxChange({ state, masksCtx, filtersCtx });

  } else if (type === 'addMask') {
    const { name, masksCtx } = action;
    return h.addMask({ state, name, masksCtx });

  } else if (type === 'addTagToList') {
    const { tagsFilterList, tag } = state;
    if (!tag) return state;

    if(tagsFilterList.includes(tag.id)) return state;

    const newTagsFilterList = [...tagsFilterList];
    newTagsFilterList.push(tag.id);
    return h.applyFilters({ ...state, tagsFilterList: newTagsFilterList });

  } else if (type === 'removeTagFromList') {
    const { tagsFilterList, tag } = state;
    if (!tag) return state;

    const newTagsFilterList = tagsFilterList.filter(t => t !== tag.id);
    if (newTagsFilterList.length === tagsFilterList.length) return state;

    return h.applyFilters({ ...state, tagsFilterList: newTagsFilterList });
  }

  const remnant: never = type;
  throw new Error(`unhandled action.type = ${remnant} in MasksBar reducer`);
}
