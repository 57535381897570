import React from 'react';
import { cn } from '@utils/classNames';
import s from './MessageBubble.module.css';

type Props = {
  className?: string
  children: React.ReactNode
  isOutgoing?: boolean
};

export function MessageBubble({ isOutgoing = false, children, className }: Props) {
  return (
    <div className={cn(s.message, isOutgoing && s.messageOut, className)}>
      { children }</div>
  );
};
