import * as api from '@api/baseSubset';
import { State } from '../reducer';
import { getDealInfo } from '.';

type Args = {
  idChain: string
  subs: api.TagsAnalysis
  token: string
  state: State
  dealId: string
  callId: string
};

export async function saveSubstatus({ idChain, subs, token, state, dealId, callId }: Args) {
  try {
    await api.saveSubstatus({ idChain, subs, token, callId });
    return await getDealInfo({ id: dealId, state, token, force: true });
  } catch(e) {
    console.log(e);
    state.dispatch({ type: 'updateStateAsync', stateSlice: { isLoading: false } });
  }
};
