import React from 'react';
import { User } from '@assets/icons';
import { cn } from '@utils';

import s from './Field.module.css';

type Props = {
  placeholder: string
  icon?: typeof User
  type: 'text' | 'password' | 'number'
  className?: string
  value: string
  invalid?: boolean
  errorMessage: string
  autoComplite?: string
  disabled?: boolean
  dataCy?: string

  onIconClick?: () => void
  onChange(v: string): void
  onBlur?: () => void
};

export function Field(x: Props) {
  const Icon = x.icon ?? User;
  return (
    <fieldset className={cn(
      s.fieldset,
      x.className,
      x.invalid && s.error)}>
      <legend className={s.legend}>{ x.placeholder }</legend>
      <label className={s.label}>
        <input type={x.type} placeholder={x.placeholder} className={s.input}
               value={x.value} onChange={e => x.onChange(e.target.value)}
               onBlur={x.onBlur}
               disabled={x.disabled}
               data-cy={x.dataCy}
               autoComplete={x.autoComplite} />
        <Icon className={s.icon} onClick={x.onIconClick} />
      </label>
      { x.invalid && <span className={s.errorMessage}>{ x.errorMessage }</span> }
    </fieldset>
  );
};
