import React, { Fragment } from 'react';
import { randomInteger } from '@utils';
import { DynamicsByDatesChart } from '../../components';
import s from '../../MonitorBar.module.css';

const errorsMockChartData = [
  { field1: randomInteger(0, 50), date: new Date('2021-01-01') },
  { field1: randomInteger(0, 50), date: '2021-01-02' },
  { field1: randomInteger(0, 50), date: '2021-01-03' },
  { field1: randomInteger(0, 50), date: '2021-01-04' },
  { field1: randomInteger(0, 50), date: '2021-01-05' },
  { field1: randomInteger(0, 50), date: '2021-01-06' },
  { field1: randomInteger(0, 50), date: '2021-01-07' },
  { field1: randomInteger(0, 50), date: '2021-01-08' },
  { field1: randomInteger(0, 50), date: '2021-01-09' },
  { field1: randomInteger(0, 50), date: '2021-01-10' },
  { field1: randomInteger(0, 50), date: '2021-01-11' },
  { field1: randomInteger(0, 50), date: '2021-01-12' },
  { field1: randomInteger(0, 50), date: '2021-01-13' },
];

export type SourceRowProps = {
  source: 'Нейросеть' | 'База данных' | 'Сервер' | 'Дашборд'
  totalErrors: number
  avgErrorsValue: number
  errors: {
    desc: string
    num: number
  }[]
  equalityKey: unknown
};

export function SourceRow(x: SourceRowProps) {
  return (
    <>
      <span className={s.errorSource}>{ x.source } </span>
      <span className={s.errorsNum}>{ x.totalErrors }</span>
      <span className={s.errorsAvg}>{ x.avgErrorsValue }</span>
      <DynamicsByDatesChart data={errorsMockChartData} className={s.chart}
                            colors={['darkgrey']}
                            equalityKey={x.equalityKey} />
      <div className={s.errorDescWrapper}>
        { x.errors.map((e, index) => (
          <Fragment key={index}>
            <span className={s.errorDesc}>{ e.desc }</span>
            <span className={s.errorDescNum}>{ e.num }</span>
          </Fragment>
        )) }
      </div>
    </>
  );
}
