import { isSameDate, formatDate } from '@utils';
import { getDeals } from '@api/baseSubset';
import { LabelValue, State } from '../reducer';

type MappedValue = 1 | 0 | undefined;

const map: Record<LabelValue, MappedValue> = {
  'no-matter': undefined,
  delta: undefined,
  yes: 1,
  no: 0,
};

type Args = {
  state: State
  token: string
  withAnalysis?: boolean
};

export async function getInfoByFilters(x: Args) {
  const { state, token, withAnalysis = false } = x;
  const {
    operator, project, status, selectedDates,
    filterId, tableId, wordsToFind, labeledTag,
    labeledEmo, labeledEmoTemp, labeledLead
  } = state;

  const dateFrom = selectedDates?.[0];
  const dateTo = selectedDates?.[1];
  const hasDateRange = dateFrom && dateTo && !isSameDate(dateFrom, dateTo);

  const dealInfos = await getDeals(
    {
      idtable: tableId,
      filter_id: filterId?.id,
      project: project?.id,
      oper: operator?.id,
      result: status?.text,
      txt_filter: wordsToFind,
      date: dateFrom && formatDate(dateFrom, 'yyyy-mm-dd'),
      date_to: (hasDateRange && formatDate(dateTo, 'yyyy-mm-dd')) || undefined,
      exclude: withAnalysis ? undefined : ['analisys'],
      tags: map[labeledTag],
      emotions: map[labeledEmo],
      emotions_star: map[labeledEmoTemp],
      token,
      tag_d: labeledTag === 'delta' ? 1 : undefined,
      subs: map[labeledLead],
    }
  );

  return dealInfos as unknown;
};
