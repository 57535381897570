import * as api from '@api/dictionaries';
import { DEFAULT_COLOR } from '@api/dictionaries/constants';
import { StateSlice, State } from '../reducer';

type Args = {
  state: State
  name: string
  token: string
};

export async function createDictionary(x: Args) {
  const { name, token, state } = x;
  const { dispatch, dictInfos } = state;

  const result = await api.createDictionary({ name, token });
  if (!api.isCreateDictionaryResult(result)) {
    return dispatch({ type: 'updateStateAsync', stateSlice: { isLoading: false } });
  }

  const { id, dict_name: dictName } = result;
  const listItem = { id: String(id), text: dictName };

  const stateSlice: StateSlice = {
    isLoading: false,
    dictionaries: state.dictionaries && [...state.dictionaries, listItem],
    dictInfos: {
      ...dictInfos, [id]: {
        color: DEFAULT_COLOR,
        words: [],
      }
    },
  };

  dispatch({ type: 'updateStateAsync', stateSlice });
  return result;
};
