import { z } from 'zod';
import { callInfo as demoCallInfo } from '@mocks/demoCall';
import { parseAndLogIfError } from '../parseAndLogIfError';
import { DealsObj } from './commonTypes';

type Args = {
  project?: string
  oper?: string // 'oп.Дьячук (Татьяна)
  date?: string // 2021-10-28 12:24:47.093
  date_to?: string
  result?: string
  deal_id?: string
  filter_id?: string
  sort?: 'ASC' | 'DESC'
  exclude?: string[]
  idtable?: string
  txt_filter?: string
  tags?: 0 | 1
  emotions?: 0 | 1
  emotions_star?: 0 | 1
  tag_d?: 0 | 1
  subs?: 0 | 1
  token: string
};

type Args0 = { // FIXME!!!!!!!
  isDemo?: boolean
} & Args;

export type GetDealsResult = z.infer<typeof GetDealsResult>;
export function isGetDealsResult(o: unknown): o is GetDealsResult {
  return parseAndLogIfError(GetDealsResult, o);
};

export async function getDeals(x: Args0) {
  const { isDemo, deal_id: dealId } = x;
  if (isDemo || dealId?.startsWith('D')) { // FIXME это убожество, когда дадут настоящее API
    const item = 'demoAudioIds';
    const resultStr = localStorage.getItem(item);
    if (!resultStr) {
      localStorage.setItem(item, JSON.stringify([]));
      return [];
    }

    const ids = JSON.parse(resultStr) as string[];
    const result = ids.reduce((res, id) => {
      const callInfoCopy = { ...demoCallInfo };
      callInfoCopy.deal_id = id;
      res[id] = callInfoCopy;
      return res;
    }, {} as {[id: string]: unknown});

    return result;
  }

  return getDealsOrigin(x);
}

export async function getDealsOrigin(x: Args) { // FIXME удалить. Переделано для иммитации  загрузки демо звонков
  const url = 'https://dashboards.reffection.com/dashboards/api/get_filter_info.php';
  const nonEmptyEntries = Object.entries(x)
    .filter(([key, value]) => (value || value === 0));

  const sendingObject = { ...Object.fromEntries(nonEmptyEntries), };

  const formData = JSON.stringify(sendingObject);

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', },
      body: formData,
    });

    const deals: unknown = await response.json();
    console.log('deals from getDeals', deals);
    return deals;

  } catch (e) {
    console.log(e);
    const deals: unknown = [];
    return deals;
  }
};

const GetDealsResult = DealsObj;
