import React from 'react';
import { Field } from '../Field';

type Props = {
  value: string
  onChange(v: string): void
  className?: string
  invalid?: boolean
};

export function LoginField(x: Props) {
  return (
    <Field className={x.className} type="text" placeholder="Логин"
           errorMessage="Неверный логин"
           autoComplite="username"
           dataCy='login'
           invalid={x.invalid} value={x.value} onChange={x.onChange} />
  );
};
