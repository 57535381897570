import React, { useState } from 'react';
import { cn } from '@utils';
import { BasicSelection, ChartsBar, ChartsKind } from './components';

import s from './Sidebar1.module.css';

type Props = {
  className?: string
  onExit: () => void
};

export function Sidebar1(x: Props) {
  const [chart, setChart] = useState<ChartsKind>();

  return (
    <div className={cn(s.sidebar1, x.className)}>
      { !chart && <BasicSelection className={s.basicSelection} onSelectChart={setChart} onExit={x.onExit} /> }
      { chart && <ChartsBar className={s.charts} chart={chart} onSelectChart={setChart} /> }
    </div>
  );
};
