import { PureWrapper } from '@components';
import React, { createContext, useEffect, useReducer } from 'react';
import { ConfigCtxReducer, initialState } from './reducer';

export const ConfigCtx = createContext(initialState);
const { Provider } = ConfigCtx;

type Props = {
  children: React.ReactNode
};

export function ConfigCtxProvider(x: Props) {
  const [state, dispatch] = useReducer(ConfigCtxReducer, initialState);
  useEffect(() => dispatch({ type: 'initDispatch', dispatch }), []);

  useEffect(() => {
    if(typeof state.dispatch !== 'function') return;
    state.asyncDispatch(state, { type: 'getConfig' });
  }, [state.dispatch]);

  if (typeof state.dispatch !== 'function') return null;
  if (!Object.keys(state.configSaved).length) return null;

  return (
    <Provider value={{ ...state, dispatch }}>
      <PureWrapper>
        { x.children }
      </PureWrapper>
    </Provider>
  );
};
