import { ActionByType } from '@utils';
import { StateSlice, State, Action } from '../reducer';

type Args = {
  state: State
  action: ActionByType<Action, 'searchByCallId' | 'selectDeal'>
};

export function handleDealSelect(x: Args) {
  const { state, action } = x;
  const { deal } = action;

  const { customer, date, operator, result, deal_id: dealId } = deal;
  const substatuses = deal.substatuses?.map(s => ({ id: s, text: s })) ?? [];

  const operatorListItem = { id: operator, text: operator };
  const projectListItem = { id: customer, text: customer };
  const dealListItem = { id: String(dealId), text: String(dealId) };
  const statusListItem = { id: result, text: result };
  const substatusListItem = substatuses?.length
    ? substatuses[0]
    : undefined;

  const dateNormalized = date.replace(/-/g, '/');

  const stateSlice: StateSlice = {
    isLoading: false,
    selectedDates: [new Date(dateNormalized), new Date(dateNormalized)],
    operator: operatorListItem,
    operators: [operatorListItem],
    project: projectListItem,
    projects: [projectListItem],
    deal: dealListItem,
    status: statusListItem,
    statuses: [statusListItem],
    substatus: substatusListItem,
    substatuses,
    historyCall: undefined,
  };

  if (action.type === 'searchByCallId') {
    stateSlice.dealList = [dealListItem];
    stateSlice.filterId = undefined;
    stateSlice.filterIds = [];
  }

  return { ...state, ...stateSlice };
}
