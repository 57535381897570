import React, { useContext } from 'react';
import { cn } from '@utils';
import { isEmptyObj } from '@type-guards';
import { FiltersCtx, AuthCtx, CallInfoCtx } from '@contexts';
import { Button, DropdownFilter, ListItem } from '@components';
import { DealSelection, CommonInfo, } from '../../components';
import { DialogChart, SpeechChart, TempoChart, } from './components';

import s from './ChartsBar.module.css';

export type ChartsKind = 'speech' | 'text' | 'emotions';

type Props = {
  className?: string
  chart: ChartsKind
  onSelectChart: (chart: ChartsKind | undefined) => void
};

export function ChartsBar(x: Props) {
  const { chart, onSelectChart } = x;
  const filtersCtx = useContext(FiltersCtx);
  const { accessType, token } = useContext(AuthCtx);
  const callCtx = useContext(CallInfoCtx);

  const {
    operator, project, deal, status,
    savedDeals, historyCall, wordsToFind,
  } = filtersCtx;
  const { dealDatas, callHistoryState, historyCalls } = callCtx;

  if (!dealDatas) return null;
  if (!deal) return null;

  const callInfo = (historyCall && dealDatas[historyCall.id]) || dealDatas[deal.id];
  if (!callInfo) return null;

  const historyItems = historyCalls.map(i => ({ id: String(i.deal_id), text: String(i.deal_id) }));

  const callToShow = callInfo.calls.find(c => !Array.isArray(c.analisys));
  const callId = callToShow?.call_id ?? deal.id;
  const audioSrc = callToShow?.url ?? '';
  const comment = callInfo.comment || 'Нет комментария';
  const dealIsSaved = Boolean(deal && savedDeals.includes(deal.id));
  const showSaveCheckbox = accessType === 'admin' || accessType === 'control_department';

  let callAnalisys = callToShow && callToShow.analisys?.dialogs;
  if (Array.isArray(callAnalisys) || isEmptyObj(callAnalisys)) {
    callAnalisys = undefined;
  }

  const handleResetClick = () => {
    onSelectChart(undefined);
    filtersCtx.dispatch({ type: 'reloadSelection' });
  };

  const handleSelectCallInHistory = (item: ListItem) => {
    const id = item.id;
    filtersCtx.dispatch({ type: 'updateStateAsync', stateSlice: { isLoading: false, historyCall: { id: id, text: id } } });
  };

  const handleHistoryBtnClick = async () => {
    const dealId = deal?.id;
    if (!dealId) return;
    await callCtx.asyncDispatch(callCtx, { type: 'getDealHistory', dealId, token, callInfo });
  };

  const handleSaveCallChange = () => {
    if (!deal) return;
    filtersCtx.dispatch({
      type: 'saveDeal', deal: {
        operator: operator?.text ?? '',
        id: deal.id,
        project: project?.text ?? '',
        status: status?.text ?? '',
        idTable: callInfo.idtable,
        script: callInfo.script,
        idChain: callInfo.idchain,
        infoComment: callInfo.info_comment,
        date: callInfo.dt,
      }
    });
  };
  const isLoading = callCtx.isLoading || filtersCtx.isLoading;

  return (
    <div className={cn(s.chartsBar, x.className)} data-cy="charts-bar" data-disabled={isLoading}>
      <CommonInfo onResetClick={handleResetClick} />

      <div className={s.callSelectors}>
        <DealSelection shouldHighlight={Boolean(deal && !historyCall)} />

        { callHistoryState !== 'not-empty' && (
          <Button className={s.historyBtn}
                  dataCy='history-btn'
                  disabled={callHistoryState === 'empty'}
                  onClick={handleHistoryBtnClick}>
            История
          </Button>
        ) }

        { callHistoryState === 'not-empty' && (
          <DropdownFilter className={s.dropdownHistory}
                          headerPlaceholder="ID"
                          dataCy='history-dropdown'
                          filterPlaceholder="Начните ввод..."
                          onSelect={handleSelectCallInHistory}
                          enableCopy={true}
                          shouldHighlight={Boolean(historyCall)}
                          selectedItem={historyCall}
                          list={historyItems} />
        ) }
      </div>

      <div className={s.buttons}>
        <Button type="button" pressed={chart === 'text'}
                onClick={() => onSelectChart('text')}
                className={s.stagesBtn}>Текст</Button>
        <Button type="button" pressed={chart === 'speech'}
                onClick={() => onSelectChart('speech')}
                className={s.stagesBtn}>Графики</Button>
        { callHistoryState === 'not-empty' && (
          <Button type="button"
                  onClick={() => filtersCtx.dispatch({ type: 'addHistoryCallsToSet', historyCallIds: historyItems.map(i => i.id) })}
                  className={s.stagesBtn}>Добавить</Button>
        ) }
      </div>

      { !callAnalisys && (
        <div className={s.unhandledTalk}>
          <p className={s.unhandledTalkDesc}>Разговор не обработан.</p>
          <Button disabled>Обработать</Button>
          <audio src={audioSrc} controls className={s.unhandledTalkAudioPlayer} />
        </div>
      ) }

      { callAnalisys && chart === 'text' && (
        <DialogChart data={callAnalisys} audioSrc={audioSrc}
                     comment={comment} className={s.dialogChart}
                     wordsToHighlight={wordsToFind ? [wordsToFind] : undefined}
                     status={status?.text ?? 'Статус неизвестен'} />
      ) }

      { callAnalisys && chart === 'speech' && (
        <div className={s.speechAnalysis}>
          <div className={s.statusWrapper}>
            { showSaveCheckbox && (
              <label className={s.saveDeal}>
                <input type="checkbox" checked={dealIsSaved}
                       onChange={handleSaveCallChange}></input>
                <span className={s.saveDealText}>Выбрать</span>
              </label>
            ) }
            <h5 className={s.status}> { status?.text ?? 'Статус неизвестен' } </h5>
          </div>
          <h5 className={s.comment}> { comment } </h5>
          <TempoChart className={s.tempoChart} data={callAnalisys} callId={callId} />
          <SpeechChart className={s.speechChart} data={callAnalisys} callId={callId} />
          <audio src={audioSrc} controls className={s.audioPlayer} />
        </div>
      ) }
    </div>
  );
};

