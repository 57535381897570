import * as api from '@api/baseSubset';
import { GetDealsResult } from '@api/baseSubset';
import { getArrayUniqueByKey } from '@utils';
import { State, StateSlice } from '../reducer';

type Args = {
  state: State
  token: string
};
export async function getDemoCalls(x: Args) {
  const { state, token } = x;
  const { dispatch } = state;
  const dealInfos = await api.getDemoCalls(token) as GetDealsResult;

  const dealInfosValues = Object.values(dealInfos);

  const operators = getArrayUniqueByKey(dealInfosValues, 'operator')
    .map(i => ({ id: i.operator, text: i.operator }));
  const projects = getArrayUniqueByKey(dealInfosValues, 'customer')
    .map(i => ({ id: i.customer, text: i.customer }));
  const statuses = getArrayUniqueByKey(dealInfosValues, 'result')
    .map(i => ({ id: i.result, text: i.result }));

  const substatusesSet = new Set<string>();
  dealInfosValues.forEach(d => {
    if (!d.substatuses) return;
    d.substatuses.forEach(s => substatusesSet.add(s));
  });

  const substatuses = [...substatusesSet].map(i => ({ id: i, text: i }));

  const dealDates = dealInfosValues.map(i => new Date(i.dt.replace(' ', 'T')));

  const dealList = dealInfosValues
    .map(i => ({ id: String(i.deal_id), text: String(i.deal_id) }));

  const stateSlice: StateSlice = {
    dealList, availableDates: dealDates,
    operators, projects, statuses,
    isLoading: false,
    substatuses,
  };

  if (dealDates.length === 1) stateSlice.selectedDates = [dealDates[0], dealDates[0]];
  if (operators.length === 1) stateSlice.operator = operators[0];
  if (statuses.length === 1) stateSlice.status = statuses[0];
  if (projects.length === 1) stateSlice.project = projects[0];

  if (dealList.length === 1) {
    const dealListItem = dealList[0];
    stateSlice.deal = dealListItem;
  } else if (dealList.length === 0) {
    stateSlice.deal = undefined;
  }

  dispatch({ type: 'updateStateAsync', stateSlice });
  return dealInfos;
}
