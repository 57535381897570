import { Masks } from '@contexts/masks/reducer';
import { State, TagSubset } from '../reducer';

export function applyFilters(state: State): State {
  const {
    initialProjectsList, subsetByMark, subsetByTag,
    tagNames, tagsFilterList, masks
  } = state;

  if (!initialProjectsList) return state;
  if (!masks || !tagNames) return state;

  const initialProjectIds = new Set(initialProjectsList.map(i => i.id));
  const masksFiltered = filterMasks({ masks, subset: subsetByTag, tagsFilterList, tagNames });

  const markedProjectIds = new Set(Object.keys(masksFiltered));

  const unmarkedProjectIds = new Set<string>();
  initialProjectIds.forEach(id => !markedProjectIds.has(id) && unmarkedProjectIds.add(id));

  let projectList = initialProjectsList;
  if (subsetByMark === 'all') {
    projectList = [...markedProjectIds, ...unmarkedProjectIds].map(i => ({ id: i, text: i }));

  } else if (subsetByMark === 'unmarked') {
    projectList = Array.from(unmarkedProjectIds).map(i => ({ id: i, text: i }));

  } else if (subsetByMark === 'marked') {
    projectList = Object.keys(masksFiltered).map(i => ({ id: i, text: i }));
  }

  const newState: State = {
    ...state,
    projectList,
    subsetByMark,
    subsetByTag
  };

  const { project } = state;
  const selectedProjectIsInList = project && projectList.find(p => p.id === project.id);
  if (!selectedProjectIsInList) newState.project = undefined;

  return newState;
}

type FilterMasksArg = {
  masks: Masks
  subset: TagSubset
  tagsFilterList: string[]
  tagNames: string[]
};

function filterMasks(x: FilterMasksArg): Masks {
  const { masks, subset, tagsFilterList, tagNames } = x;
  if (subset === 'no-matter') return masks;

  const indexes = tagsFilterList.map(tag => tagNames.indexOf(tag));
  const valueToCompare = subset === 'no-tag' ? 1 : 0;

  const projectNameAndMaskPairs = Object.entries(masks).filter(([p, m]) => {
    if (!m) return false;

    for (const index of indexes) {
      if (m[index] === valueToCompare) return false;
    }
    return true;
  });

  const result = Object.fromEntries(projectNameAndMaskPairs);

  return result;
}
