import React, { useContext, useRef, useState } from 'react';
import { FiltersCtx, AuthCtx } from '@contexts';
import { cn } from '@utils';
import { Button } from '@components';

import s from './CallAudioLoader.module.css';

type Props = {
  className?: string
};

export function CallAudioLoader(x: Props) {
  const [info, setInfo] = useState('');
  const [file, setFile] = useState<File>();

  const ref = useRef<HTMLInputElement>(null);
  const filtersCtx = useContext(FiltersCtx);
  const { token } = useContext(AuthCtx);

  const { isLoading } = filtersCtx;

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileLocal = e.target.files?.[0];
    if (!fileLocal) return;

    setFile(fileLocal);
    setInfo(fileLocal.name);
  };

  const handleSend = async () => {
    if (!file) return;

    setFile(undefined);
    setInfo('Отправка запроса...');
    const id = await filtersCtx.asyncDispatch(filtersCtx, { type: 'uploadAudio', file });
    setInfo('ID звонка: ' + id);
  };

  return (
    <div className={cn(s.wrapper, x.className)} data-cy="audio-uploader">
      <Button disabled={isLoading}
              onClick={() => ref.current?.click()} dataCy="updload-btn">
        Загрузить запись
      </Button>

      <Button dataCy="send-btn"
              disabled={!file || isLoading}
              onClick={handleSend}>
        Отправить
      </Button>

      <span className={s.fileName} data-cy="file-info">
        { info || 'Нет файла...' }
      </span>
      <Button dataCy="demo-loader"
              onClick={() => filtersCtx.asyncDispatch(filtersCtx, { type: 'getDemoCalls', token })}
              className={s.demoCallLoad}>
        Загрузить анализ демо-звонков
      </Button>

      <input type="file"
             ref={ref}
             className={s.inputFile}
             onChange={handleFileChange}
             name="" accept='.mp3' />
    </div>
  );
};
