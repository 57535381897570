import React, { useEffect, useRef } from 'react';

/*
  Назначение - сброс значения при смене ключа без удаления элемента из DOM,
  когда использование атрибута key для обновления компонента вызывает некоторые неудобства.
  Пример - при тестировании cypress может не найти одновленный компонент, поскольку
  при смене ключа, React его уже уничтожил.
  В ином случае, использовать его бесполезно.
*/

type Props = {
  radioKey: number | string // если он поменяется, компонент перегрузится
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  defaultChecked: boolean
  value: string
  name: string
  className?: string
};

export function RadioUncontrolled(x: Props) {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.checked = x.defaultChecked;
  }, [x.radioKey]);

  return (
    <input type="radio"
           name={x.name}
           className={x.className}
           ref={ref}
           value={x.value}
           onChange={x.onChange}
           defaultChecked={x.defaultChecked} />
  );
}

