import { z } from 'zod';
import { parseAndLogIfError } from '@api/parseAndLogIfError';
import { RequestDicts } from './types';
import { DEFAULT_COLOR } from './constants';
import { basicRequest } from './basicRequest';

type Args = {
  token: string
  name: string
};

export async function createDictionary(x: Args): Promise<unknown> {
  const { token, name } = x;
  const sendingObject: RequestDicts = {
    token,
    process: 'dict_work',
    data: {
      proc: 'ins',
      dict_name: name,
      dict: {
        color: DEFAULT_COLOR,
        words: []
      }
    }
  };

  const formData = JSON.stringify(sendingObject);
  return basicRequest({ formData });
}

const CreateDictionaryResult = z.object({
  id: z.number(),
  dict_name: z.string(),
});

export type CreateDictionaryResult = z.infer<typeof CreateDictionaryResult>;
export function isCreateDictionaryResult(o: unknown): o is CreateDictionaryResult {
  return parseAndLogIfError(CreateDictionaryResult, o);
}
