import * as authApi from '@api/auth';
import { Dispatch, StateSlice } from '../reducer';

type Args = {
  login: string
  password: string
  dispatch: Dispatch
};

export async function logIn(x: Args) {
  const { login, password, dispatch } = x;
  const { auth, isAuthResult } = authApi;
  const res = await auth({ login, password });

  if (!isAuthResult(res)) {
    alert('Попытка авторизации: нестандартизированный ответ сервера на запрос');
    alert('Авторизация невозможна');
    return;
  }

  const accessType = res.access_type;
  const token = res.token;
  const result = res.result;
  const stateSlice: StateSlice = {
    accessType,
    error: result,
    isAuth: res.access === 'true',
    token,
    isLoading: false,
  };

  dispatch({ type: 'updateStateAsync', stateSlice });
};
