/* eslint-disable linebreak-style */
export const csv = `Проект,Приветствие,Интерес,Гео,Обращение,УТП,Потребность,Модель,Физ/юрлицо,Оплата,Переключение
alarm_kaskad_kinetic,1,1,1,1,1,1,1,1,0,1,
alarm_komtrans_spb_1149_h,1,1,1,1,1,1,1,1,0,1,
alarm_motors_hyu_1155,1,1,1,1,1,1,1,1,0,1,
alarm_motors_hyu_1155_kinetic,1,1,1,1,1,1,1,1,0,1,
alarm_motors_multibrand_2632138,1,1,1,1,1,1,1,1,0,1,
alarm_motors_UAZ_SPB_1125,1,1,1,1,1,1,1,1,0,1,
alarm_suzuki_32103,1,1,1,1,1,1,1,1,0,1,
alarmgeely_spb_62091,1,1,1,1,1,1,1,1,0,1,
audi_vitebskiy_572098,1,1,0,0,1,1,1,1,0,1,
automir_renault_novosibirsk_1179,1,1,0,1,1,1,1,1,0,1,
autopassage_haval_1177,1,1,0,1,1,1,1,0,0,1,
avtogermes_ru_sale_renault_2632225,1,1,1,1,1,1,1,1,0,1,
Avtomir_DMI_Mazda_MSK_414,1,1,1,1,1,1,1,1,1,1,
avtomir_geely_2632143,1,1,0,1,1,1,1,1,0,1,
avtomir_haval_arh_512091,1,1,0,1,1,1,1,1,0,1,
avtomir_haval_yar_1108,1,1,0,1,1,1,1,1,0,1,
avtomir_hyundai_br_1850,1,1,0,1,1,1,1,1,0,1,
avtomir_hyundai_nsk_1911,1,1,0,1,1,1,1,1,0,1,
avtomir_hyundai_nvk_2009,1,1,0,1,1,1,1,1,0,1,
avtomir_lada_br_20_2069,1,1,0,1,1,1,1,1,0,1,
avtomir_lada_yar_28,1,1,0,1,1,1,1,1,0,1,
avtomir_mitsubishi_varsh_2632218,1,1,0,1,1,1,1,1,0,1,
br_nissan_2632213,1,1,1,1,1,1,1,1,0,1,
chery_lahta_2632236,1,1,0,0,1,0,0,0,0,1,
fili_volkswagen__2632193,1,1,1,1,1,1,1,1,0,1,
forsage_mitsubishi_2632245,1,1,1,1,1,1,1,1,0,1,
forsage_mitsubishi_2632245_kinetic,1,1,1,1,1,1,1,1,0,1,
geely_ekt_2632157,1,1,0,1,1,1,1,1,0,1,
geely_msk_2632140,1,1,0,1,1,1,1,1,0,1,
hyundai_avtomir_vrn_92105,1,1,0,1,1,1,1,1,0,1,
hyundai_dmitrovka_2632153,1,1,0,1,1,1,1,1,0,1,
hyundai_lublino_2632154,1,1,0,1,1,1,1,1,0,1,
hyundai_msk_official_2632152,1,1,0,1,1,1,1,1,0,1,
hyundai_rolf_spb_648,1,1,1,1,1,1,1,1,0,1,
kia_alarm_motors_1205,1,1,1,1,1,1,1,1,0,1,
kia_avtomir_spb_2632207,1,1,1,1,1,1,1,1,0,1,
kia_forsage_1947,1,1,1,1,1,1,1,1,0,1,
krd_renault_572096,1,1,1,1,1,1,1,1,0,1,
lada_pragmatika_avto_2632222,1,1,0,1,1,1,1,1,0,1,
mazda_avtomir_arh_2632151,1,1,1,1,1,1,1,1,1,1,
mini_borishof,1,1,1,1,1,1,1,1,0,1,
mitsubishi_avtomir_krul_2632220,1,1,0,1,1,1,1,1,0,1,
mitsubishi_ekt_2632221,1,1,0,1,1,1,1,1,0,1,
mitsubishi_marino_2632219,1,1,0,1,1,1,1,1,0,1,
mitsubishi_saratov_2632244,1,1,0,1,1,1,1,1,0,1,
nissanstock_2632172,1,1,1,1,1,1,1,1,0,1,
nn_avtomir_skoda_2632134,1,1,0,1,1,1,1,1,0,1,
nn_avtomir_skoda_2632134_kinetic,1,1,0,1,1,1,1,1,0,1,
nsk_skoda_avtomir_2632136,1,1,0,1,1,1,1,1,0,1,
nsk_skoda_avtomir_2632136_kinetic,1,1,0,1,1,1,1,1,0,1,
promo_arh_nissan_2632243,1,1,1,1,1,1,1,1,0,1,
renault_baykalskaya_2632212,1,1,1,1,1,1,1,1,0,1,
renault_ekaterinburg_2632198,1,1,0,1,1,1,1,1,0,1,
renault_leningradka_2632211,1,1,1,1,1,1,1,1,0,1,
renault_ozernaya_2632210,1,1,1,1,1,1,1,1,0,1,
renault_surgut_2632190,1,1,0,1,1,1,1,1,0,1,
rolf_himki,1,1,0,1,1,1,1,1,0,1,
ROLF_MITSU_ALL_SPB_1047,1,1,0,1,1,1,1,1,0,1,
rolf_nissan_1872,1,1,0,1,1,1,1,1,0,1,
rolf_oktyabrskaya_hyundai_394,1,1,1,1,1,1,1,1,0,1,
rolf_pelican_2632158,1,1,0,1,1,1,1,1,0,1,
rolf_pelican_2632158_kinetic,1,1,0,1,1,1,1,1,0,1,
rolf_pulkovo_KIA_SPB_407,1,1,0,1,1,1,1,1,0,1,
rolf_renault_spb_2006,1,1,1,1,1,1,1,1,0,1,
rolf_renault_spb_prime,1,1,1,1,1,1,1,1,0,1,
RolfMazda,1,1,0,1,1,1,1,1,0,1,
rolfmazda_dop,1,1,0,1,1,1,1,1,0,1,
Shuvalovo_Motors_KIA_948,1,1,1,1,1,1,1,1,0,1,
Shuvalovo_Motors_KIA_948_kinetic,1,1,1,1,1,1,1,1,0,1,
skoda_avtomir_2632174,1,1,0,1,1,1,1,1,0,1,
skoda_avtomir_2632174_kinetic,1,1,0,1,1,1,1,1,0,1,
skoda_msk_yaroslavka_2632149,1,1,0,1,1,1,1,1,0,1,
skoda_msk_yaroslavka_2632149_kinetic,1,1,0,1,1,1,1,1,0,1,
skoda_na_entuziastov_2632137,1,1,0,1,1,1,1,1,0,1,
skoda_na_entuziastov_2632137_kinetic,1,1,0,1,1,1,1,1,0,1,
skoda_novoryazanke_2632132,1,1,0,1,1,1,1,1,0,1,
skoda_novoryazanke_2632132_kinetic,1,1,0,1,1,1,1,1,0,1,
skoda_rolf_2632159,1,1,0,1,1,1,1,1,0,1,
skoda_rolf_2632159_kinetic,1,1,0,1,1,1,1,1,0,1,
skoda_vitebskiy,1,1,0,1,1,1,1,1,0,1,
smr_avtomir_skoda_2632133,1,1,0,1,1,1,1,1,0,1,
smr_avtomir_skoda_2632133_kinetic,1,1,0,1,1,1,1,1,0,1,
volkswagen_forsage_738,1,1,0,1,1,1,1,1,0,1,
volkswagen_now_1964,1,1,0,1,1,1,1,1,0,1,
volkswagen_srt_2632200,1,1,1,1,1,1,1,1,0,1,
volkswagen_vrn_2632192,1,1,1,1,1,1,1,1,0,1,
volkswagengermanika_92112,1,1,1,1,1,1,1,1,0,1,
volvo_car_belgorod_1082,1,1,0,1,1,1,1,1,0,1,
vrn_avtomir_skoda_2632150,1,1,0,1,1,1,1,1,0,1,
vrn_avtomir_skoda_2632150_kinetic,1,1,0,1,1,1,1,1,0,1,
wwwmazda_avtomir_689,1,1,1,1,1,1,1,1,0,1,
yar_skoda_avtomir_2632135,1,1,0,1,1,1,1,1,0,1,
yar_skoda_avtomir_2632135_kinetic,1,1,0,1,1,1,1,1,0,1,
yaroslavka_mazda_2632121,1,1,1,1,1,1,1,1,1,1,
Avtodom_SPB_1122,1,1,1,1,1,1,1,1,0,1,
avtomir_nissan_MSK_30,1,1,1,1,1,1,1,1,0,1,
avtomir_renault_dmitrovka_2632209,1,1,1,1,1,1,1,1,0,1,
bmw_msk_632093,1,1,1,1,1,1,1,1,0,1,
bmw_msk_vnukovo_2632164,1,1,1,1,1,1,1,1,0,1,
Moto_Avtodom_MSK_1065,1,1,1,1,1,1,1,0,0,1,
galant_auto_mitsubishi_1937,1,1,1,1,1,1,1,0,0,1,
ROLF_Jeep_Centre__848,1,1,0,1,1,1,1,1,0,1,
avtomir_chery_nn_92128,1,1,1,1,1,1,1,1,0,1,
renault_novokuznetsk_2632191,1,1,0,1,1,1,1,1,0,1,
toyota_bryansk_2632252,1,1,1,1,1,1,1,1,0,1,
toyotabc_serv_2632215,1,1,1,1,1,1,1,1,0,1,
mur_mazda_mgcom_92101,1,1,1,1,1,1,1,1,0,1,
hyundai_maximum_spb_2632188,1,1,0,0,1,1,1,0,0,1,
pragmatik_lada_2632173,1,1,1,0,1,1,0,0,0,1,
rolf_oktyabrskaya_hyundai_394_kinetic,1,1,1,1,1,1,1,1,0,1,
Mini_Avtodom_SPB_1123,1,1,1,1,1,1,1,0,0,1,
hyundai_rolf_spb_648_kinetic,1,1,1,1,1,1,1,1,0,1,
rolf_renault_spb_prime_kinetic,1,1,1,1,1,1,1,1,0,1,
hyundai_himki_2632175,1,1,1,1,1,1,1,1,0,1,
kia_alarm_motors_1205_kinetic,1,1,1,1,1,1,1,1,0,1,
lada_forsage_kinetic,1,1,1,1,1,1,1,1,1,1,
audi_belgorod_2632189,1,1,0,1,1,1,1,1,0,1,
avtomir_haval_samara_2056,1,1,0,1,1,1,1,0,0,1,
alarm_motors_mazda_92096,1,1,1,1,1,1,1,1,0,1,
avtomir_haval_arh_512091_kinetic,1,1,0,1,1,1,1,1,0,1,
rolf_lahta_spb_2632217,1,1,1,1,1,1,1,1,0,1,
lexus_spb_new_2632186,1,1,1,1,1,1,1,0,0,1,
ROLF_MITSU_ALL_SPB_1047_kinetic,1,1,0,1,1,1,1,1,0,1,
avtogermes_ru_sale_mitsubishi_2632224,1,1,1,1,1,1,1,1,0,1,
alarm_haval_reactive_2632231,1,1,1,1,1,1,1,0,0,1,
rolf_pulkovo_KIA_SPB_407_kinetic,1,1,0,1,1,1,1,1,0,1,
avtomir_chery_nn_92128_kinetic,1,1,1,1,1,1,1,1,0,1,
kia_alarm_motors_reactive_2632234,1,1,1,1,1,1,1,1,0,1,
avtomir_lada_br_20_2069_kinetic,1,1,0,1,1,1,1,1,0,1,
alarmgeely_spb_reactive_2632238,1,1,1,1,1,1,1,1,0,1,
all_auto_kaskad_project_2632233,1,1,0,1,1,1,1,1,0,1,
alarm_motors_mazda_reactive_2632239,1,1,1,1,1,1,1,1,0,1,
lexusbc_serv_2632216,1,1,1,1,1,1,1,1,0,1,
alarm_haval_geely_reactive_2632241,1,1,1,1,1,1,1,1,0,1,
avtogermes_ru_sale_lada_2632254,1,1,1,1,1,1,1,1,0,1,
volvocarfamily_2632256,1,1,1,1,1,1,1,1,0,1,
forsage_haval_2632258,1,1,1,1,1,1,1,1,0,1,
volvocarfamily_used_2632257,1,1,1,1,1,1,1,0,0,1,
kia_shuvalovo_new_2632264,1,1,1,1,1,1,1,1,0,1,
alarm_komtrans_spb_1149_h_2632267,1,1,1,1,1,1,1,1,0,1,
pragmatic_kia_spb_2022,1,1,1,0,1,1,0,0,0,1,
Moto_Avtodom_SPB_1124,1,1,1,1,1,1,1,0,0,1,
avtogermes_suzuki_2632162,1,1,1,1,1,1,1,1,0,1,
lada_forsage,1,1,1,1,1,1,1,1,0,1,
bmw_msk_smit_201021,1,1,1,1,1,1,1,1,0,1,
avtogermes_chery_2632161,1,1,1,1,1,1,1,1,0,1,
hyundai_altufyevo_new_2632183,1,1,1,1,1,1,1,1,0,1,
hyundai_city_2632178,1,1,1,1,1,1,1,1,0,1,
hyundai_city_new_2632185,1,1,1,1,1,1,1,1,0,1,
hyundai_south_2632177,1,1,1,1,1,1,1,1,0,1,
hyundai_south_new_2632184,1,1,1,1,1,1,1,1,0,1,
hyundai_rolf_new_2632182,1,1,1,1,1,1,1,1,0,1,
hyundai_rolf_2632179,1,1,1,1,1,1,1,1,0,1,`;
