import { useContext } from 'react';
import { isValidDialogAnalysis, ValidDialogAnalysis } from '@api/baseSubset';
import { CallInfoCtx } from '@contexts';

export function useDialog(callId: string): UseDialogResult {
  const { dealDatas } = useContext(CallInfoCtx);
  const callInfo = dealDatas[callId];
  if (!callInfo) return { dialog: undefined, error: 'no callInfo' };

  const callToShow = callInfo?.calls.find(c => !Array.isArray(c.analisys));
  const analisys = callToShow?.analisys?.dialogs;
  if (!analisys) return { dialog: undefined, error: 'no analysis' };

  if (!isValidDialogAnalysis(analisys)) return { dialog: undefined, error: 'invalid analysis' };

  const mappedDialog = mapToChart(analisys);
  return { dialog: mappedDialog, error: '' };
}

function mapToChart(data: ValidDialogAnalysis) {
  const acc: {speech: string; speaker: string}[] = [];

  for (let i = 0; ; i++) {
    const speech = (data.speech)[i];
    const speaker = (data.speaker)[i];
    if (!speech || !speaker) break;
    acc.push({ speech, speaker });
  }

  return acc;
}

type UseDialogResult = {
  dialog: {
    speech: string
    speaker: string
  }[]
  error: ''
} | {
  dialog: undefined
  error: 'no callInfo'
} | {
  dialog: undefined
  error: 'no analysis'
} | {
  dialog: undefined
  error: 'invalid analysis'
};
