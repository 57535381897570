import * as api from '@api/baseSubset';
import { State } from '../reducer';
import { getDealInfo } from './getDealInfo';

type Args = {
  comment: Omit<api.SaveCommentArgs, 'token'>
  state: State
  dealId: string
  token: string
};

export async function saveComment(x: Args) {
  const { comment: { idChain, infoComment, }, dealId, state, token } = x;
  const { dispatch } = state;
  try {
    await api.saveComment({ idChain, token, infoComment });
    return await getDealInfo({ id: dealId, state, token, force: true });
  } catch(e) {
    console.log(e);
    dispatch({ type: 'updateStateAsync', stateSlice: { isLoading: false } });
  }
};
