import * as api from '@api/baseSubset';
import { State } from '../reducer';

type Args = {
  state: State
  file: File
};
export async function uploadAudio(x: Args) {
  const { state, file } = x;
  const result = await api.uploadAudio({ file });
  state.dispatch({ type: 'updateStateAsync', stateSlice: { isLoading: false } });
  return result;
}
