import React, { createContext, useEffect, useReducer } from 'react';
import { PureWrapper } from '@components';
import { AuthCtxReducer, initialState } from './reducer';

export const AuthCtx = createContext(initialState);
const { Provider } = AuthCtx;

type Props = {
  children: React.ReactNode
};

export function AuthCtxProvider(x: Props) {
  const [state, dispatch] = useReducer(AuthCtxReducer, initialState);
  useEffect(() => dispatch({ type: 'initDispatch', dispatch }), []);

  if (typeof state.dispatch !== 'function') return null;

  return (
    <Provider value={{ ...state, dispatch }}>
      <PureWrapper>
        { x.children }
      </PureWrapper>
    </Provider>
  );
};
