import { csv } from '@mocks/tagsInProjects';
import { delay, parseMasksCSV } from '@utils';
import { ITEM } from './constants';

type Args = {
  tagNames: string[]
  projectId: string
  mask: number[]
};

type ItemType = ReturnType<typeof parseMasksCSV>;

export async function updateMask(x: Args): Promise<unknown> {
  await delay(500);

  const oldInfo = localStorage.getItem(ITEM);
  if (!oldInfo) localStorage.setItem(ITEM, JSON.stringify(parseMasksCSV(csv)));
  const result: ItemType = (oldInfo && JSON.parse(oldInfo)) || parseMasksCSV(csv);

  const { tagNames, projectMasks } = result;

  try {
    if (JSON.stringify(result.tagNames) !== JSON.stringify(tagNames)) {
      throw new Error('Список тегов не совпадает! Проекты рассинхронизированы...');
    }
    projectMasks[x.projectId] = [...x.mask];

    const objToSave: ItemType = {
      tagNames,
      projectMasks,
    };

    localStorage.setItem(ITEM, JSON.stringify(objToSave));
    return true;

  } catch(e) {
    console.log(e);
    return false;
  }
}
