import * as api from '@api/config';
import { State, StateSlice } from '../reducer';

type Args = {
  state: State
  info: api.GetConfigResult
};

export async function saveConfig(x: Args) {
  const { info, state } = x;
  const result = await api.saveConfig({ info });

  if (!api.isSaveConfigResult(result)) {
    return x.state.dispatch({ type: 'updateStateAsync', stateSlice: { isLoading: false } });
  }

  const stateSlice: StateSlice = {
    configSaved: result,
    // configTemp: copySimpleObj(result),
    isLoading: false,
  };

  state.dispatch({ type: 'updateStateAsync', stateSlice });
  return result;
}
