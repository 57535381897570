import * as api from '@api/config';
import { copySimpleObj } from '@utils';
import { State, StateSlice } from '../reducer';

type Args = {
  state: State
};
export async function getConfig(x: Args) {
  const { state } = x;
  const result = await api.getConfig();

  if (!api.isGetConfigResult(result)) {
    return state.dispatch({ type: 'updateStateAsync', stateSlice: { isLoading: false } });
  }

  const stateSlice: StateSlice = { configSaved: result, isLoading: false };

  if (!Object.keys(state.configTemp).length) stateSlice.configTemp = copySimpleObj(result);

  return state.dispatch({ type: 'updateStateAsync', stateSlice });
}
