import { copySimpleObj } from '@utils';
import * as api from '@api/dictionaries';
import { StateSlice, State } from '../reducer';

type Args = {
  state: State
  id: number
  token: string
};

export async function deleteDictionary(x: Args) {
  const { id, token } = x;
  const { dispatch } = x.state;

  const result = await api.deleteDictionary({ id, token });
  if (!result) return dispatch({ type: 'updateStateAsync', stateSlice: { isLoading: false } });

  const { dictInfos, dictionaries } = x.state;
  const updatedDictInfos = copySimpleObj(dictInfos);
  delete updatedDictInfos[id];

  const filteredDictionaries = dictionaries?.filter(i => i.id !== String(id));
  const stateSlice: StateSlice = {
    isLoading: false,
    dictInfos: updatedDictInfos,
    dictionaries: filteredDictionaries
  };

  dispatch({ type: 'updateStateAsync', stateSlice });
  return result;
};
