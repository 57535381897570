import { z } from 'zod';
import { parseAndLogIfError } from '@api/parseAndLogIfError';
import { RequestDicts } from './types';
import { basicRequest } from './basicRequest';

type Args = {
  token: string
  id: number
  data: {
    color: string
    words: string[]
  } | undefined
};

export async function updateDictionary(x: Args): Promise<unknown> {
  const { token, id, data } = x;

  const sendingObject: RequestDicts = {
    token,
    process: 'dict_work',
    data: {
      proc: 'upd',
      id,
      dict: data,
    }
  };

  const formData = JSON.stringify(sendingObject);
  return basicRequest({ formData });
};

export type UpdateDictionaryResult = z.infer<typeof UpdateDictionaryResult>;
export function isUpdateDictionaryResult(o: unknown): o is UpdateDictionaryResult {
  return parseAndLogIfError(UpdateDictionaryResult, o);
};

const UpdateDictionaryResult = z.object({
  id: z.number(),
  dict: z.object({
    words: z.string().array(),
    color: z.string(),
  }),
});
