import React, { useContext, useEffect, useRef, useState } from 'react';
import { FiltersCtx, AuthCtx, CallInfoCtx } from '@contexts';
import { cn } from '@utils';
import { Button, Dropdown } from '@components';
import * as h from './helpers';
import s from './Sidebar2.module.css';

type Props = {
  className?: string
};

const controlsList = [
  'текст', 'теги', 'лиды', 'эмоции', 'эмоции*', 'слова', 'голос', 'не обработан'
].map(i => ({ id: i, text: i }));

export function Sidebar2(x: Props) {
  const { token, accessType } = useContext(AuthCtx);
  const filtersCtx = useContext(FiltersCtx);
  const callCtx = useContext(CallInfoCtx);
  const [controlSelected, setControlSelected] = useState(controlsList[1]);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const { deal, savedDeals, filterId } = filtersCtx;
  const { dealDatas } = callCtx;
  const dealId = deal?.id;
  const setId = filterId?.id;
  const callInfo = deal && dealDatas[deal?.id];

  useEffect(() => {
    if (!textAreaRef.current) return;

    textAreaRef.current.value = callInfo?.info_comment ?? '';
  }, [callInfo, dealId]);

  const handleSaveComment = () => {
    if (!textAreaRef.current) return;
    if (!dealId) return;

    const comment = textAreaRef.current.value;
    callCtx.asyncDispatch(callCtx, {
      type: 'saveComment', commentText: comment,
      idChain: callInfo?.idchain ?? '',
      dealId, token
    });
  };

  const handleSetDeal = async (dealLocal: string) => {
    const info = await callCtx.asyncDispatch(callCtx, { type: 'getDealInfo', id: dealLocal, token });
    if (!info) return;
    filtersCtx.dispatch({ type: 'selectDeal', deal: info });
  };

  const dataCy = 'supervisor-bar';
  const isAdmin = accessType === 'admin';

  return (
    <div className={cn(s.wrapper, x.className)} data-cy={dataCy}>
      <h2 className={s.title}>Выборка</h2>

      <div className={s.dealList} data-cy={`${dataCy}-list`}>
        { savedDeals.map(i => (
          <label className={s.dealItem} key={i}>
            <span className={s.dealId}>{ i }</span>
            <input type="radio" name="deal-id" value={i}
                   checked={i === dealId}
                   onChange={e => handleSetDeal(e.target.value)} />
          </label>
        )) }
      </div>

      <div className={cn(s.controls, isAdmin && s.controlsAdmin)}
           data-cy={`${dataCy}-list-controls`}>
        <Button className={s.controlBtn}
                disabled={savedDeals.length === 0}
                onClick={() => h.loadSetCSV({ savedDeals, dealDatas, filterId: filterId?.id, token })}>
          CSV
        </Button>

        { !isAdmin && (
          <Button className={s.controlBtn}
                  disabled={savedDeals.length === 0}
                  onClick={() => h.loadSetTXT(savedDeals)}>
            Сохранить
          </Button>
        ) }

        { isAdmin && (
          <>
            <Dropdown list={controlsList} onSelect={i => setControlSelected(i)}
                      selectedItem={controlSelected} />
            <Button className={s.controlBtn}
                    disabled={savedDeals.length === 0}
                    onClick={() => {}}>
              OK
            </Button>

            <Button className={s.downloadJSON}
                    onClick={() => callInfo && h.loadCallJSON({ callInfo })}
                    disabled={savedDeals.length === 0}>
              Загрузить в JSON
            </Button>
          </>

        ) }
      </div>

      <div className={s.dealInfo} data-cy={`${dataCy}-deal-info`}>
        { callInfo && (
          <>
            { setId && <p>Фильтр № { setId } </p> }
            <p>ID звонка: { callInfo?.deal_id }</p>
            <p>Проект: { callInfo?.customer }</p>
            <p>Оператор: { callInfo?.operator }</p>
            <p>Статус: { callInfo?.result }</p>
            <p>Подстатус: { callInfo?.substatuses?.slice(0, 1) ?? 'н/д' }</p>
            <p>ID таблицы: { callInfo?.idtable }</p>
            <p>Дата: { callInfo?.dt.replace(/:\d\d.\d+$/, '') }</p>
          </>
        ) }
      </div>

      <div className={s.commentSaver} data-cy="comment-saver">
        <textarea className={s.comment} data-cy="textarea"
                  disabled={!deal}
                  maxLength={140}
                  ref={textAreaRef}></textarea>
        <Button className={s.saveCommentBtn}
                disabled={!deal}
                onClick={handleSaveComment}
                dataCy='button'>Сохранить комментарий</Button>
      </div>

    </div>
  );
};
