import React, { useContext } from 'react';
import { cn } from '@utils';
import { CallInfoCtx } from '@contexts';

import s from './ScriptBar.module.css';

type Props = {
  dealId: string
  className?: string
};

export function ScriptBar(x: Props) {
  const { dealDatas } = useContext(CallInfoCtx);
  const callInfo = dealDatas[x.dealId];

  const src = callInfo?.script;
  if (!src) return null;

  return (
    <iframe className={cn(s.scriptBar, x.className)}
            data-cy="script-bar"
            key={src}
            src={src}
            title="Использованный скрипт"
            name="script"></iframe>
  );
};
