import { ListItem } from '@components';
import { Masks } from '@contexts/masks/reducer';
import { LabelValue, State } from './reducer';
import * as h from './helpers';

type ActionAsync =
  | { type: 'getInitialLists'; token: string; accessType: string }
  | { type: 'selectDates'; dates: [Date, Date?]; token: string }
  | { type: 'selectProject'; project: ListItem; token: string }
  | { type: 'selectTableId'; tableId: string; token: string }
  | { type: 'resaveTags'; tagNames: string[]; masks: Masks; token: string }
  | { type: 'validateCalls'; token: string }

  | { type: 'selectDictionary'; dictionary: ListItem; token: string }
  | { type: 'selectOperator'; operator: ListItem; token: string }
  | { type: 'selectStatus'; status: ListItem; token: string }
  | { type: 'selectSubstatus'; substatus: ListItem; token: string }
  | { type: 'selectFilterId'; filterId: ListItem; token: string }
  | { type: 'selectByWords'; str: string; token: string }
  | { type: 'selectLabels'
    labels: { tag: LabelValue; emo: LabelValue; emoTemp: LabelValue; lead: LabelValue }
    token: string
  }
  | { type: 'uploadAudio'; file: File }
  | { type: 'getDemoCalls'; token: string }
;

export async function asyncDispatch<T extends ActionAsync, U extends T['type']>(state: State, action: T & ActionAsync): Promise<AsyncDispatchResults[U]> {
  const { type } = action;
  const setIsLoading = (slice?: Partial<State>) => state.dispatch({ type: 'updateStateAsync', stateSlice: { isLoading: true, ...slice } });
  const { dispatch } = state;

  if (type === 'selectDates') {
    if (state.selectedDates && state.selectedDates.length === 2) return;

    const { dates, token } = action;
    if (dates.length !== 2) {
      return dispatch({ type: 'updateStateAsync', stateSlice: { selectedDates: dates, isLoading: false } });
    }

    setIsLoading({ selectedDates: dates });
    return h.updateFilters({ ...state, selectedDates: dates }, token);

  } else if (type === 'selectProject') {
    const { project, token } = action;
    if (project.id === state.project?.id) return;
    setIsLoading({ project });
    return h.updateFilters({ ...state, project }, token);

  } else if (type === 'selectTableId') {
    const { tableId, token } = action;
    setIsLoading({ tableId });
    return h.updateFilters({ ...state, tableId }, token);

  } else if (type === 'resaveTags') {
    const { masks, tagNames, token } = action;
    setIsLoading();
    return h.resaveTags({ state, masks, tagNames, token });

  } else if (type === 'selectDictionary') {
    const { dictionary } = action;
    return state.dispatch({ type: 'updateStateAsync', stateSlice: { dictionary, isLoading: false } });

  } else if (type === 'selectOperator') {
    const { operator, token } = action;
    if (operator.id === state.operator?.id) return;
    setIsLoading({ operator });
    return h.updateFilters({ ...state, operator }, token);

  } else if (type === 'selectFilterId') {
    const { filterId, token } = action;
    if (filterId.id === state.filterId?.id) return;
    setIsLoading({ filterId });
    return h.updateFilters({ ...state, filterId }, token);

  } else if (type === 'selectStatus') {
    const { status, token } = action;
    if (status.id === state.status?.id) return;
    setIsLoading({ status });
    return h.updateFilters({ ...state, status }, token);

  } else if (type === 'selectSubstatus') {
    const { substatus } = action;
    return dispatch({ type: 'updateStateAsync', stateSlice: { substatus, isLoading: false } });

  } else if (type === 'selectByWords') {
    const { str: wordsToFind, token } = action;
    if (state.wordsToFind === wordsToFind || !wordsToFind) return;
    setIsLoading({ wordsToFind });
    return h.updateFilters({ ...state, wordsToFind }, token);

  } else if (type === 'selectLabels') { // FIXME вынести в отдельную функцию.
    const { labels: { emo, emoTemp, tag, lead }, token } = action;
    const { labeledEmo, labeledTag, labeledEmoTemp, labeledLead } = state;
    const nothingChanged
    = emo === labeledEmo
    && emoTemp === labeledEmoTemp
    && labeledTag === tag
    && labeledLead === lead;

    if (nothingChanged) return;
    setIsLoading({
      labeledEmo: emo,
      labeledEmoTemp: emoTemp,
      labeledTag: tag,
      labeledLead: lead,

    });
    return h.updateFilters({
      ...state,
      labeledTag: tag, labeledEmo: emo, labeledEmoTemp: emoTemp, labeledLead: lead
    }, token);

  } else if (type === 'getInitialLists') {
    const { token, accessType } = action;
    setIsLoading();
    return h.getInitialLists(token, dispatch, accessType === 'demo');

  } else if (type === 'validateCalls') {
    const { token } = action;
    setIsLoading();
    return h.validateCalls({ state, token });

  } else if (type === 'uploadAudio') {
    const { file } = action;
    setIsLoading();
    return h.uploadAudio({ state, file });

  } else if (type === 'getDemoCalls') {
    const { token } = action;
    setIsLoading();
    return h.getDemoCalls({ state, token });
  }
};

type AsyncDispatchResults = {
  resaveTags: ReturnType<typeof h.resaveTags>

  selectDates: ReturnType<typeof h.updateFilters>
  selectProject: ReturnType<typeof h.updateFilters>
  selectTableId: ReturnType<typeof h.updateFilters>
  selectOperator: ReturnType<typeof h.updateFilters>
  selectStatus: ReturnType<typeof h.updateFilters>
  selectByWords: ReturnType<typeof h.updateFilters>
  selectLabels: ReturnType<typeof h.updateFilters>
  selectFilterId: ReturnType<typeof h.updateFilters>

  selectDictionary: Promise<void>
  selectSubstatus: Promise<void>
  uploadAudio: ReturnType<typeof h.uploadAudio>
  getDemoCalls: ReturnType<typeof h.getDemoCalls>

  getInitialLists: ReturnType<typeof h.getInitialLists>
  validateCalls: ReturnType<typeof h.validateCalls>
};
