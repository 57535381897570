import React from 'react';
import { ValidDialogAnalysis } from '@api/baseSubset';
import { cn, mapTextToHTMLForHighlight } from '@utils';
import { MessageBubble } from './MessageBubble';
import s from './DialogChart.module.css';

type Props = {
  data: ValidDialogAnalysis
  audioSrc: string
  status: string
  comment: string
  className?: string
  wordsToHighlight: string[] | undefined
};

const HIGHLIGHT_COLOR = 'yellow';

type CallData = ValidDialogAnalysis;

export function DialogChart(x: Props) {
  const { data, audioSrc, status, comment } = x;
  const mappedDialog = mapResponseToChart(data);

  return (
    <div className={cn(s.wrapper, x.className)}>
      <h5 className={s.status}>{ status }</h5>
      <h5 className={s.comment}>{ comment }</h5>
      <audio className={s.audioPlayer} src={audioSrc} controls></audio>
      <div className={s.dialogs}>
        { mappedDialog.map((i, index) => (
          <MessageBubble key={index}
                         className={i.speaker === 'operator' ? s.operator : s.client}
                         isOutgoing={i.speaker === 'operator'}>
            { x.wordsToHighlight && (
              <p className={s.speech}
                 dangerouslySetInnerHTML={
                   { __html: mapTextToHTMLForHighlight(i.speech, x.wordsToHighlight, HIGHLIGHT_COLOR) }
                 } />
            ) }
            { !x.wordsToHighlight && <p className={s.speech}>{ i.speech }</p> }
            <p className={s.messageStart}>{ i.start }</p>
          </MessageBubble>
        )) }
      </div>
    </div>
  );
};

function mapResponseToChart(data: CallData) {
  const acc: {speech: string; speaker: string; start: string}[] = [];

  for (let i = 0; ; i++) {
    const speech = (data.speech)[i];
    const speaker = (data.speaker)[i];
    const start = (data.start)[i];
    if (!speech || !speaker || !start) break;
    acc.push({ speech, speaker, start });
  }

  return acc;
}
