import { ZodError } from 'zod';

export function parseAndLogIfError(validator: { parse: (s: unknown) => void }, o: unknown) {
  try {
    validator.parse(o);
    return true;
  } catch (e) {
    if (e instanceof ZodError) {
      console.log('Zod: received object', o);
      console.log(e.stack);
      return false;
    }
    throw e;
  }
}
