import * as api from '@api/baseSubset';

type Args = {
  token: string
  dealDatas: api.DealsObj
  savedDeals: string[]
  filterId?: string
};

export async function loadSetCSV(x: Args) {
  const { token, dealDatas, savedDeals, filterId } = x;
  try {
    const headers = ['Фильтр', 'ID звонка', 'Проект', 'Оператор', 'Статус',
      'Подстатус', 'ID таблицы', 'Дата'];

    const captions = headers.join(',') + '\r\n';
    const replacer = (str: string) => {
      let s = str;
      s = s.replace(/"/g, '""');

      const regexp = /[",;\n]/g;
      if (regexp.test(s)) {
        s = `"${s}"`;
      }
      return s;
    };
    // FIXME попросить сделать точку на получение инфы по нескольким звонкам (или добавить поле)
    const info = [] as api.Deal[];
    for (const id of savedDeals) {
      const callInfo = dealDatas[id];
      if (callInfo) {
        info.push(callInfo);
        continue;
      }

      const data = await api.getDeals({ token, exclude: ['analisys'], deal_id: id });
      if (!api.isDealsObj(data)) continue;

      const callInfoReceived = data[id];
      callInfoReceived && info.push(callInfoReceived);
    }

    const rows = info
      .map(i => [
        filterId || 'н/д',
        String(i.deal_id),
        i.customer,
        i.operator,
        i.result,
        i.substatuses?.[0] ?? 'н/д',
        String(i.idtable),
        i.dt,
      ]
        .map(replacer)
        .join(','))
      .join('\r\n');

    const csv = captions + rows;

    const blob = new Blob([csv], { type: 'text/csv' });
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = 'selection.csv';
    a.click();

  } catch(e) {
    console.log(e);
  }
};
