import { useState } from 'react';

/* вероятно, временное решение. Используется для того, чтобы при работе с доступом
второго уровня, можно было ввести определенный пароль и получить инфу о
разметке нейросети */

export function useControlBtn() {
  const [showExtraInfo, setShowExtraInfo] = useState(false);
  const handleControlClick = () => {
    const password = prompt('Введите пароль', '');
    if (password === 'rEffection17') setShowExtraInfo(true);
  };

  return { showExtraInfo, handleControlClick };
}
