import React, { useState, useContext } from 'react';

import { AuthCtx } from '@contexts';
import { cn } from '@utils';
import { Button } from '@components';

import { VERSION } from '../../constants';
import { LoginField, PasswordField } from './components';

import s from './Auth.module.css';

type Props = {
  className?: string
};

export function Auth(x: Props) {
  const authCtx = useContext(AuthCtx);
  const { error } = authCtx;

  const [password, setPassword] = useState('');
  const [login, setLogin] = useState('');
  const errorLogin = error.includes('login');
  const errorPassword = error.includes('password');

  return (
    <form className={cn(s.auth, x.className)}>
      <LoginField className={s.login} value={login} onChange={setLogin} invalid={errorLogin} />
      <PasswordField className={s.password} value={password}
                     onChange={setPassword} invalid={errorPassword} />
      <Button className={s.loginBtn}
              dataCy="enter"
              onClick={() => authCtx.asyncDispatch(authCtx, { type: 'logIn', login, password })}
              type="button">Войти</Button>

      <span className={s.version}>{ `V.${VERSION}` }</span>
    </form>
  );
};
